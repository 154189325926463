import { Record } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Types.js";
import { Locale$reflection } from "./Locale.fs.js";
import { record_type, class_type, option_type, lambda_type, string_type, float64_type, int32_type } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Reflection.js";

export class Localization extends Record {
    constructor(Locale, FormatDecimal, FormatDecimalFixed, FormatUnit, FormatCurrency, FormatCurrencyWithEmpty, FormatDate) {
        super();
        this.Locale = Locale;
        this.FormatDecimal = FormatDecimal;
        this.FormatDecimalFixed = FormatDecimalFixed;
        this.FormatUnit = FormatUnit;
        this.FormatCurrency = FormatCurrency;
        this.FormatCurrencyWithEmpty = FormatCurrencyWithEmpty;
        this.FormatDate = FormatDate;
    }
}

export function Localization$reflection() {
    return record_type("Fame.Localization.Localization", [], Localization, () => [["Locale", Locale$reflection()], ["FormatDecimal", lambda_type(int32_type, lambda_type(int32_type, lambda_type(float64_type, string_type)))], ["FormatDecimalFixed", lambda_type(int32_type, lambda_type(float64_type, string_type))], ["FormatUnit", lambda_type(int32_type, lambda_type(string_type, lambda_type(float64_type, string_type)))], ["FormatCurrency", lambda_type(string_type, lambda_type(float64_type, string_type))], ["FormatCurrencyWithEmpty", lambda_type(string_type, lambda_type(option_type(float64_type), string_type))], ["FormatDate", lambda_type(class_type("System.DateTime"), string_type)]]);
}

