import { empty, cons } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/List.js";
import { join } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/String.js";
import { concatNewLine } from "./String.fs.js";
import { RemoteServiceError } from "../Types/Errors.fs.js";

function getAllMessagesInternal(messages, ex) {
    if (ex == null) {
        return messages;
    }
    else {
        return cons(ex.message, messages);
    }
}

export function getAllMessages(ex) {
    return getAllMessagesInternal(empty(), ex);
}

export function getFullMessage(ex) {
    return join("\n", getAllMessages(ex));
}

export function toString(ex) {
    return ex.message;
}

export function toRemoteServiceError(ex) {
    return new RemoteServiceError(toString(ex), concatNewLine(getAllMessages(ex)), ex);
}

