import { Record, Union } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Types.js";
import { record_type, bool_type, lambda_type, unit_type, class_type, tuple_type, option_type, list_type, string_type, union_type } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Reflection.js";
import { TextWeight_Option, Text_span, Color_IColor, Common_GenericOption, Modifier_IModifier, Text_p, Color_IColor$reflection } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Common.fs.js";
import { forAll, ofArrayWithTail, foldBack, head, tail, isEmpty, ofArray, truncate, empty as empty_1, singleton, map as map_1, length } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/List.js";
import { isEmpty as isEmpty_1, empty, singleton as singleton_1, delay, toList } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Seq.js";
import * as react from "react";
import { FunctionComponent_Of_Z5A158BBF } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option, modal as modal_1 } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { DOMAttr } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Option as Option_1, delete$ } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Elements/Delete.fs.js";
import { Option as Option_2, button } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { uncurry } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Util.js";
import { defaultValue as defaultValue_1 } from "../../Fame/Core/String.fs.js";
import { isNullOrEmpty } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/String.js";
import { value as value_7 } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Option.js";

export class Alignment extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Left", "Center", "Right"];
    }
}

export function Alignment$reflection() {
    return union_type("Fame.Fts.Shared.UI.Fields.Alignment", [], Alignment, () => [[], [], []]);
}

export class Value extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TextValue", "MultiLineValue", "MultiLineError", "ColoredMultiLineValue", "BoldValue", "ColoredValue", "LinkValue", "ExternalLinkValue", "BoolValue", "DateValue", "JoinedValue", "EmptyValue"];
    }
}

export function Value$reflection() {
    return union_type("Fame.Fts.Shared.UI.Fields.Value", [], Value, () => [[["Item", string_type]], [["Item", list_type(string_type)]], [["Item", list_type(string_type)]], [["Item", list_type(tuple_type(string_type, option_type(Color_IColor$reflection())))]], [["Item", string_type]], [["Item1", string_type], ["Item2", Color_IColor$reflection()]], [["value", string_type], ["href", string_type], ["onclick", lambda_type(class_type("Browser.Types.MouseEvent", void 0, MouseEvent), unit_type)]], [["value", string_type], ["href", string_type]], [["Item", bool_type]], [["Item", class_type("System.DateTime")]], [["seperator", string_type], ["Item2", list_type(Value$reflection())]], []]);
}

export class FieldResources extends Record {
    constructor(Details, Close, Yes, No) {
        super();
        this.Details = Details;
        this.Close = Close;
        this.Yes = Yes;
        this.No = No;
    }
}

export function FieldResources$reflection() {
    return record_type("Fame.Fts.Shared.UI.Fields.FieldResources", [], FieldResources, () => [["Details", string_type], ["Close", string_type], ["Yes", string_type], ["No", string_type]]);
}

export function ValueModule_isEmpty(_arg1) {
    if (_arg1.tag === 11) {
        return true;
    }
    else {
        return false;
    }
}

function ValueModule_coloredMultiLineView(resources, isError, lines) {
    const maxLines = 3;
    const matchValue = length(lines) | 0;
    if (matchValue === 0) {
        return "-";
    }
    else if (matchValue <= maxLines) {
        const children = map_1((l) => {
            if (l[1] != null) {
                return Text_p(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(1, l[1])))), singleton(l[0]));
            }
            else {
                return Text_p(toList(delay(() => (isError ? singleton_1(new Common_GenericOption(2, singleton(new Modifier_IModifier(1, new Color_IColor(8))))) : empty()))), singleton(l[0]));
            }
        }, lines);
        return react.createElement("div", {}, ...children);
    }
    else {
        return FunctionComponent_Of_Z5A158BBF((lines_1) => {
            const modalOpenState = react.useState(false);
            const close = (_arg2) => {
                modalOpenState[1](false);
            };
            let leading;
            const children_3 = map_1((tupledArg) => Text_p(empty_1(), singleton(tupledArg[0])), truncate(maxLines, lines_1));
            leading = react.createElement("div", {}, ...children_3);
            const moreButton = react.createElement("a", {
                onClick: (_arg1) => {
                    modalOpenState[1](true);
                },
            }, resources.Details);
            let modal;
            if (!(modalOpenState[0])) {
                modal = "";
            }
            else {
                const ps = map_1((tupledArg_1) => Text_p(empty_1(), singleton(tupledArg_1[0])), lines_1);
                modal = modal_1(singleton(new Option(1, true)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, close)))), empty_1()), Card_card(empty_1(), ofArray([Card_head(empty_1(), ofArray([Card_title(empty_1(), singleton(resources.Details)), delete$(singleton(new Option_1(3, close)), empty_1())])), Card_body(empty_1(), ps), Card_foot(empty_1(), singleton(button(singleton(new Option_2(18, close)), singleton(resources.Close))))]))]));
            }
            return react.createElement("div", {}, leading, moreButton, modal);
        }, void 0, uncurry(2, void 0), void 0, "coloredMultiLineView", "/home/vsts/work/1/s/src/Fame.Fts.Shared/Shared.UI/Fields.fs", 65)(lines);
    }
}

function ValueModule_multiLineView(resources, isError, lines) {
    return ValueModule_coloredMultiLineView(resources, isError, map_1((l) => [l, void 0], lines));
}

export function ValueModule_view(localization, resources) {
    const defaultValue = (input) => defaultValue_1("-", input);
    return (_arg1) => {
        let pattern_matching_result, value;
        if (_arg1.tag === 11) {
            pattern_matching_result = 0;
        }
        else if (_arg1.tag === 0) {
            pattern_matching_result = 1;
            value = _arg1.fields[0];
        }
        else if (_arg1.tag === 3) {
            if (isEmpty_1(_arg1.fields[0])) {
                pattern_matching_result = 2;
            }
            else {
                pattern_matching_result = 3;
            }
        }
        else {
            pattern_matching_result = 3;
        }
        switch (pattern_matching_result) {
            case 0: {
                return "-";
            }
            case 1: {
                return defaultValue(value);
            }
            case 2: {
                return "-";
            }
            case 3: {
                let pattern_matching_result_1, vs_3;
                if (_arg1.tag === 3) {
                    pattern_matching_result_1 = 0;
                    vs_3 = _arg1.fields[0];
                }
                else if (_arg1.tag === 1) {
                    if (isEmpty_1(_arg1.fields[0])) {
                        pattern_matching_result_1 = 1;
                    }
                    else {
                        pattern_matching_result_1 = 2;
                    }
                }
                else {
                    pattern_matching_result_1 = 2;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        return ValueModule_coloredMultiLineView(resources, false, vs_3);
                    }
                    case 1: {
                        return "-";
                    }
                    case 2: {
                        let pattern_matching_result_2, vs_6;
                        if (_arg1.tag === 1) {
                            pattern_matching_result_2 = 0;
                            vs_6 = _arg1.fields[0];
                        }
                        else if (_arg1.tag === 2) {
                            if (isEmpty_1(_arg1.fields[0])) {
                                pattern_matching_result_2 = 1;
                            }
                            else {
                                pattern_matching_result_2 = 2;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 2;
                        }
                        switch (pattern_matching_result_2) {
                            case 0: {
                                return ValueModule_multiLineView(resources, false, vs_6);
                            }
                            case 1: {
                                return ValueModule_multiLineView(resources, true, singleton("Error"));
                            }
                            case 2: {
                                switch (_arg1.tag) {
                                    case 2: {
                                        return ValueModule_multiLineView(resources, true, _arg1.fields[0]);
                                    }
                                    case 4: {
                                        return Text_span(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(2, new TextWeight_Option(2))))), singleton(defaultValue(_arg1.fields[0])));
                                    }
                                    case 5: {
                                        return Text_span(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(1, _arg1.fields[1])))), singleton(defaultValue(_arg1.fields[0])));
                                    }
                                    case 6: {
                                        const value_3 = _arg1.fields[0];
                                        if (isNullOrEmpty(value_3)) {
                                            return defaultValue(value_3);
                                        }
                                        else {
                                            const children = [defaultValue(value_3)];
                                            return react.createElement("a", {
                                                href: _arg1.fields[1],
                                                onClick: _arg1.fields[2],
                                            }, ...children);
                                        }
                                    }
                                    case 7: {
                                        const value_4 = _arg1.fields[0];
                                        if (isNullOrEmpty(value_4)) {
                                            return defaultValue(value_4);
                                        }
                                        else {
                                            const children_2 = [defaultValue(value_4)];
                                            return react.createElement("a", {
                                                href: _arg1.fields[1],
                                                target: "_blank",
                                            }, ...children_2);
                                        }
                                    }
                                    case 8: {
                                        return _arg1.fields[0] ? resources.Yes : resources.No;
                                    }
                                    case 9: {
                                        return localization.FormatDate(_arg1.fields[0]);
                                    }
                                    case 10: {
                                        const matchValue = map_1(ValueModule_view(localization, resources), _arg1.fields[1]);
                                        if (!isEmpty(matchValue)) {
                                            if (isEmpty(tail(matchValue))) {
                                                return head(matchValue);
                                            }
                                            else {
                                                const children_4 = foldBack((view, list) => {
                                                    if (isEmpty(list)) {
                                                        return singleton(view);
                                                    }
                                                    else {
                                                        return ofArrayWithTail([view, _arg1.fields[0]], list);
                                                    }
                                                }, matchValue, empty_1());
                                                return react.createElement("span", {}, ...children_4);
                                            }
                                        }
                                        else {
                                            return "";
                                        }
                                    }
                                    default: {
                                        throw (new Error("Match failure: Fame.Fts.Shared.UI.Fields.Value"));
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };
}

export class FieldDefinition$2 extends Record {
    constructor(IsPublic, GetName, GetValue, GetAlignment) {
        super();
        this.IsPublic = IsPublic;
        this.GetName = GetName;
        this.GetValue = GetValue;
        this.GetAlignment = GetAlignment;
    }
}

export function FieldDefinition$2$reflection(gen0, gen1) {
    return record_type("Fame.Fts.Shared.UI.Fields.FieldDefinition`2", [gen0, gen1], FieldDefinition$2, () => [["IsPublic", lambda_type(gen1, bool_type)], ["GetName", lambda_type(gen1, string_type)], ["GetValue", lambda_type(gen0, lambda_type(gen1, Value$reflection()))], ["GetAlignment", lambda_type(gen1, Alignment$reflection())]]);
}

export function Field_filterEmptyValue(_arg1) {
    let pattern_matching_result;
    if (_arg1.tag === 0) {
        if (_arg1.fields[0] === "") {
            pattern_matching_result = 0;
        }
        else if (_arg1.fields[0] === null) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (_arg1.tag === 4) {
        if (_arg1.fields[0] === "") {
            pattern_matching_result = 0;
        }
        else if (_arg1.fields[0] === null) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (_arg1.tag === 5) {
        if (_arg1.fields[0] === "") {
            pattern_matching_result = 0;
        }
        else if (_arg1.fields[0] === null) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (_arg1.tag === 6) {
        if (_arg1.fields[0] === "") {
            pattern_matching_result = 0;
        }
        else if (_arg1.fields[0] === null) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (_arg1.tag === 7) {
        if (_arg1.fields[0] === "") {
            pattern_matching_result = 0;
        }
        else if (_arg1.fields[0] === null) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (_arg1.tag === 1) {
        if (isEmpty_1(_arg1.fields[0])) {
            pattern_matching_result = 1;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else {
        pattern_matching_result = 2;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new Value(11);
        }
        case 1: {
            return new Value(11);
        }
        case 2: {
            let pattern_matching_result_1;
            if (_arg1.tag === 2) {
                if (isEmpty_1(_arg1.fields[0])) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return new Value(11);
                }
                case 1: {
                    let pattern_matching_result_2;
                    if (_arg1.tag === 10) {
                        if (forAll((arg) => ValueModule_isEmpty(Field_filterEmptyValue(arg)), _arg1.fields[1])) {
                            pattern_matching_result_2 = 0;
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return new Value(11);
                        }
                        case 1: {
                            return _arg1;
                        }
                    }
                }
            }
        }
    }
}

export function Field_mapOrEmpty(map, _arg1) {
    if (_arg1 != null) {
        return map(value_7(_arg1));
    }
    else {
        return new Value(11);
    }
}

export function Field_toCurrencyValue(loc, currency, value) {
    return new Value(0, loc.FormatCurrency(currency, value));
}

export function Field_toUnitValue(loc, unit, value) {
    return new Value(0, loc.FormatUnit(2, unit, value));
}

export function Field_toDateValue(loc) {
    return (arg) => (new Value(0, loc.FormatDate(arg)));
}

