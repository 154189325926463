import { ProgramModule_mkProgram, ProgramModule_runWith } from "./fable_modules/Fable.Elmish.3.1.0/program.fs.js";
import { AI_load } from "../../Import/Fable/Fable.ApplicationInsightsWeb.fs.js";
import { SettingsModule_read } from "./Settings.fs.js";
import { Program_withReactBatched } from "./fable_modules/Fable.Elmish.React.3.0.1/react.fs.js";
import { update, init } from "./State.fs.js";
import { view } from "./View.fs.js";

export function start() {
    ProgramModule_runWith([AI_load("1d806945-dab5-4ca2-a8a7-5670e2267408"), SettingsModule_read()], Program_withReactBatched("elmish-app", ProgramModule_mkProgram((tupledArg) => init(tupledArg[0], tupledArg[1]), update, view)));
}

(function () {
    const matchValue = navigator.serviceWorker;
    if (matchValue != null) {
        const sw = matchValue;
        sw.register("service-worker.js").then((registration) => {
            registration.update().catch();
        }).finally((_arg1) => {
            start();
        });
    }
    else {
        start();
    }
})();

