import { printf, toText } from "./fable_modules/fable-library.3.7.12/String.js";
import { ProductIdModule_toString, SparePartIdModule_toString } from "../SparePartInfo/CommonTypes.fs.js";

export const externalLinks = {
    IllustratedSpares: "https://manuals.ts.fujitsu.com/isc_illustrated_spares",
    MediaPortal: "http://mediaportal.ts.fujitsu.com/pages/search.php?search=!theme3!0\u0026bc_from=themes\u0026de=oo",
    PartnerPortal: "https://partners.ts.fujitsu.com/com/Pages/default.aspx",
};

export const ListLoadStep = 100;

export const EndOfLifeYearForEver = 9999;

export const ExternalUrl = (() => {
    let f2_3, clo2_4, f2_4, clo2_5;
    const hub = "https://bicore.theed.solutions";
    const redirectBase = toText(printf("%s/public/api/ftsproducts/redirect"))(hub);
    let MaterialResources;
    let f2;
    const clo2 = toText(printf("%s/public/api/ftsproducts/material/resources/%s"))(hub);
    f2 = (clo2);
    MaterialResources = ((arg) => f2(SparePartIdModule_toString(arg)));
    let MaterialImage;
    let f2_1;
    const clo2_1 = toText(printf("%s/public/api/ftsproducts/material/image/%s"))(hub);
    f2_1 = (clo2_1);
    MaterialImage = ((arg_1) => f2_1(SparePartIdModule_toString(arg_1)));
    let PackingImage;
    const clo2_2 = toText(printf("%s/public/api/ftsproducts/packing/image/%s"))(hub);
    PackingImage = (clo2_2);
    let ProductPlanningInfo;
    let f2_2;
    const clo2_3 = toText(printf("%s/product/planninginfo/%s"))(redirectBase);
    f2_2 = (clo2_3);
    ProductPlanningInfo = ((arg_2) => f2_2(ProductIdModule_toString(arg_2)));
    return {
        MaterialImage: MaterialImage,
        MaterialInboundInfo: (f2_3 = ((clo2_4 = toText(printf("%s/material/inboundinfo/%s"))(redirectBase), clo2_4)), (arg_3) => f2_3(SparePartIdModule_toString(arg_3))),
        MaterialResources: MaterialResources,
        MaterialReturnInfo: (f2_4 = ((clo2_5 = toText(printf("%s/material/returninfo/%s"))(redirectBase), clo2_5)), (arg_4) => f2_4(SparePartIdModule_toString(arg_4))),
        PackingImage: PackingImage,
        ProductPlanningInfo: ProductPlanningInfo,
    };
})();

