import { englishUS, hungarian, russian, french, german, englishUK } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fable.Date.1.0.0/Date.Local.fs.js";
import { defaultArg } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Option.js";
import { empty, singleton, ofArray, tryPick } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/List.js";
import { LocaleModule_toString, LocaleModule_defaultEnglish, LocaleModule_tryParse } from "../Localization/Locale.fs.js";
import { printf, toText, isNullOrEmpty } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/String.js";
import { replaceWithRegex } from "../Core/String.fs.js";
import { localFormat } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fable.Date.1.0.0/Date.Format.fs.js";
import { Localization } from "../Localization/Localization.fs.js";

export function Locale_toDateLocalization(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return englishUK;
        }
        case 2: {
            return german;
        }
        case 3: {
            return french;
        }
        case 4: {
            return russian;
        }
        case 5: {
            return hungarian;
        }
        default: {
            return englishUS;
        }
    }
}

export function Locale_getLocale() {
    const language = window.navigator.language;
    const languages = window.navigator.languages;
    return defaultArg(tryPick(LocaleModule_tryParse, (!(languages == null)) ? ofArray(languages) : ((!(language == null)) ? singleton(language) : empty())), LocaleModule_defaultEnglish);
}

function Localization_formatDecimal(locale, minimumFractionDigits, maximumFractionDigits, value) {
    const localeStr = LocaleModule_toString(locale);
    return new Intl.NumberFormat(localeStr, { style: 'decimal', minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits }).format(value);
}

function Localization_formatDecimalFixed(locale, fractionDigits, value) {
    return Localization_formatDecimal(locale, fractionDigits, fractionDigits, value);
}

function Localization_formatUnit(locale, maximumFractionDigits, unit, value) {
    const decimalStr = Localization_formatDecimalFixed(locale, maximumFractionDigits, value);
    if (isNullOrEmpty(unit)) {
        return decimalStr;
    }
    else {
        return toText(printf("%s %s"))(decimalStr)(unit);
    }
}

function Localization_formatCurrency(locale, currency, value) {
    const dec = Localization_formatDecimalFixed(locale, 2, value);
    return toText(printf("%s %s"))(dec)(currency);
}

function Localization_formatCurrencyWithEmpty(locale, currency, valueOption) {
    if (valueOption != null) {
        return Localization_formatCurrency(locale, currency, valueOption);
    }
    else {
        return replaceWithRegex(/\d/g, "-", Localization_formatDecimalFixed(locale, 2, 1));
    }
}

export function Localization_get(locale) {
    const dateLocalization = Locale_toDateLocalization(locale);
    return new Localization(locale, (minimumFractionDigits, maximumFractionDigits, value) => Localization_formatDecimal(locale, minimumFractionDigits, maximumFractionDigits, value), (fractionDigits, value_1) => Localization_formatDecimalFixed(locale, fractionDigits, value_1), (maximumFractionDigits_1, unit, value_2) => Localization_formatUnit(locale, maximumFractionDigits_1, unit, value_2), (currency, value_3) => Localization_formatCurrency(locale, currency, value_3), (currency_1, valueOption) => Localization_formatCurrencyWithEmpty(locale, currency_1, valueOption), (date) => localFormat(dateLocalization, dateLocalization.Date.DefaultFormat, date));
}

