import { Record, Union } from "./fable_modules/fable-library.3.7.12/Types.js";
import { record_type, class_type, string_type, union_type } from "./fable_modules/fable-library.3.7.12/Reflection.js";
import { LocaleModule_toString, LocaleModule_tryParse, Locale$reflection } from "../../Fame/Localization/Locale.fs.js";
import { toList, isEmpty, filter, map, toArray } from "./fable_modules/fable-library.3.7.12/Seq.js";
import { defaultValue, split, isNotNullOrEmpty } from "../../Fame/Core/String.fs.js";
import { defaultArgWith, defaultArg } from "./fable_modules/fable-library.3.7.12/Option.js";
import { Locale_getLocale } from "../../Fame/Fable/Locales.fs.js";
import { cons, truncate, head, contains } from "./fable_modules/fable-library.3.7.12/List.js";
import { supportedLocales } from "./Localization.fs.js";
import { safeHash, equals } from "./fable_modules/fable-library.3.7.12/Util.js";
import { Project_defaultTableCols, Product_defaultTableCols, SalesList_defaultTableCols, SparePart_defaultTableCols } from "./Fields.fs.js";
import { join } from "./fable_modules/fable-library.3.7.12/String.js";
import { SparePartIdModule_toString, SparePartIdModule_ofString } from "../SparePartInfo/CommonTypes.fs.js";
import { List_distinct } from "./fable_modules/fable-library.3.7.12/Seq2.js";

export class ListMode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Table", "Tiles"];
    }
}

export function ListMode$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.Settings.ListMode", [], ListMode, () => [[], []]);
}

function ListModeModule_tryParse(_arg1) {
    switch (_arg1) {
        case "table": {
            return new ListMode(0);
        }
        case "tiles": {
            return new ListMode(1);
        }
        default: {
            return void 0;
        }
    }
}

function ListModeModule_toString(_arg1) {
    if (_arg1.tag === 1) {
        return "tiles";
    }
    else {
        return "table";
    }
}

export class TableFields extends Record {
    constructor(SparePartFields, SalesListFields, ProductFields, ProjectFields) {
        super();
        this.SparePartFields = SparePartFields;
        this.SalesListFields = SalesListFields;
        this.ProductFields = ProductFields;
        this.ProjectFields = ProjectFields;
    }
}

export function TableFields$reflection() {
    return record_type("Fame.Fts.Toolbox.SparePartInfo.UI.Settings.TableFields", [], TableFields, () => [["SparePartFields", class_type("System.Collections.Generic.IEnumerable`1", [string_type])], ["SalesListFields", class_type("System.Collections.Generic.IEnumerable`1", [string_type])], ["ProductFields", class_type("System.Collections.Generic.IEnumerable`1", [string_type])], ["ProjectFields", class_type("System.Collections.Generic.IEnumerable`1", [string_type])]]);
}

export class Settings extends Record {
    constructor(Locale, ListMode, TableFields) {
        super();
        this.Locale = Locale;
        this.ListMode = ListMode;
        this.TableFields = TableFields;
    }
}

export function Settings$reflection() {
    return record_type("Fame.Fts.Toolbox.SparePartInfo.UI.Settings.Settings", [], Settings, () => [["Locale", Locale$reflection()], ["ListMode", ListMode$reflection()], ["TableFields", TableFields$reflection()]]);
}

const SettingsModule_keys = {
    Fields: {
        Product: "fields.product",
        Project: "fields.project",
        SalesList: "fields.saleslist",
        SparePart: "fields.sparepart",
    },
    ListMode: "listmode",
    Locale: "locale",
    SparePartHistory: "history.sparepart",
};

function SettingsModule_tryReadFields(key, toField) {
    const fields = toArray(map(toField, filter(isNotNullOrEmpty, split([","], defaultValue("", localStorage.getItem(key))))));
    if (fields.length === 0) {
        return void 0;
    }
    else {
        return fields;
    }
}

export function SettingsModule_read() {
    let l;
    const ListMode_1 = defaultArg(ListModeModule_tryParse(localStorage.getItem(SettingsModule_keys.ListMode)), new ListMode(1));
    return new Settings((l = defaultArgWith(LocaleModule_tryParse(localStorage.getItem(SettingsModule_keys.Locale)), Locale_getLocale), contains(l, supportedLocales, {
        Equals: equals,
        GetHashCode: safeHash,
    }) ? l : head(supportedLocales)), ListMode_1, new TableFields(defaultArg(SettingsModule_tryReadFields(SettingsModule_keys.Fields.SparePart, (value_1) => value_1), SparePart_defaultTableCols), defaultArg(SettingsModule_tryReadFields(SettingsModule_keys.Fields.SalesList, (value_2) => value_2), SalesList_defaultTableCols), defaultArg(SettingsModule_tryReadFields(SettingsModule_keys.Fields.Product, (value_3) => value_3), Product_defaultTableCols), defaultArg(SettingsModule_tryReadFields(SettingsModule_keys.Fields.Project, (value_4) => value_4), Project_defaultTableCols)));
}

function SettingsModule_saveFields(key, fields, defaultFields) {
    if (isEmpty(fields) ? true : equals(fields, defaultFields)) {
        localStorage.removeItem(key);
    }
    else {
        const value_1 = join(",", map((value) => value, fields));
        localStorage.setItem(key, value_1);
    }
}

export function SettingsModule_save(settings) {
    localStorage.setItem(SettingsModule_keys.Locale, LocaleModule_toString(settings.Locale));
    localStorage.setItem(SettingsModule_keys.ListMode, ListModeModule_toString(settings.ListMode));
    SettingsModule_saveFields(SettingsModule_keys.Fields.SparePart, settings.TableFields.SparePartFields, SparePart_defaultTableCols);
    SettingsModule_saveFields(SettingsModule_keys.Fields.SalesList, settings.TableFields.SalesListFields, SalesList_defaultTableCols);
    SettingsModule_saveFields(SettingsModule_keys.Fields.Product, settings.TableFields.ProductFields, Product_defaultTableCols);
    SettingsModule_saveFields(SettingsModule_keys.Fields.Project, settings.TableFields.ProjectFields, Project_defaultTableCols);
}

export function SettingsModule_readSparePartHistory() {
    return toList(map(SparePartIdModule_ofString, filter(isNotNullOrEmpty, split([","], defaultValue("", localStorage.getItem(SettingsModule_keys.SparePartHistory))))));
}

export function SettingsModule_addToSparePartHistory(sparePartId) {
    const sparePartIds = truncate(12, List_distinct(cons(sparePartId, SettingsModule_readSparePartHistory()), {
        Equals: equals,
        GetHashCode: safeHash,
    }));
    localStorage.setItem(SettingsModule_keys.SparePartHistory, join(",", map(SparePartIdModule_toString, sparePartIds)));
}

