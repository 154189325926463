import { Union } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Types.js";
import { union_type, bool_type, int32_type } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Reflection.js";
import { keyValueList } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/MapUtil.js";
import * as react from "react";
import react_lazyload from "react-lazyload";

export class Prop extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Height", "Scroll", "Resize", "Once", "Offset", "UnmountIfInvisible"];
    }
}

export function Prop$reflection() {
    return union_type("Fable.ReactLazyLoad.LazyLoad.Prop", [], Prop, () => [[["Item", int32_type]], [["Item", bool_type]], [["Item", bool_type]], [], [["Item", int32_type]], []]);
}

export function lazyLoad(props, children) {
    const props_1 = keyValueList(props, 1);
    return react.createElement(react_lazyload, props_1, ...children);
}

