import { Option, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Modifier_IModifier } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { map as map_1, ofArray, empty, singleton as singleton_1 } from "../fable_modules/fable-library.3.7.12/List.js";
import { HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { SearchResultModule_toSearchType, Msg, LoadedMsg, ListMsg } from "../State.fs.js";
import { icon } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { h1 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { section } from "../fable_modules/Fulma.2.16.0/Layouts/Section.fs.js";
import { container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { SearchTypeModule_all, Route, RouteModule_toLink, SearchTypeModule_getCount, SearchTypeModule_translate } from "../Routing.fs.js";
import { equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { Tabs, Tab } from "../../../Fame.Fts.Shared/Shared.UI/Components/Tabs.fs.js";
import { createElement } from "react";
import { view as view_1 } from "../Components/List.fs.js";
import { SearchFilterModule_withOrder } from "../Context.fs.js";

export function view(loc, isInternal, settings, model, dispatch) {
    let ts, originalQuery, filterToRoute, navigateToFilter;
    let heading;
    const exportButton = button(toList(delay(() => append(singleton(new Option(20, singleton_1(new Modifier_IModifier(12)))), delay(() => append(singleton(new Option(17, singleton_1(new HTMLAttr(27, loc.Translation.ExportExcel)))), delay(() => (model.IsExporting ? singleton(new Option(13, true)) : singleton(new Option(18, (_arg1) => {
        dispatch(new Msg(4, new LoadedMsg(4, new ListMsg(0))));
    }))))))))), singleton_1(icon(empty(), singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-file-excel")), [])))));
    const text = defaultArg(map((query) => toText(printf("%s: %s"))(loc.Translation.SearchResultsFor)(query), model.SearchFilter.Query), loc.Translation.AllEntries);
    heading = h1(empty())(ofArray([text, exportButton]));
    const activeSearchType = SearchResultModule_toSearchType(model.SearchResult);
    return section(empty(), singleton_1(container(empty(), ofArray([heading, (ts = map_1((searchType) => {
        const title = SearchTypeModule_translate(loc.Translation, searchType);
        const count = SearchTypeModule_getCount(model.SearchResultCounts, searchType) | 0;
        return new Tab(toText(printf("%s (%d)"))(title)(count), equals(searchType, activeSearchType), (value_1) => {
        }, RouteModule_toLink(new Route(6, searchType, model.SearchFilter)));
    }, SearchTypeModule_all), createElement(Tabs, {
        ts: ts,
    })), (originalQuery = defaultArg(model.SearchFilter.Query, ""), (filterToRoute = ((filter) => (new Route(6, activeSearchType, filter))), (navigateToFilter = ((arg_1) => {
        dispatch(new Msg(2, filterToRoute(arg_1)));
    }), view_1(loc, isInternal, settings, model.SearchFilter, model.SearchResult, filterToRoute, navigateToFilter, () => {
        dispatch(new Msg(4, new LoadedMsg(1, originalQuery)));
    }, (arg_2) => {
        dispatch(new Msg(1, arg_2));
    }, (arg_3) => {
        let tupledArg;
        navigateToFilter((tupledArg = arg_3, SearchFilterModule_withOrder(model.SearchFilter, tupledArg[0], tupledArg[1])));
    }))))]))));
}

