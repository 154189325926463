import { FunctionComponent_Of_Z5A158BBF } from "../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import * as react from "react";
import { empty, ofArray, singleton, map } from "../fable_modules/fable-library.3.7.12/List.js";
import { Option, image } from "../fable_modules/Fulma.2.16.0/Elements/Image.fs.js";
import $00301 from "../Assets/picture-key/01.jpg";
import $00302 from "../Assets/picture-key/02.jpg";
import $00303 from "../Assets/picture-key/03.jpg";
import $00304 from "../Assets/picture-key/04.jpg";
import $00305 from "../Assets/picture-key/05.jpg";
import $00306 from "../Assets/picture-key/06.jpg";
import $00307 from "../Assets/picture-key/07.jpg";
import $00308 from "../Assets/picture-key/08.jpg";
import $00309 from "../Assets/picture-key/09.jpg";
import $00310 from "../Assets/picture-key/10.jpg";
import $00311 from "../Assets/picture-key/11.jpg";
import $00312 from "../Assets/picture-key/12.jpg";
import $00313 from "../Assets/picture-key/13.jpg";
import $00314 from "../Assets/picture-key/14.jpg";
import $00315 from "../Assets/picture-key/15.jpg";
import $00316 from "../Assets/picture-key/16.jpg";
import $00317 from "../Assets/picture-key/17.jpg";
import $00318 from "../Assets/picture-key/18.jpg";
import $00319 from "../Assets/picture-key/19.jpg";
import $00320 from "../Assets/picture-key/20.jpg";
import $00321 from "../Assets/picture-key/21.jpg";
import $00322 from "../Assets/picture-key/22.jpg";
import $00323 from "../Assets/picture-key/23.jpg";
import $00324 from "../Assets/picture-key/24.jpg";
import $00325 from "../Assets/picture-key/25.jpg";
import $00326 from "../Assets/picture-key/26.jpg";
import $00327 from "../Assets/picture-key/27.jpg";
import $00328 from "../Assets/picture-key/28.jpg";
import $00329 from "../Assets/picture-key/29.jpg";
import $00330 from "../Assets/picture-key/30.jpg";
import $00331 from "../Assets/picture-key/31.jpg";
import $00332 from "../Assets/picture-key/32.jpg";
import $00333 from "../Assets/picture-key/33.jpg";
import $00334 from "../Assets/picture-key/34.jpg";
import $00335 from "../Assets/picture-key/35.jpg";
import { Option as Option_1, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Common_GenericOption, Size_ISize } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option as Option_2, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Option as Option_3, delete$ } from "../fable_modules/Fulma.2.16.0/Elements/Delete.fs.js";
import { uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";

export const internalView = FunctionComponent_Of_Z5A158BBF((tr) => {
    let children_6;
    const openState = react.useState(false);
    const close = (_arg2) => {
        openState[1](false);
    };
    const entries = map((tupledArg) => {
        let children_2;
        const children_4 = [image(singleton(new Option(2)), singleton(react.createElement("img", {
            src: tupledArg[0],
        }))), (children_2 = [react.createElement("p", {}, tupledArg[1])], react.createElement("div", {}, ...children_2))];
        return react.createElement("div", {}, ...children_4);
    }, ofArray([[$00301, tr.Key01], [$00302, tr.Key02], [$00303, tr.Key03], [$00304, tr.Key04], [$00305, tr.Key05], [$00306, tr.Key06], [$00307, tr.Key07], [$00308, tr.Key08], [$00309, tr.Key09], [$00310, tr.Key10], [$00311, tr.Key11], [$00312, tr.Key12], [$00313, tr.Key13], [$00314, tr.Key14], [$00315, tr.Key15], [$00316, tr.Key16], [$00317, tr.Key17], [$00318, tr.Key18], [$00319, tr.Key19], [$00320, tr.Key20], [$00321, tr.Key21], [$00322, tr.Key22], [$00323, tr.Key23], [$00324, tr.Key24], [$00325, tr.Key25], [$00326, tr.Key26], [$00327, tr.Key27], [$00328, tr.Key28], [$00329, tr.Key29], [$00330, tr.Key30], [$00331, tr.Key31], [$00332, tr.Key32], [$00333, tr.Key33], [$00334, tr.Key34], [$00335, tr.Key35]]));
    const children_10 = [(children_6 = [button(ofArray([new Option_1(18, (_arg1) => {
        const arg00 = !(openState[0]);
        openState[1](arg00);
    }), new Option_1(1, new Size_ISize(0))]), singleton(tr.ShowKey))], react.createElement("div", {
        className: "picture-key-control",
    }, ...children_6)), modal(singleton(new Option_2(1, openState[0])), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, close)))), empty()), Card_card(empty(), ofArray([Card_head(empty(), ofArray([Card_title(empty(), singleton(tr.PictureKey)), delete$(singleton(new Option_3(3, close)), empty())])), Card_body(empty(), singleton(react.createElement("div", {
        className: "picture-key-entries",
    }, ...entries))), Card_foot(empty(), singleton(button(singleton(new Option_1(18, close)), singleton(tr.Close))))]))]))];
    return react.createElement("div", {
        className: "picture-key",
    }, ...children_10);
}, void 0, uncurry(2, void 0), void 0, "internalView", "/home/vsts/work/1/s/src/Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/Components/PictureKey.fs", 12);

export function view(translation) {
    return internalView(translation);
}

