import { toString, Union } from "./fable_modules/fable-library.3.7.12/Types.js";
import { option_type, union_type } from "./fable_modules/fable-library.3.7.12/Reflection.js";
import { singleton as singleton_1, filter as filter_4, map as map_1, cons, head, tail, isEmpty, fold, ofArray } from "./fable_modules/fable-library.3.7.12/List.js";
import { isNotNullOrEmpty, ofNullable, split } from "../../Fame/Core/String.fs.js";
import { empty, singleton, append, delay, toList, skip, tryHead } from "./fable_modules/fable-library.3.7.12/Seq.js";
import { isNullOrEmpty, printf, toText, join } from "./fable_modules/fable-library.3.7.12/String.js";
import { List_except, List_distinct } from "./fable_modules/fable-library.3.7.12/Seq2.js";
import { arrayHash, equalArrays, uncurry, safeHash, equals } from "./fable_modules/fable-library.3.7.12/Util.js";
import { SearchFilter$reflection, SearchFilterModule_empty, CentralLocalModule_toString, OrderDirectionModule_toString, ProjectFilter as ProjectFilter_1, ProductFilter as ProductFilter_1, CentralLocalModule_tryParse, SparePartFilter as SparePartFilter_1, Order, OrderDirectionModule_tryParse, Page, LookupFilter, SearchFilter } from "./Context.fs.js";
import { ProjectId$reflection, ProductId$reflection, SalesListId$reflection, SparePartId$reflection, SparePartIdModule_toString, ProjectIdModule_toString, ProductIdModule_toString, SalesListIdModule_toString, ProjectIdModule_ofString, ProductIdModule_ofString, SalesListIdModule_ofString, SparePartIdModule_ofString } from "../SparePartInfo/CommonTypes.fs.js";
import { value as value_27, defaultArg, some, map } from "./fable_modules/fable-library.3.7.12/Option.js";
import { tryParse } from "../../Fame/Core/Int.fs.js";
import { RouterModule_nav, RouterModule_encodeQueryString, RouterModule_encodeParts } from "./fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { Cmd_ofSub } from "./fable_modules/Feliz.Router.3.8.0/../Fable.Elmish.3.1.0/cmd.fs.js";
import { Route_$007CQuery$007C_$007C } from "./fable_modules/Feliz.Router.3.8.0/Router.fs.js";

export class SearchType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SparePart", "SalesList", "Product", "Project"];
    }
}

export function SearchType$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.Routing.SearchType", [], SearchType, () => [[], [], [], []]);
}

export const SearchTypeModule_all = ofArray([new SearchType(0), new SearchType(1), new SearchType(2), new SearchType(3)]);

export function SearchTypeModule_tryParse(input) {
    switch (input) {
        case "sparepart": {
            return new SearchType(0);
        }
        case "saleslist": {
            return new SearchType(1);
        }
        case "product": {
            return new SearchType(2);
        }
        case "project": {
            return new SearchType(3);
        }
        default: {
            return void 0;
        }
    }
}

export function SearchTypeModule_toString(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return "saleslist";
        }
        case 2: {
            return "product";
        }
        case 3: {
            return "project";
        }
        default: {
            return "sparepart";
        }
    }
}

export function SearchTypeModule_translate(tr, _arg1) {
    switch (_arg1.tag) {
        case 1: {
            return tr.SalesLists;
        }
        case 2: {
            return tr.Products;
        }
        case 3: {
            return tr.Projects;
        }
        default: {
            return tr.SpareParts;
        }
    }
}

export function SearchTypeModule_getCount(counts, _arg1) {
    switch (_arg1.tag) {
        case 1: {
            return counts.SalesLists | 0;
        }
        case 2: {
            return counts.Products | 0;
        }
        case 3: {
            return counts.Projects | 0;
        }
        default: {
            return counts.SpareParts | 0;
        }
    }
}

function SearchFilter_isTruthyString(input) {
    switch (input) {
        case "true":
        case "1":
        case "yes":
        case "y": {
            return true;
        }
        default: {
            return false;
        }
    }
}

function SearchFilter_$007CHasPrefix$007C_$007C(prefix, key) {
    const parts = split(["."], key);
    const matchValue = tryHead(parts);
    let pattern_matching_result;
    if (matchValue != null) {
        if (matchValue === prefix) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return join(".", skip(1, parts));
        }
        case 1: {
            return void 0;
        }
    }
}

export function SearchFilter_distinct(searchFilter) {
    return new SearchFilter(searchFilter.Query, searchFilter.Lookup, searchFilter.Page, searchFilter.Order, searchFilter.PageSize, List_distinct(searchFilter.SparePartFilter, {
        Equals: equals,
        GetHashCode: safeHash,
    }), List_distinct(searchFilter.ProductFilter, {
        Equals: equals,
        GetHashCode: safeHash,
    }), List_distinct(searchFilter.ProjectFilter, {
        Equals: equals,
        GetHashCode: safeHash,
    }));
}

export function SearchFilter_except(toExclude, searchFilter) {
    return new SearchFilter(searchFilter.Query, equals(searchFilter.Lookup, toExclude.Lookup) ? (void 0) : searchFilter.Lookup, searchFilter.Page, searchFilter.Order, searchFilter.PageSize, List_except(toExclude.SparePartFilter, searchFilter.SparePartFilter, {
        Equals: equals,
        GetHashCode: safeHash,
    }), List_except(toExclude.ProductFilter, searchFilter.ProductFilter, {
        Equals: equals,
        GetHashCode: safeHash,
    }), List_except(toExclude.ProjectFilter, searchFilter.ProjectFilter, {
        Equals: equals,
        GetHashCode: safeHash,
    }));
}

export function SearchFilter_fromQueries(initial, queries) {
    const tupledArg_3 = fold(uncurry(2, (tupledArg) => {
        const searchTypeOption = tupledArg[0];
        const searchFilter = tupledArg[1];
        return (tupledArg_1) => {
            const key = tupledArg_1[0];
            const value = tupledArg_1[1];
            if (key === "type") {
                return [SearchTypeModule_tryParse(value), searchFilter];
            }
            else if (key === "query") {
                return [searchTypeOption, new SearchFilter(ofNullable(value), searchFilter.Lookup, searchFilter.Page, searchFilter.Order, searchFilter.PageSize, searchFilter.SparePartFilter, searchFilter.ProductFilter, searchFilter.ProjectFilter)];
            }
            else if (key === "sparepart") {
                return [searchTypeOption, new SearchFilter(searchFilter.Query, new LookupFilter(0, SparePartIdModule_ofString(value)), searchFilter.Page, searchFilter.Order, searchFilter.PageSize, searchFilter.SparePartFilter, searchFilter.ProductFilter, searchFilter.ProjectFilter)];
            }
            else if (key === "saleslist") {
                return [searchTypeOption, new SearchFilter(searchFilter.Query, new LookupFilter(1, SalesListIdModule_ofString(value)), searchFilter.Page, searchFilter.Order, searchFilter.PageSize, searchFilter.SparePartFilter, searchFilter.ProductFilter, searchFilter.ProjectFilter)];
            }
            else if (key === "product") {
                return [searchTypeOption, new SearchFilter(searchFilter.Query, new LookupFilter(2, ProductIdModule_ofString(value)), searchFilter.Page, searchFilter.Order, searchFilter.PageSize, searchFilter.SparePartFilter, searchFilter.ProductFilter, searchFilter.ProjectFilter)];
            }
            else if (key === "project") {
                return [searchTypeOption, new SearchFilter(searchFilter.Query, new LookupFilter(3, ProjectIdModule_ofString(value)), searchFilter.Page, searchFilter.Order, searchFilter.PageSize, searchFilter.SparePartFilter, searchFilter.ProductFilter, searchFilter.ProjectFilter)];
            }
            else {
                const activePatternResult30731 = SearchFilter_$007CHasPrefix$007C_$007C("page", key);
                if (activePatternResult30731 != null) {
                    const subKey = activePatternResult30731;
                    const page = searchFilter.Page;
                    const _arg1 = (subKey === "sparepart") ? map((p) => (new Page(p, page.SalesListPage, page.ProductPage, page.ProjectPage)), tryParse(value)) : ((subKey === "saleslist") ? map((p_1) => (new Page(page.SparePartPage, p_1, page.ProductPage, page.ProjectPage)), tryParse(value)) : ((subKey === "product") ? map((p_2) => (new Page(page.SparePartPage, page.SalesListPage, p_2, page.ProjectPage)), tryParse(value)) : ((subKey === "project") ? map((p_3) => (new Page(page.SparePartPage, page.SalesListPage, page.ProductPage, p_3)), tryParse(value)) : (void 0))));
                    return (_arg1 == null) ? [searchTypeOption, searchFilter] : [searchTypeOption, new SearchFilter(searchFilter.Query, searchFilter.Lookup, _arg1, searchFilter.Order, searchFilter.PageSize, searchFilter.SparePartFilter, searchFilter.ProductFilter, searchFilter.ProjectFilter)];
                }
                else {
                    const activePatternResult30729 = SearchFilter_$007CHasPrefix$007C_$007C("order", key);
                    if (activePatternResult30729 != null) {
                        const subKey_1 = activePatternResult30729;
                        let fieldDirectionOption;
                        const matchValue = toList(split([" "], value));
                        let pattern_matching_result, direction, field;
                        if (!isEmpty(matchValue)) {
                            if (!isEmpty(tail(matchValue))) {
                                if (isEmpty(tail(tail(matchValue)))) {
                                    pattern_matching_result = 0;
                                    direction = head(tail(matchValue));
                                    field = head(matchValue);
                                }
                                else {
                                    pattern_matching_result = 1;
                                }
                            }
                            else {
                                pattern_matching_result = 1;
                            }
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                        switch (pattern_matching_result) {
                            case 0: {
                                fieldDirectionOption = map((d) => [field, d], OrderDirectionModule_tryParse(direction));
                                break;
                            }
                            case 1: {
                                fieldDirectionOption = (void 0);
                                break;
                            }
                        }
                        const order = searchFilter.Order;
                        const withOrderType = (toFieldType, withOrder) => some(withOrder(map((tupledArg_2) => [toFieldType(tupledArg_2[0]), tupledArg_2[1]], fieldDirectionOption)));
                        const _arg2 = (subKey_1 === "sparepart") ? withOrderType((value_1) => value_1, (o_1) => (new Order(o_1, order.SalesListOrder, order.ProductOrder, order.ProjectOrder))) : ((subKey_1 === "saleslist") ? withOrderType((value_2) => value_2, (o_2) => (new Order(order.SparePartOrder, o_2, order.ProductOrder, order.ProjectOrder))) : ((subKey_1 === "product") ? withOrderType((value_3) => value_3, (o_3) => (new Order(order.SparePartOrder, order.SalesListOrder, o_3, order.ProjectOrder))) : ((subKey_1 === "project") ? withOrderType((value_4) => value_4, (o_4) => (new Order(order.SparePartOrder, order.SalesListOrder, order.ProductOrder, o_4))) : (void 0))));
                        return (_arg2 == null) ? [searchTypeOption, searchFilter] : [searchTypeOption, new SearchFilter(searchFilter.Query, searchFilter.Lookup, searchFilter.Page, _arg2, searchFilter.PageSize, searchFilter.SparePartFilter, searchFilter.ProductFilter, searchFilter.ProjectFilter)];
                    }
                    else {
                        const activePatternResult30727 = SearchFilter_$007CHasPrefix$007C_$007C("sparepart", key);
                        if (activePatternResult30727 != null) {
                            const subKey_2 = activePatternResult30727;
                            const _arg3 = (subKey_2 === "division") ? (new SparePartFilter_1(1, value)) : ((subKey_2 === "hierarchy") ? (new SparePartFilter_1(2, value)) : ((subKey_2 === "engineer") ? (new SparePartFilter_1(3, value)) : ((subKey_2 === "planner") ? (new SparePartFilter_1(4, value)) : ((subKey_2 === "criticalpart") ? (new SparePartFilter_1(5, (value === "na") ? (void 0) : SearchFilter_isTruthyString(value))) : ((subKey_2 === "endoflife") ? (new SparePartFilter_1(6, SearchFilter_isTruthyString(value))) : ((subKey_2 === "centrallocal") ? map((arg0_17) => (new SparePartFilter_1(7, arg0_17)), CentralLocalModule_tryParse(value)) : (void 0)))))));
                            return (_arg3 == null) ? [searchTypeOption, searchFilter] : [searchTypeOption, new SearchFilter(searchFilter.Query, searchFilter.Lookup, searchFilter.Page, searchFilter.Order, searchFilter.PageSize, cons(_arg3, searchFilter.SparePartFilter), searchFilter.ProductFilter, searchFilter.ProjectFilter)];
                        }
                        else {
                            const activePatternResult30725 = SearchFilter_$007CHasPrefix$007C_$007C("product", key);
                            if (activePatternResult30725 != null) {
                                const subKey_3 = activePatternResult30725;
                                const _arg4 = (subKey_3 === "hierarchy") ? (new ProductFilter_1(0, value)) : ((subKey_3 === "productgroupplant") ? (new ProductFilter_1(1, value)) : ((subKey_3 === "engineer") ? (new ProductFilter_1(2, value)) : ((subKey_3 === "endoflife") ? (new ProductFilter_1(3, SearchFilter_isTruthyString(value))) : ((subKey_3 === "centrallocal") ? map((arg0_22) => (new ProductFilter_1(4, arg0_22)), CentralLocalModule_tryParse(value)) : (void 0)))));
                                return (_arg4 == null) ? [searchTypeOption, searchFilter] : [searchTypeOption, new SearchFilter(searchFilter.Query, searchFilter.Lookup, searchFilter.Page, searchFilter.Order, searchFilter.PageSize, searchFilter.SparePartFilter, cons(_arg4, searchFilter.ProductFilter), searchFilter.ProjectFilter)];
                            }
                            else {
                                const activePatternResult30723 = SearchFilter_$007CHasPrefix$007C_$007C("project", key);
                                if (activePatternResult30723 != null) {
                                    const subKey_4 = activePatternResult30723;
                                    const _arg5 = (subKey_4 === "hierarchy") ? (new ProjectFilter_1(0, value)) : ((subKey_4 === "productgroupplant") ? (new ProjectFilter_1(1, value)) : ((subKey_4 === "engineer") ? (new ProjectFilter_1(2, value)) : ((subKey_4 === "endoflife") ? (new ProjectFilter_1(3, SearchFilter_isTruthyString(value))) : (void 0))));
                                    return (_arg5 == null) ? [searchTypeOption, searchFilter] : [searchTypeOption, new SearchFilter(searchFilter.Query, searchFilter.Lookup, searchFilter.Page, searchFilter.Order, searchFilter.PageSize, searchFilter.SparePartFilter, searchFilter.ProductFilter, cons(_arg5, searchFilter.ProjectFilter))];
                                }
                                else {
                                    return [searchTypeOption, searchFilter];
                                }
                            }
                        }
                    }
                }
            }
        };
    }), [void 0, initial], queries);
    return [tupledArg_3[0], SearchFilter_distinct(tupledArg_3[1])];
}

export function SearchFilter_toQueries(searchTypeOption, searchFilter) {
    const addPrefix = (prefix, list) => map_1((tupledArg) => [(prefix + ".") + tupledArg[0], tupledArg[1]], list);
    const pages = addPrefix("page", map_1((tupledArg_2) => [tupledArg_2[0], toText(printf("%d"))(tupledArg_2[1])], filter_4((tupledArg_1) => (tupledArg_1[1] > 1), ofArray([["sparepart", searchFilter.Page.SparePartPage], ["saleslist", searchFilter.Page.SalesListPage], ["product", searchFilter.Page.ProductPage], ["project", searchFilter.Page.ProjectPage]]))));
    const orders = addPrefix("order", filter_4((tupledArg_9) => isNotNullOrEmpty(tupledArg_9[1]), map_1((tupledArg_7) => [tupledArg_7[0], defaultArg(map((tupledArg_8) => {
        const arg20 = OrderDirectionModule_toString(tupledArg_8[1]);
        return toText(printf("%s %s"))(tupledArg_8[0])(arg20);
    }, tupledArg_7[1]), "")], ofArray([["sparepart", map((tupledArg_3) => [tupledArg_3[0], tupledArg_3[1]], searchFilter.Order.SparePartOrder)], ["saleslist", map((tupledArg_4) => [tupledArg_4[0], tupledArg_4[1]], searchFilter.Order.SalesListOrder)], ["product", map((tupledArg_5) => [tupledArg_5[0], tupledArg_5[1]], searchFilter.Order.ProductOrder)], ["project", map((tupledArg_6) => [tupledArg_6[0], tupledArg_6[1]], searchFilter.Order.ProjectOrder)]]))));
    return List_distinct(filter_4((tupledArg_10) => (!isNullOrEmpty(tupledArg_10[1])), toList(delay(() => append((searchTypeOption != null) ? singleton(["type", SearchTypeModule_toString(value_27(searchTypeOption))]) : empty(), delay(() => append((searchFilter.Query != null) ? singleton(["query", value_27(searchFilter.Query)]) : empty(), delay(() => {
        let _arg1;
        return append((searchFilter.Lookup != null) ? singleton((_arg1 = value_27(searchFilter.Lookup), (_arg1.tag === 1) ? ["saleslist", SalesListIdModule_toString(_arg1.fields[0])] : ((_arg1.tag === 2) ? ["product", ProductIdModule_toString(_arg1.fields[0])] : ((_arg1.tag === 3) ? ["project", ProjectIdModule_toString(_arg1.fields[0])] : ["sparepart", SparePartIdModule_toString(_arg1.fields[0])])))) : empty(), delay(() => append(pages, delay(() => append(orders, delay(() => append(addPrefix("sparepart", map_1((_arg2) => {
            switch (_arg2.tag) {
                case 1: {
                    return ["division", _arg2.fields[0]];
                }
                case 2: {
                    return ["hierarchy", _arg2.fields[0]];
                }
                case 3: {
                    return ["engineer", _arg2.fields[0]];
                }
                case 4: {
                    return ["planner", _arg2.fields[0]];
                }
                case 5: {
                    const value_9 = _arg2.fields[0];
                    if (value_9 != null) {
                        return ["criticalpart", toString(value_9)];
                    }
                    else {
                        return ["criticalpart", "na"];
                    }
                }
                case 6: {
                    return ["endoflife", toString(_arg2.fields[0])];
                }
                case 7: {
                    return ["centrallocal", CentralLocalModule_toString(_arg2.fields[0])];
                }
                default: {
                    return ["materialno", SparePartIdModule_toString(_arg2.fields[0])];
                }
            }
        }, searchFilter.SparePartFilter)), delay(() => append(addPrefix("product", map_1((_arg3) => {
            switch (_arg3.tag) {
                case 1: {
                    return ["productgroupplant", _arg3.fields[0]];
                }
                case 2: {
                    return ["engineer", _arg3.fields[0]];
                }
                case 3: {
                    return ["endoflife", toString(_arg3.fields[0])];
                }
                case 4: {
                    return ["centrallocal", CentralLocalModule_toString(_arg3.fields[0])];
                }
                default: {
                    return ["hierarchy", _arg3.fields[0]];
                }
            }
        }, searchFilter.ProductFilter)), delay(() => addPrefix("project", map_1((_arg4) => {
            switch (_arg4.tag) {
                case 1: {
                    return ["productgroupplant", _arg4.fields[0]];
                }
                case 2: {
                    return ["engineer", _arg4.fields[0]];
                }
                case 3: {
                    return ["endoflife", toString(_arg4.fields[0])];
                }
                default: {
                    return ["hierarchy", _arg4.fields[0]];
                }
            }
        }, searchFilter.ProjectFilter))))))))))));
    }))))))), {
        Equals: equalArrays,
        GetHashCode: arrayHash,
    });
}

export function SearchFilter_ofSparePartId(id) {
    return new SearchFilter(SearchFilterModule_empty.Query, new LookupFilter(0, id), SearchFilterModule_empty.Page, SearchFilterModule_empty.Order, SearchFilterModule_empty.PageSize, SearchFilterModule_empty.SparePartFilter, SearchFilterModule_empty.ProductFilter, SearchFilterModule_empty.ProjectFilter);
}

export function SearchFilter_ofSalesListId(id) {
    return new SearchFilter(SearchFilterModule_empty.Query, new LookupFilter(1, id), SearchFilterModule_empty.Page, SearchFilterModule_empty.Order, SearchFilterModule_empty.PageSize, SearchFilterModule_empty.SparePartFilter, SearchFilterModule_empty.ProductFilter, SearchFilterModule_empty.ProjectFilter);
}

export function SearchFilter_ofProductId(id) {
    return new SearchFilter(SearchFilterModule_empty.Query, new LookupFilter(2, id), SearchFilterModule_empty.Page, SearchFilterModule_empty.Order, SearchFilterModule_empty.PageSize, SearchFilterModule_empty.SparePartFilter, SearchFilterModule_empty.ProductFilter, SearchFilterModule_empty.ProjectFilter);
}

export function SearchFilter_ofProjectId(id) {
    return new SearchFilter(SearchFilterModule_empty.Query, new LookupFilter(3, id), SearchFilterModule_empty.Page, SearchFilterModule_empty.Order, SearchFilterModule_empty.PageSize, SearchFilterModule_empty.SparePartFilter, SearchFilterModule_empty.ProductFilter, SearchFilterModule_empty.ProjectFilter);
}

export class Route extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotFound", "Start", "SparePart", "SalesList", "Product", "Project", "Search"];
    }
}

export function Route$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.Routing.Route", [], Route, () => [[], [], [["Item1", SparePartId$reflection()], ["Item2", option_type(SearchType$reflection())], ["Item3", SearchFilter$reflection()]], [["Item1", SalesListId$reflection()], ["Item2", option_type(SearchType$reflection())], ["Item3", SearchFilter$reflection()]], [["Item1", ProductId$reflection()], ["Item2", option_type(SearchType$reflection())], ["Item3", SearchFilter$reflection()]], [["Item1", ProjectId$reflection()], ["Item2", option_type(SearchType$reflection())], ["Item3", SearchFilter$reflection()]], [["Item1", SearchType$reflection()], ["Item2", SearchFilter$reflection()]]]);
}

export function RouteModule_ofSparePartId(id) {
    return new Route(2, id, void 0, SearchFilter_ofSparePartId(id));
}

export function RouteModule_ofSalesListId(id) {
    return new Route(3, id, void 0, SearchFilter_ofSalesListId(id));
}

export function RouteModule_ofProductId(id) {
    return new Route(4, id, void 0, SearchFilter_ofProductId(id));
}

export function RouteModule_ofProjectId(id) {
    return new Route(5, id, void 0, SearchFilter_ofProjectId(id));
}

export function RouteModule_tryGetQuery(_arg1) {
    if (_arg1.tag === 6) {
        return _arg1.fields[1].Query;
    }
    else {
        return void 0;
    }
}

export function RouteModule_toLink(route) {
    switch (route.tag) {
        case 2: {
            const id = route.fields[0];
            const queries = SearchFilter_toQueries(route.fields[1], SearchFilter_except(SearchFilter_ofSparePartId(id), route.fields[2]));
            return RouterModule_encodeParts(ofArray(["sparepart", SparePartIdModule_toString(id) + RouterModule_encodeQueryString(queries)]), 1);
        }
        case 3: {
            const id_1 = route.fields[0];
            const queries_1 = SearchFilter_toQueries(route.fields[1], SearchFilter_except(SearchFilter_ofSalesListId(id_1), route.fields[2]));
            return RouterModule_encodeParts(ofArray(["saleslist", SalesListIdModule_toString(id_1) + RouterModule_encodeQueryString(queries_1)]), 1);
        }
        case 4: {
            const id_2 = route.fields[0];
            const queries_2 = SearchFilter_toQueries(route.fields[1], SearchFilter_except(SearchFilter_ofProductId(id_2), route.fields[2]));
            return RouterModule_encodeParts(ofArray(["product", ProductIdModule_toString(id_2) + RouterModule_encodeQueryString(queries_2)]), 1);
        }
        case 5: {
            const id_3 = route.fields[0];
            const queries_3 = SearchFilter_toQueries(route.fields[1], SearchFilter_except(SearchFilter_ofProjectId(id_3), route.fields[2]));
            return RouterModule_encodeParts(ofArray(["project", ProjectIdModule_toString(id_3) + RouterModule_encodeQueryString(queries_3)]), 1);
        }
        case 1: {
            return RouterModule_encodeParts(singleton_1(""), 1);
        }
        case 0: {
            return RouterModule_encodeParts(singleton_1("404"), 1);
        }
        default: {
            return RouterModule_encodeParts(singleton_1("search" + RouterModule_encodeQueryString(SearchFilter_toQueries(route.fields[0], route.fields[1]))), 1);
        }
    }
}

export function RouteModule_navigate(route) {
    const arg00 = RouteModule_toLink(route);
    return Cmd_ofSub((_arg62) => {
        RouterModule_nav(singleton_1(arg00), 1, 1);
    });
}

export function RouteModule_ofSegments(_arg1) {
    let pattern_matching_result, queries;
    if (!isEmpty(_arg1)) {
        if (head(_arg1) === "search") {
            if (!isEmpty(tail(_arg1))) {
                const activePatternResult31369 = Route_$007CQuery$007C_$007C(head(tail(_arg1)));
                if (activePatternResult31369 != null) {
                    if (isEmpty(tail(tail(_arg1)))) {
                        pattern_matching_result = 0;
                        queries = activePatternResult31369;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const patternInput = SearchFilter_fromQueries(SearchFilterModule_empty, queries);
            return new Route(6, defaultArg(patternInput[0], new SearchType(0)), patternInput[1]);
        }
        case 1: {
            let pattern_matching_result_1, idStr, idStr_1, queries_1;
            if (!isEmpty(_arg1)) {
                if (head(_arg1) === "sparepart") {
                    if (!isEmpty(tail(_arg1))) {
                        if (!isEmpty(tail(tail(_arg1)))) {
                            const activePatternResult31368 = Route_$007CQuery$007C_$007C(head(tail(tail(_arg1))));
                            if (activePatternResult31368 != null) {
                                if (isEmpty(tail(tail(tail(_arg1))))) {
                                    pattern_matching_result_1 = 1;
                                    idStr_1 = head(tail(_arg1));
                                    queries_1 = activePatternResult31368;
                                }
                                else {
                                    pattern_matching_result_1 = 2;
                                }
                            }
                            else {
                                pattern_matching_result_1 = 2;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 0;
                            idStr = head(tail(_arg1));
                        }
                    }
                    else {
                        pattern_matching_result_1 = 2;
                    }
                }
                else {
                    pattern_matching_result_1 = 2;
                }
            }
            else {
                pattern_matching_result_1 = 2;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return RouteModule_ofSparePartId(SparePartIdModule_ofString(idStr));
                }
                case 1: {
                    const id_1 = SparePartIdModule_ofString(idStr_1);
                    const patternInput_1 = SearchFilter_fromQueries(SearchFilter_ofSparePartId(id_1), queries_1);
                    return new Route(2, id_1, patternInput_1[0], patternInput_1[1]);
                }
                case 2: {
                    let pattern_matching_result_2, idStr_2, idStr_3, queries_2;
                    if (!isEmpty(_arg1)) {
                        if (head(_arg1) === "saleslist") {
                            if (!isEmpty(tail(_arg1))) {
                                if (!isEmpty(tail(tail(_arg1)))) {
                                    const activePatternResult31367 = Route_$007CQuery$007C_$007C(head(tail(tail(_arg1))));
                                    if (activePatternResult31367 != null) {
                                        if (isEmpty(tail(tail(tail(_arg1))))) {
                                            pattern_matching_result_2 = 1;
                                            idStr_3 = head(tail(_arg1));
                                            queries_2 = activePatternResult31367;
                                        }
                                        else {
                                            pattern_matching_result_2 = 2;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_2 = 2;
                                    }
                                }
                                else {
                                    pattern_matching_result_2 = 0;
                                    idStr_2 = head(tail(_arg1));
                                }
                            }
                            else {
                                pattern_matching_result_2 = 2;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 2;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 2;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return RouteModule_ofSalesListId(SalesListIdModule_ofString(idStr_2));
                        }
                        case 1: {
                            const id_3 = SalesListIdModule_ofString(idStr_3);
                            const patternInput_2 = SearchFilter_fromQueries(SearchFilter_ofSalesListId(id_3), queries_2);
                            return new Route(3, id_3, patternInput_2[0], patternInput_2[1]);
                        }
                        case 2: {
                            let pattern_matching_result_3, idStr_4, idStr_5, queries_3;
                            if (!isEmpty(_arg1)) {
                                if (head(_arg1) === "product") {
                                    if (!isEmpty(tail(_arg1))) {
                                        if (!isEmpty(tail(tail(_arg1)))) {
                                            const activePatternResult31366 = Route_$007CQuery$007C_$007C(head(tail(tail(_arg1))));
                                            if (activePatternResult31366 != null) {
                                                if (isEmpty(tail(tail(tail(_arg1))))) {
                                                    pattern_matching_result_3 = 1;
                                                    idStr_5 = head(tail(_arg1));
                                                    queries_3 = activePatternResult31366;
                                                }
                                                else {
                                                    pattern_matching_result_3 = 2;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_3 = 2;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_3 = 0;
                                            idStr_4 = head(tail(_arg1));
                                        }
                                    }
                                    else {
                                        pattern_matching_result_3 = 2;
                                    }
                                }
                                else {
                                    pattern_matching_result_3 = 2;
                                }
                            }
                            else {
                                pattern_matching_result_3 = 2;
                            }
                            switch (pattern_matching_result_3) {
                                case 0: {
                                    return RouteModule_ofProductId(ProductIdModule_ofString(idStr_4));
                                }
                                case 1: {
                                    const id_5 = ProductIdModule_ofString(idStr_5);
                                    const patternInput_3 = SearchFilter_fromQueries(SearchFilter_ofProductId(id_5), queries_3);
                                    return new Route(4, id_5, patternInput_3[0], patternInput_3[1]);
                                }
                                case 2: {
                                    let pattern_matching_result_4, idStr_6, idStr_7, queries_4;
                                    if (!isEmpty(_arg1)) {
                                        if (head(_arg1) === "project") {
                                            if (!isEmpty(tail(_arg1))) {
                                                if (!isEmpty(tail(tail(_arg1)))) {
                                                    const activePatternResult31365 = Route_$007CQuery$007C_$007C(head(tail(tail(_arg1))));
                                                    if (activePatternResult31365 != null) {
                                                        if (isEmpty(tail(tail(tail(_arg1))))) {
                                                            pattern_matching_result_4 = 1;
                                                            idStr_7 = head(tail(_arg1));
                                                            queries_4 = activePatternResult31365;
                                                        }
                                                        else {
                                                            pattern_matching_result_4 = 2;
                                                        }
                                                    }
                                                    else {
                                                        pattern_matching_result_4 = 2;
                                                    }
                                                }
                                                else {
                                                    pattern_matching_result_4 = 0;
                                                    idStr_6 = head(tail(_arg1));
                                                }
                                            }
                                            else {
                                                pattern_matching_result_4 = 2;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_4 = 2;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_4 = 2;
                                    }
                                    switch (pattern_matching_result_4) {
                                        case 0: {
                                            return RouteModule_ofProjectId(ProjectIdModule_ofString(idStr_6));
                                        }
                                        case 1: {
                                            const id_7 = ProjectIdModule_ofString(idStr_7);
                                            const patternInput_4 = SearchFilter_fromQueries(SearchFilter_ofProjectId(id_7), queries_4);
                                            return new Route(5, id_7, patternInput_4[0], patternInput_4[1]);
                                        }
                                        case 2: {
                                            if (isEmpty(_arg1)) {
                                                return new Route(1);
                                            }
                                            else {
                                                return new Route(0);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

