import { Option as Option_3, columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { cons, collect, append, mapIndexed as mapIndexed_1, isEmpty, ofSeq, filter as filter_4, map, ofArray, singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Option, ISize, column as column_1 } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Common_GenericOption, Color_IColor, Text_span, Modifier_IModifier, Screen } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { createElement } from "react";
import * as react from "react";
import { defaultValue } from "../../../Fame/Core/String.fs.js";
import { Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, list_type, tuple_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { ValueModule_isEmpty, ValueModule_view, Value, Value$reflection } from "../../../Fame.Fts.Shared/Shared.UI/Fields.fs.js";
import { isNullOrEmpty, printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { Project_definition, Product_definition, SalesList_definition, SparePart_definition, P84_definition, Battery_definition } from "../Fields.fs.js";
import { singleton as singleton_1, append as append_1, delay, head as head_2, length, isEmpty as isEmpty_1, map as map_2, mapIndexed, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { map as map_1, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { SparePartIdModule_ofString } from "../../SparePartInfo/CommonTypes.fs.js";
import { equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { SearchFilter_ofSparePartId, SearchFilter_ofProjectId, SearchFilter_ofProductId, SearchFilter_ofSalesListId, SearchTypeModule_getCount, SearchTypeModule_translate, Route, SearchType, RouteModule_ofSparePartId, RouteModule_toLink } from "../Routing.fs.js";
import { h1, Option as Option_1, h2 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { TableOption, table } from "../fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { Option as Option_2, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { SearchResultModule_toSearchType, Msg, LoadedMsg, DetailMsg } from "../State.fs.js";
import { view as view_1 } from "../Components/PictureKey.fs.js";
import { Option as Option_4, image } from "../fable_modules/Fulma.2.16.0/Elements/Image.fs.js";
import { ExternalUrl } from "../Constants.fs.js";
import { section } from "../fable_modules/Fulma.2.16.0/Layouts/Section.fs.js";
import { container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { icon } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Tabs, Tab } from "../../../Fame.Fts.Shared/Shared.UI/Components/Tabs.fs.js";
import { view as view_2 } from "../Components/List.fs.js";
import { SearchFilterModule_withOrder } from "../Context.fs.js";

function row(left, right) {
    return columns(empty(), ofArray([column_1(singleton(new Option(0, new Screen(0), new ISize(1))), singleton(left)), column_1(empty(), singleton(right))]));
}

function textRow(left, right) {
    return row(react.createElement("span", {}, left), right);
}

function fieldRow(left, right) {
    let children;
    return textRow(left, (children = [defaultValue("-", right)], react.createElement("span", {}, ...children)));
}

class Group extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LineGroup", "TableGroup"];
    }
}

function Group$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.Pages.DetailPage.Group", [], Group, () => [[["Item1", string_type], ["Item2", list_type(tuple_type(string_type, Value$reflection()))]], [["Item1", string_type], ["Item2", list_type(string_type)], ["Item3", list_type(list_type(Value$reflection()))]]]);
}

function lineGroup(title, lines) {
    return new Group(0, title, lines);
}

function fieldsToLines(isInternal, def, entity, fields) {
    return map((f) => [def.GetName(f), def.GetValue(entity, f)], isInternal ? fields : filter_4(def.IsPublic, fields));
}

function getBatteryGroup(loc, isInternal, index, battery) {
    let arg20;
    return lineGroup((arg20 = ((index + 1) | 0), toText(printf("%s (%d)"))(loc.Translation.Group.Battery)(arg20)), fieldsToLines(isInternal, Battery_definition(loc), battery, ofArray(["iECCode", "bATTKZ", "battchargetype", "battmatcomp", "battdef1", "lIContent", "capperBatt", "bATTCELLS", "battweight", "manufacturer"])));
}

function getP84Group(loc, isInternal, p) {
    return lineGroup(loc.Translation.Group.P84, fieldsToLines(isInternal, P84_definition(loc), p, ofArray(["materialNo", "description", "salesText", "vmsta01", "vmsta31", "jpdmMmsta", "p84Mmsta", "orderableFrom", "deliverableFrom", "limitedAvailability", "eolControlDate"])));
}

function getSparePartData(loc, isInternal, sparePart) {
    const tr = loc.Translation;
    const batteryGroups = toList(mapIndexed((index, battery) => getBatteryGroup(loc, isInternal, index, battery), sparePart.Batteries));
    const p84Group = defaultArg(map_1((arg) => singleton(getP84Group(loc, isInternal, arg)), sparePart.P84), empty());
    const pekuniaGroup = defaultArg(map_1((p_1) => singleton(lineGroup(tr.Group.Pekunia, singleton([tr.Field.ModelNo, new Value(0, p_1)]))), sparePart.PekuniaModelNo), empty());
    let compaGroup;
    const _arg1 = ofSeq(sparePart.Compas);
    compaGroup = (isEmpty(_arg1) ? empty() : singleton(lineGroup(tr.Group.Compa, mapIndexed_1((i, c) => [(i !== 0) ? "" : tr.Field.PartNumber, new Value(0, c)], _arg1))));
    let jpdmGroups;
    const _arg2 = ofSeq(sparePart.Jpdms);
    jpdmGroups = (isEmpty(_arg2) ? empty() : singleton(new Group(1, tr.Group.Jpdm, ofArray([tr.Field.OrderPartNumber, tr.Field.Manufacturer, tr.Field.ModelNo, tr.Field.ProductNo]), map((jpdm) => ofArray([new Value(0, jpdm.OrderPartNumber), new Value(0, jpdm.Manufacturer), new Value(0, jpdm.ModelNo), new Value(0, jpdm.ProductNo)]), _arg2))));
    let chainGroup;
    const _arg3 = sparePart.Chain;
    if (_arg3 != null) {
        const toValue = (idStr) => {
            const sparePartId = SparePartIdModule_ofString(idStr);
            if (equals(sparePartId, sparePart.MaterialNo)) {
                return new Value(0, idStr);
            }
            else {
                return new Value(6, idStr, RouteModule_toLink(RouteModule_ofSparePartId(sparePartId)), (value_4) => {
                });
            }
        };
        chainGroup = singleton(new Group(1, tr.Group.B0Chain, ofArray([tr.Field.MaterialNoShort, tr.Field.B0MAT, tr.Field.SUBMAT, tr.Field.BISMAT]), toList(map_2((link) => ofArray([toValue(link.MaterialNo), toValue(link.B0MAT), toValue(link.SUBMAT), toValue(link.BISMAT)]), _arg3.Links))));
    }
    else {
        chainGroup = empty();
    }
    const sparePartLineGroup = (title_2, fields) => lineGroup(title_2, fieldsToLines(isInternal, SparePart_definition(loc), sparePart, fields));
    return [append(ofArray([sparePartLineGroup("", ofArray(["materialNo", "partNumber", "description", "descriptionDE", "division", "productHierarchy", "vendorPartNo", "productCode", "technicalInfo", "returnCode", "repairCode", "listPrice", "creditPrice", "lastCall", "repairOnly", "cRU", "centralLocal", "dataSecurity", "substituteMaterial", "alternativePart", "mainAlternativePart", "creationDate", "endOfService"])), sparePartLineGroup(isInternal ? tr.Group.Internal : tr.Group.Extended, ofArray(["endOfServiceMax", "criticalPart", "materialKind", "generalInfo", "precheckInfo", "inboundInfo", "returnInfo", "supplier", "repairer", "repairerPartNo", "notInStore", "moreInfo", "engineer", "planner", "mS80"])), sparePartLineGroup(tr.Group.ImportExport, ofArray(["commodityCode", "legalControlDE", "legalControlEN", "countryOfOrigin"])), sparePartLineGroup(tr.Group.Environment, ofArray(["roHSKz", "dangerousGoods", "hazardousMaterialNumber", "articleNumberBattery1"])), sparePartLineGroup(tr.Group.Packaging, ofArray(["externalPackaging", "internalPackaging", "eSDBag", "grossWeight", "netWeight", "volume", "dimensions", "serialNoHint"]))]), batteryGroups), append(p84Group, append(pekuniaGroup, append(compaGroup, append(jpdmGroups, chainGroup))))];
}

function getSalesListData(loc, isInternal, salesList) {
    return singleton(lineGroup("", fieldsToLines(isInternal, SalesList_definition(loc), salesList, ofArray(["materialNo", "partNumber", "description"]))));
}

function getProductData(loc, isInternal, product) {
    return singleton(lineGroup("", fieldsToLines(isInternal, Product_definition(loc), product, ofArray(["materialNo", "partNumber", "description", "descriptionDE", "productNo", "productGroupPlant", "productHierarchy", "cRU", "centralLocal", "endOfService", "endOfServiceMax", "mS80", "engineer", "eXTWG", "creationDate", "planningInformation"]))));
}

function getProjectData(loc, isInternal, project) {
    return singleton(lineGroup("", fieldsToLines(isInternal, Project_definition(loc), project, ofArray(["materialNo", "productNo", "partNumber", "productNo", "descriptionDE", "productGroupPlant", "productHierarchy", "endOfService", "endOfServiceMax", "engineer"]))));
}

function groupsToRows(localization, translation, showEmptyFields, groups) {
    const toHeadRow = (title) => row(h2(singleton(new Option_1(8, "group-title")))(singleton(title)), "");
    return collect((_arg1) => {
        let children_14;
        if (_arg1.tag === 1) {
            let thead;
            const ths = map((h) => react.createElement("th", {}, h), _arg1.fields[1]);
            const children_4 = [react.createElement("tr", {}, ...ths)];
            thead = react.createElement("thead", {}, ...children_4);
            let tbody;
            const children_11 = map((values) => {
                const children_8 = map((v) => {
                    const valueView = ValueModule_view(localization, translation)(v);
                    return react.createElement("td", {}, valueView);
                }, values);
                return react.createElement("tr", {}, ...children_8);
            }, _arg1.fields[2]);
            tbody = react.createElement("tbody", {}, ...children_11);
            return ofArray([toHeadRow(_arg1.fields[0]), columns(empty(), singleton(column_1(empty(), singleton((children_14 = [table(singleton(new TableOption(3)), ofArray([thead, tbody]))], react.createElement("div", {
                className: "table-container",
            }, ...children_14))))))]);
        }
        else {
            const title_1 = _arg1.fields[0];
            const lineRows = map((tupledArg_1) => textRow(tupledArg_1[0], ValueModule_view(localization, translation)(tupledArg_1[1])), filter_4((tupledArg) => {
                if (showEmptyFields) {
                    return true;
                }
                else {
                    return !ValueModule_isEmpty(tupledArg[1]);
                }
            }, _arg1.fields[1]));
            if (isEmpty(lineRows)) {
                return empty();
            }
            else if (isNullOrEmpty(title_1)) {
                return lineRows;
            }
            else {
                return cons(toHeadRow(title_1), lineRows);
            }
        }
    }, groups);
}

function detail(loc, isInternal, model, dispatchDetailMsg) {
    let children_3;
    const appendDocuments = (groups) => {
        let documents_2, input;
        const matchValue = [model.Documents, model.Item];
        if (matchValue[0] != null) {
            if (isEmpty_1(matchValue[0])) {
                const documents_1 = matchValue[0];
                return groups;
            }
            else {
                let pattern_matching_result;
                if (matchValue[0] != null) {
                    if (matchValue[1].tag === 0) {
                        if ((documents_2 = matchValue[0], (length(documents_2) === 1) && ((input = head_2(documents_2).ContentType, (input == null) ? false : (input.indexOf("image/") === 0))))) {
                            pattern_matching_result = 0;
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return groups;
                    }
                    case 1: {
                        if (matchValue[0] != null) {
                            const documents_4 = matchValue[0];
                            return append(groups, singleton(lineGroup(loc.Translation.Group.Documents, toList(mapIndexed((i, doc) => [(i !== 0) ? "" : loc.Translation.Documents(length(documents_4)), new Value(7, doc.Name, doc.Url)], documents_4)))));
                        }
                        else {
                            throw (new Error("Match failure"));
                        }
                    }
                }
            }
        }
        else {
            return groups;
        }
    };
    const appendShowEmptyRow = (groupRows) => append(groupRows, singleton(textRow("", button(ofArray([new Option_2(19, "show-empty-fields"), new Option_2(5), new Option_2(18, (_arg1) => {
        dispatchDetailMsg(new DetailMsg(0, !model.ShowEmptyFields));
    })]), singleton(model.ShowEmptyFields ? loc.Translation.HideEmpty : loc.Translation.ShowEmpty)))));
    const toContentRows = (groups_1) => groupsToRows(loc.BaseLocalization, loc.FieldResources, model.ShowEmptyFields, groups_1);
    const column = (contentRows) => columns(singleton(new Option_3(8, "detail")), singleton(column_1(empty(), contentRows)));
    const matchValue_1 = model.Item;
    switch (matchValue_1.tag) {
        case 1: {
            return column(appendShowEmptyRow(toContentRows(appendDocuments(getSalesListData(loc, isInternal, matchValue_1.fields[0])))));
        }
        case 2: {
            return column(appendShowEmptyRow(toContentRows(appendDocuments(getProductData(loc, isInternal, matchValue_1.fields[0])))));
        }
        case 3: {
            return column(appendShowEmptyRow(toContentRows(appendDocuments(getProjectData(loc, isInternal, matchValue_1.fields[0])))));
        }
        default: {
            const e = matchValue_1.fields[0];
            let picture;
            const mkContainer = (child) => {
                const children = [child, view_1(loc.Translation.PictureKey)];
                return react.createElement("div", {}, ...children);
            };
            const mkImage = (children_2) => image(ofArray([new Option_4(9), new Option_4(24, "box")]), children_2);
            const src = ExternalUrl.MaterialImage(e.MaterialNo);
            picture = ((!model.HasPicture) ? mkContainer(mkImage(empty())) : mkContainer((children_3 = singleton(mkImage(singleton(react.createElement("img", {
                src: src,
                alt: "Picture",
                onError: (_arg2) => {
                    dispatchDetailMsg(new DetailMsg(1));
                },
            })))), react.createElement("a", {
                href: src,
                target: "_blank",
            }, ...children_3))));
            const patternInput = getSparePartData(loc, isInternal, e);
            const referenceGroups = patternInput[1];
            const referenceRowsTablet = toContentRows(referenceGroups);
            const referenceRowsMobile = toContentRows(referenceGroups);
            const contentRows_1 = appendShowEmptyRow(append(toContentRows(appendDocuments(patternInput[0])), singleton(react.createElement("div", {
                className: "is-hidden-tablet",
            }, ...referenceRowsMobile))));
            return columns(singleton(new Option_3(8, "detail")), ofArray([column_1(singleton(new Option(2, "picture")), ofArray([picture, react.createElement("div", {
                className: "is-hidden-mobile",
            }, ...referenceRowsTablet)])), column_1(empty(), contentRows_1)]));
        }
    }
}

export function view(loc, isInternal, listMode, model, dispatch) {
    let exportButton, activeSearchType, ts, matchValue_1, sr, filterToRoute, emptyFilter, matchValue_2, navigateToFilter;
    let patternInput;
    const matchValue = model.Item;
    switch (matchValue.tag) {
        case 1: {
            const e_1 = matchValue.fields[0];
            patternInput = [loc.Translation.SalesList, e_1.Description, ofArray([new SearchType(0), new SearchType(2)]), (searchTypeOption_1) => ((filter_1) => (new Route(3, e_1.MaterialNo, searchTypeOption_1, filter_1)))];
            break;
        }
        case 2: {
            const e_2 = matchValue.fields[0];
            patternInput = [loc.Translation.Product, e_2.Description, ofArray([new SearchType(0), new SearchType(1), new SearchType(3)]), (searchTypeOption_2) => ((filter_2) => (new Route(4, e_2.MaterialNo, searchTypeOption_2, filter_2)))];
            break;
        }
        case 3: {
            const e_3 = matchValue.fields[0];
            patternInput = [loc.Translation.Project, e_3.Description, singleton(new SearchType(2)), (searchTypeOption_3) => ((filter_3) => (new Route(5, e_3.MaterialNo, searchTypeOption_3, filter_3)))];
            break;
        }
        default: {
            const e = matchValue.fields[0];
            patternInput = [loc.Translation.SparePart, e.Description, ofArray([new SearchType(1), new SearchType(2)]), (searchTypeOption) => ((filter) => (new Route(2, e.MaterialNo, searchTypeOption, filter)))];
        }
    }
    const getRoute = patternInput[3];
    return section(empty(), singleton(container(empty(), ofArray([(exportButton = button(toList(delay(() => append_1(singleton_1(new Option_2(20, singleton(new Modifier_IModifier(12)))), delay(() => append_1(singleton_1(new Option_2(17, singleton(new HTMLAttr(27, loc.Translation.ExportExcel)))), delay(() => (model.IsExporting ? singleton_1(new Option_2(13, true)) : singleton_1(new Option_2(18, (_arg1) => {
        dispatch(new Msg(4, new LoadedMsg(5, model.Id, new DetailMsg(3))));
    }))))))))), singleton(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-file-excel")), []))))), h1(empty())(ofArray([patternInput[0], ": ", Text_span(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(1, new Color_IColor(4))))), singleton(patternInput[1])), exportButton]))), (activeSearchType = map_1(SearchResultModule_toSearchType, model.SearchResult), (ts = map((searchTypeOption_4) => {
        let _arg1_2, st, title_1, count;
        return new Tab((_arg1_2 = searchTypeOption_4, (_arg1_2 != null) ? ((st = _arg1_2, (title_1 = SearchTypeModule_translate(loc.Translation, st), (count = (SearchTypeModule_getCount(model.SearchResultCounts, st) | 0), toText(printf("%s (%d)"))(title_1)(count))))) : loc.Translation.Details), equals(searchTypeOption_4, activeSearchType), (value) => {
        }, RouteModule_toLink(getRoute(searchTypeOption_4)(model.SearchFilter)));
    }, cons(void 0, map((arg0_2) => arg0_2, patternInput[2]))), createElement(Tabs, {
        ts: ts,
    }))), (matchValue_1 = model.SearchResult, (matchValue_1 != null) ? ((sr = matchValue_1, (filterToRoute = getRoute(SearchResultModule_toSearchType(sr)), (emptyFilter = ((matchValue_2 = model.Item, (matchValue_2.tag === 1) ? SearchFilter_ofSalesListId(matchValue_2.fields[0].MaterialNo) : ((matchValue_2.tag === 2) ? SearchFilter_ofProductId(matchValue_2.fields[0].MaterialNo) : ((matchValue_2.tag === 3) ? SearchFilter_ofProjectId(matchValue_2.fields[0].MaterialNo) : SearchFilter_ofSparePartId(matchValue_2.fields[0].MaterialNo))))), (navigateToFilter = ((arg_1) => {
        dispatch(new Msg(2, filterToRoute(arg_1)));
    }), view_2(loc, isInternal, listMode, model.SearchFilter, sr, filterToRoute, navigateToFilter, () => {
        navigateToFilter(emptyFilter);
    }, (arg_2) => {
        dispatch(new Msg(1, arg_2));
    }, (arg_3) => {
        let tupledArg;
        navigateToFilter((tupledArg = arg_3, SearchFilterModule_withOrder(model.SearchFilter, tupledArg[0], tupledArg[1])));
    })))))) : detail(loc, isInternal, model, (msg) => {
        dispatch(new Msg(4, new LoadedMsg(5, model.Id, msg)));
    }))]))));
}

