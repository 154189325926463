import { trigger } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Components/Dropdown.fs.js";
import { ofArray, singleton, empty } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/List.js";
import { button } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import * as react from "react";
import { Option, icon } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Size_ISize } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Common.fs.js";
import { Fa_IconOption, Fa_i } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";

export function triggerButton(title) {
    return trigger(empty(), singleton(button(empty(), ofArray([react.createElement("span", {}, title), icon(singleton(new Option(0, new Size_ISize(0))), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-angle-down")), [])))]))));
}

