import { Record, Union } from "./fable_modules/fable-library.3.7.12/Types.js";
import { record_type, tuple_type, option_type, list_type, string_type, bool_type, union_type, class_type, unit_type } from "./fable_modules/fable-library.3.7.12/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.12/Choice.js";
import { SparePartIdModule_toString, ProjectIdModule_toString, ProductIdModule_toString, SalesListIdModule_toString, Project$reflection, Product$reflection, SalesList$reflection, SparePart$reflection } from "../SparePartInfo/CommonTypes.fs.js";
import { SearchFilterModule_withQuery, SparePartSearchResult, SearchFilter, SparePartFilter as SparePartFilter_1, Order as Order_1, OrderModule_empty, SearchFilterModule_empty, ResultCounts$reflection, SearchFilter$reflection, Context$reflection, ProjectSearchResult$reflection, ProductSearchResult$reflection, SalesListSearchResult$reflection, SparePartSearchResult$reflection, OrderDirection$reflection, Document$$reflection } from "./Context.fs.js";
import { RouteModule_ofSparePartId, RouteModule_ofProjectId, RouteModule_ofProductId, RouteModule_ofSalesListId, Route, RouteModule_navigate, RouteModule_toLink, RouteModule_ofSegments, Route$reflection, SearchType } from "./Routing.fs.js";
import { Locale$reflection } from "../../Fame/Localization/Locale.fs.js";
import { SettingsModule_save, Settings, SettingsModule_addToSparePartHistory, SettingsModule_readSparePartHistory, Settings$reflection, ListMode$reflection } from "./Settings.fs.js";
import { getLocalization as getLocalization_1, Localization$reflection } from "./Localization.fs.js";
import { loadSimpleContext } from "./SimpleContext.fs.js";
import { RouterModule_urlSegments } from "./fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { tryFind, add, empty } from "./fable_modules/fable-library.3.7.12/Map.js";
import { Cmd_OfFunc_result, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "./fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";
import { map, isEmpty } from "./fable_modules/fable-library.3.7.12/List.js";
import { empty as empty_1, singleton, collect, delay, toList } from "./fable_modules/fable-library.3.7.12/Seq.js";
import { partialApply, equals } from "./fable_modules/fable-library.3.7.12/Util.js";
import { Cmd_map, Cmd_OfFunc_either, Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { map as map_1, defaultArg, some } from "./fable_modules/fable-library.3.7.12/Option.js";
import { toString } from "../../Fame/Core/Exception.fs.js";
import { AI_trackPageView } from "../../Import/Fable/Fable.ApplicationInsightsWeb.fs.js";
import { newGuid } from "./fable_modules/fable-library.3.7.12/Guid.js";

export class ListMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExportXLSX", "ExportXLSXDone"];
    }
}

export function ListMsg$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.ListMsg", [], ListMsg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", class_type("System.Exception")]]])]]]);
}

export class DetailPageId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DetailPageId"];
    }
}

export function DetailPageId$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.DetailPageId", [], DetailPageId, () => [[["Item", class_type("System.Guid")]]]);
}

export class DetailItem extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SparePart", "SalesList", "Product", "Project"];
    }
}

export function DetailItem$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.DetailItem", [], DetailItem, () => [[["Item", SparePart$reflection()]], [["Item", SalesList$reflection()]], [["Item", Product$reflection()]], [["Item", Project$reflection()]]]);
}

export function DetailItemModule_getStringId(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return SalesListIdModule_toString(_arg1.fields[0].MaterialNo);
        }
        case 2: {
            return ProductIdModule_toString(_arg1.fields[0].MaterialNo);
        }
        case 3: {
            return ProjectIdModule_toString(_arg1.fields[0].MaterialNo);
        }
        default: {
            return SparePartIdModule_toString(_arg1.fields[0].MaterialNo);
        }
    }
}

export class DetailMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ShowEmptyFields", "PictureLoadErrored", "DocumentsLoaded", "ExportXLSX", "ExportXLSXDone"];
    }
}

export function DetailMsg$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.DetailMsg", [], DetailMsg, () => [[["Item", bool_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Collections.Generic.IEnumerable`1", [Document$$reflection()]), class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", class_type("System.Collections.Generic.IEnumerable`1", [Document$$reflection()])]], [["ErrorValue", class_type("System.Exception")]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", class_type("System.Exception")]]])]]]);
}

export class StartMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SortBy"];
    }
}

export function StartMsg$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.StartMsg", [], StartMsg, () => [[["Item1", string_type], ["Item2", OrderDirection$reflection()]]]);
}

export class SearchResult extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SparePart", "SalesList", "Product", "Project"];
    }
}

export function SearchResult$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.SearchResult", [], SearchResult, () => [[["Item", SparePartSearchResult$reflection()]], [["Item", SalesListSearchResult$reflection()]], [["Item", ProductSearchResult$reflection()]], [["Item", ProjectSearchResult$reflection()]]]);
}

export function SearchResultModule_toSearchType(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return new SearchType(1);
        }
        case 2: {
            return new SearchType(2);
        }
        case 3: {
            return new SearchType(3);
        }
        default: {
            return new SearchType(0);
        }
    }
}

export class LoadedMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RouteChanged", "Search", "ShowDetail", "StartMsg", "ListMsg", "DetailMsg"];
    }
}

export function LoadedMsg$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.LoadedMsg", [], LoadedMsg, () => [[["Item", Route$reflection()]], [["Item", string_type]], [["Item", DetailItem$reflection()]], [["Item", StartMsg$reflection()]], [["Item", ListMsg$reflection()]], [["Item1", DetailPageId$reflection()], ["Item2", DetailMsg$reflection()]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeLocale", "ChangeListMode", "Navigate", "UrlChanged", "LoadedMsg", "ContextLoadFinished", "ContextLoadFailed", "SaveSettings"];
    }
}

export function Msg$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.Msg", [], Msg, () => [[["Item", Locale$reflection()]], [["Item", ListMode$reflection()]], [["Item", Route$reflection()]], [["Item", list_type(string_type)]], [["Item", LoadedMsg$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Context$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Context$reflection()]], [["ErrorValue", string_type]]])]], [["Item", class_type("System.Exception")]], [["Item", Settings$reflection()]]]);
}

export class StartPage extends Record {
    constructor(SparePartHistory, SparePartOrder) {
        super();
        this.SparePartHistory = SparePartHistory;
        this.SparePartOrder = SparePartOrder;
    }
}

export function StartPage$reflection() {
    return record_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.StartPage", [], StartPage, () => [["SparePartHistory", option_type(SearchResult$reflection())], ["SparePartOrder", option_type(tuple_type(string_type, OrderDirection$reflection()))]]);
}

export class ListPage extends Record {
    constructor(SearchFilter, SearchResult, SearchResultCounts, IsExporting) {
        super();
        this.SearchFilter = SearchFilter;
        this.SearchResult = SearchResult;
        this.SearchResultCounts = SearchResultCounts;
        this.IsExporting = IsExporting;
    }
}

export function ListPage$reflection() {
    return record_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.ListPage", [], ListPage, () => [["SearchFilter", SearchFilter$reflection()], ["SearchResult", SearchResult$reflection()], ["SearchResultCounts", ResultCounts$reflection()], ["IsExporting", bool_type]]);
}

export class DetailPage extends Record {
    constructor(Id, Item, Documents, ShowEmptyFields, HasPicture, IsExporting, SearchFilter, SearchResult, SearchResultCounts) {
        super();
        this.Id = Id;
        this.Item = Item;
        this.Documents = Documents;
        this.ShowEmptyFields = ShowEmptyFields;
        this.HasPicture = HasPicture;
        this.IsExporting = IsExporting;
        this.SearchFilter = SearchFilter;
        this.SearchResult = SearchResult;
        this.SearchResultCounts = SearchResultCounts;
    }
}

export function DetailPage$reflection() {
    return record_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.DetailPage", [], DetailPage, () => [["Id", DetailPageId$reflection()], ["Item", DetailItem$reflection()], ["Documents", option_type(class_type("System.Collections.Generic.IEnumerable`1", [Document$$reflection()]))], ["ShowEmptyFields", bool_type], ["HasPicture", bool_type], ["IsExporting", bool_type], ["SearchFilter", SearchFilter$reflection()], ["SearchResult", option_type(SearchResult$reflection())], ["SearchResultCounts", ResultCounts$reflection()]]);
}

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["StartPage", "ListPage", "DetailPage", "NotFound"];
    }
}

export function Page$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.Page", [], Page, () => [[["Item", StartPage$reflection()]], [["Item", ListPage$reflection()]], [["Item", DetailPage$reflection()]], []]);
}

export class Loaded extends Record {
    constructor(Context, Page, DocumentsCache) {
        super();
        this.Context = Context;
        this.Page = Page;
        this.DocumentsCache = DocumentsCache;
    }
}

export function Loaded$reflection() {
    return record_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.Loaded", [], Loaded, () => [["Context", Context$reflection()], ["Page", Page$reflection()], ["DocumentsCache", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("System.Collections.Generic.IEnumerable`1", [Document$$reflection()])])]]);
}

export class LoadStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Loading", "LoadError", "Loaded"];
    }
}

export function LoadStatus$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.LoadStatus", [], LoadStatus, () => [[["Item", string_type]], [["Item", string_type]], [["Item", Loaded$reflection()]]]);
}

export class Model extends Record {
    constructor(Route, Settings, AppInsights, Localization, LoadStatus) {
        super();
        this.Route = Route;
        this.Settings = Settings;
        this.AppInsights = AppInsights;
        this.Localization = Localization;
        this.LoadStatus = LoadStatus;
    }
}

export function Model$reflection() {
    return record_type("Fame.Fts.Toolbox.SparePartInfo.UI.State.Model", [], Model, () => [["Route", Route$reflection()], ["Settings", Settings$reflection()], ["AppInsights", class_type("ApplicationInsightsWeb.IApplicationInsights")], ["Localization", Localization$reflection()], ["LoadStatus", LoadStatus$reflection()]]);
}

export function loadContext() {
    return loadSimpleContext();
}

export function init(appInsights, settings) {
    return [new Model(RouteModule_ofSegments(RouterModule_urlSegments(window.location.hash, 1)), settings, appInsights, getLocalization_1(empty(), empty(), empty(), empty(), settings.Locale), new LoadStatus(0, "")), Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, loadContext, void 0, (arg0) => (new Msg(5, arg0)), (arg0_1) => (new Msg(6, arg0_1)))];
}

export function search(context, searchType, searchFilter) {
    switch (searchType.tag) {
        case 1: {
            const tupledArg_1 = context.SearchSalesLists(searchFilter);
            return [tupledArg_1[0], new SearchResult(1, tupledArg_1[1])];
        }
        case 2: {
            const tupledArg_2 = context.SearchProducts(searchFilter);
            return [tupledArg_2[0], new SearchResult(2, tupledArg_2[1])];
        }
        case 3: {
            const tupledArg_3 = context.SearchProjects(searchFilter);
            return [tupledArg_3[0], new SearchResult(3, tupledArg_3[1])];
        }
        default: {
            const tupledArg = context.SearchSpareParts(searchFilter);
            return [tupledArg[0], new SearchResult(0, tupledArg[1])];
        }
    }
}

export function loadSparePartHistory(context, sparePartOrder) {
    const _arg1 = SettingsModule_readSparePartHistory();
    if (isEmpty(_arg1)) {
        return void 0;
    }
    else {
        const sparePartIds = _arg1;
        const _arg2 = search(context, new SearchType(0), new SearchFilter(SearchFilterModule_empty.Query, SearchFilterModule_empty.Lookup, SearchFilterModule_empty.Page, new Order_1(sparePartOrder, OrderModule_empty.SalesListOrder, OrderModule_empty.ProductOrder, OrderModule_empty.ProjectOrder), SearchFilterModule_empty.PageSize, map((arg0) => (new SparePartFilter_1(0, arg0)), sparePartIds), SearchFilterModule_empty.ProductFilter, SearchFilterModule_empty.ProjectFilter))[1];
        if (_arg2.tag === 0) {
            const result = _arg2.fields[0];
            return new SearchResult(0, new SparePartSearchResult((sparePartOrder == null) ? toList(delay(() => collect((id) => collect((sp) => (equals(sp.MaterialNo, id) ? singleton(sp) : empty_1()), result.SpareParts), sparePartIds))) : result.SpareParts, result.FilteredCount, result.Divisions, result.Hierarchies, result.Planner, result.Engineer));
        }
        else {
            return void 0;
        }
    }
}

export function startUpdate(context, msg, _model) {
    const sparePartOrder = [msg.fields[0], msg.fields[1]];
    return [new StartPage(loadSparePartHistory(context, sparePartOrder), sparePartOrder), Cmd_none()];
}

export function detailUpdate(context, tr, documentsCache, msg, model) {
    if (msg.tag === 1) {
        return [new DetailPage(model.Id, model.Item, model.Documents, model.ShowEmptyFields, false, model.IsExporting, model.SearchFilter, model.SearchResult, model.SearchResultCounts), documentsCache, Cmd_none()];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 0) {
            return [new DetailPage(model.Id, model.Item, msg.fields[0].fields[0], model.ShowEmptyFields, model.HasPicture, model.IsExporting, model.SearchFilter, model.SearchResult, model.SearchResultCounts), add(DetailItemModule_getStringId(model.Item), msg.fields[0].fields[0], documentsCache), Cmd_none()];
        }
        else {
            console.error(some("document load failed"), msg.fields[0].fields[0]);
            return [model, documentsCache, Cmd_none()];
        }
    }
    else if (msg.tag === 3) {
        return [new DetailPage(model.Id, model.Item, model.Documents, model.ShowEmptyFields, model.HasPicture, true, model.SearchFilter, model.SearchResult, model.SearchResultCounts), documentsCache, Cmd_OfFunc_either(() => {
            context.ExportXLSX(tr, model.SearchFilter);
        }, void 0, () => (new DetailMsg(4, new FSharpResult$2(0, void 0))), (arg_1) => (new DetailMsg(4, new FSharpResult$2(1, arg_1))))];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            const s = toString(msg.fields[0].fields[0]);
            console.error(some(s));
        }
        return [new DetailPage(model.Id, model.Item, model.Documents, model.ShowEmptyFields, model.HasPicture, false, model.SearchFilter, model.SearchResult, model.SearchResultCounts), documentsCache, Cmd_none()];
    }
    else {
        return [new DetailPage(model.Id, model.Item, model.Documents, msg.fields[0], model.HasPicture, model.IsExporting, model.SearchFilter, model.SearchResult, model.SearchResultCounts), documentsCache, Cmd_none()];
    }
}

export function listUpdate(loc, context, msg, model) {
    if (msg.tag === 1) {
        const result = msg.fields[0];
        if (result.tag === 1) {
            const s = toString(result.fields[0]);
            console.error(some(s));
        }
        return [new ListPage(model.SearchFilter, model.SearchResult, model.SearchResultCounts, false), Cmd_none()];
    }
    else {
        return [new ListPage(model.SearchFilter, model.SearchResult, model.SearchResultCounts, true), Cmd_OfFunc_either(() => {
            context.ExportXLSX(loc.Translation, model.SearchFilter);
        }, void 0, () => (new ListMsg(1, new FSharpResult$2(0, void 0))), (arg_1) => (new ListMsg(1, new FSharpResult$2(1, arg_1))))];
    }
}

export function routeToPage(appInsights, context, documentsCache, route) {
    let option_1, f2_3, option_2, f2_4, option_3, f2_5, filter, patternInput_2;
    AI_trackPageView(appInsights, RouteModule_toLink(route));
    const toDetailPage = (activeTab, searchFilter, item) => {
        let matchValue, toDocumentMsg;
        const detailId = new DetailPageId(0, newGuid());
        let patternInput_1;
        if (activeTab != null) {
            const patternInput = search(context, activeTab, searchFilter);
            patternInput_1 = [patternInput[0], patternInput[1]];
        }
        else {
            patternInput_1 = [context.GetCounts(searchFilter), void 0];
        }
        return [new Page(2, new DetailPage(detailId, item, void 0, false, true, false, searchFilter, patternInput_1[1], patternInput_1[0])), (matchValue = [tryFind(DetailItemModule_getStringId(item), documentsCache), item], (matchValue[0] == null) ? ((matchValue[1].tag === 0) ? ((toDocumentMsg = ((arg) => (new LoadedMsg(5, detailId, new DetailMsg(2, arg)))), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, context.GetSparePartDocuments, matchValue[1].fields[0].MaterialNo, (arg_1) => toDocumentMsg(new FSharpResult$2(0, arg_1)), (arg_2) => toDocumentMsg(new FSharpResult$2(1, arg_2))))) : Cmd_none()) : Cmd_none())];
    };
    window.scroll(0, 0);
    return defaultArg((route.tag === 0) ? [new Page(3), Cmd_none()] : ((route.tag === 2) ? map_1((sparePart) => {
        SettingsModule_addToSparePartHistory(sparePart.MaterialNo);
        return toDetailPage(route.fields[1], route.fields[2], new DetailItem(0, sparePart));
    }, context.GetSparePart(route.fields[0])) : ((route.tag === 3) ? ((option_1 = context.GetSalesList(route.fields[0]), map_1((f2_3 = partialApply(1, toDetailPage, [route.fields[1], route.fields[2]]), (arg_4) => f2_3(new DetailItem(1, arg_4))), option_1))) : ((route.tag === 4) ? ((option_2 = context.GetProduct(route.fields[0]), map_1((f2_4 = partialApply(1, toDetailPage, [route.fields[1], route.fields[2]]), (arg_5) => f2_4(new DetailItem(2, arg_5))), option_2))) : ((route.tag === 5) ? ((option_3 = context.GetProject(route.fields[0]), map_1((f2_5 = partialApply(1, toDetailPage, [route.fields[1], route.fields[2]]), (arg_6) => f2_5(new DetailItem(3, arg_6))), option_3))) : ((route.tag === 6) ? ((filter = route.fields[1], (patternInput_2 = search(context, route.fields[0], filter), [new Page(1, new ListPage(filter, patternInput_2[1], patternInput_2[0], false)), Cmd_none()]))) : [new Page(0, new StartPage(loadSparePartHistory(context, void 0), void 0)), Cmd_none()]))))), [new Page(3), Cmd_none()]);
}

export function loadedUpdate(appInsights, loc, msg, model) {
    let matchValue_3;
    const withPage = (page) => (new Loaded(model.Context, page, model.DocumentsCache));
    switch (msg.tag) {
        case 3: {
            const matchValue = model.Page;
            if (matchValue.tag === 0) {
                const patternInput_1 = startUpdate(model.Context, msg.fields[0], matchValue.fields[0]);
                return [withPage(new Page(0, patternInput_1[0])), patternInput_1[1]];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 5: {
            const matchValue_1 = model.Page;
            let pattern_matching_result, page_5;
            if (matchValue_1.tag === 2) {
                if (equals(matchValue_1.fields[0].Id, msg.fields[0])) {
                    pattern_matching_result = 0;
                    page_5 = matchValue_1.fields[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    const patternInput_2 = detailUpdate(model.Context, loc.Translation, model.DocumentsCache, msg.fields[1], page_5);
                    return [new Loaded(model.Context, new Page(2, patternInput_2[0]), patternInput_2[1]), Cmd_map((m) => (new LoadedMsg(5, page_5.Id, m)), patternInput_2[2])];
                }
                case 1: {
                    return [model, Cmd_none()];
                }
            }
        }
        case 4: {
            const matchValue_2 = model.Page;
            if (matchValue_2.tag === 1) {
                const patternInput_3 = listUpdate(loc, model.Context, msg.fields[0], matchValue_2.fields[0]);
                return [withPage(new Page(1, patternInput_3[0])), Cmd_map((arg0) => (new LoadedMsg(4, arg0)), patternInput_3[1])];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 1: {
            return [model, RouteModule_navigate(new Route(6, (matchValue_3 = model.Page, (matchValue_3.tag === 1) ? SearchResultModule_toSearchType(matchValue_3.fields[0].SearchResult) : (new SearchType(0))), SearchFilterModule_withQuery(msg.fields[0])))];
        }
        case 2: {
            const item = msg.fields[0];
            return [model, RouteModule_navigate((item.tag === 1) ? RouteModule_ofSalesListId(item.fields[0].MaterialNo) : ((item.tag === 2) ? RouteModule_ofProductId(item.fields[0].MaterialNo) : ((item.tag === 3) ? RouteModule_ofProjectId(item.fields[0].MaterialNo) : RouteModule_ofSparePartId(item.fields[0].MaterialNo))))];
        }
        default: {
            const patternInput = routeToPage(appInsights, model.Context, model.DocumentsCache, msg.fields[0]);
            return [withPage(patternInput[0]), patternInput[1]];
        }
    }
}

export function update(msg, model) {
    const withLoadStatus = (loadStatus) => (new Model(model.Route, model.Settings, model.AppInsights, model.Localization, loadStatus));
    const getLocalization = (context, locale) => getLocalization_1(context.GetProductGroupPlants(), context.GetProductHierarchies(), context.GetDivisions(), context.GetSerialNoHints(), locale);
    const matchValue = [msg, model.LoadStatus];
    let pattern_matching_result, lmodel, locale_1, locale_2, listMode, settings_3, route, lmodel_1, segments, segments_1, lmodel_2, lmsg, context_1, errorMsg, ex;
    if (matchValue[0].tag === 1) {
        pattern_matching_result = 2;
        listMode = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 7) {
        pattern_matching_result = 3;
        settings_3 = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 2) {
        pattern_matching_result = 4;
        route = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 3) {
        if (matchValue[1].tag === 2) {
            pattern_matching_result = 5;
            lmodel_1 = matchValue[1].fields[0];
            segments = matchValue[0].fields[0];
        }
        else if (matchValue[1].tag === 0) {
            pattern_matching_result = 6;
            segments_1 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 4) {
        if (matchValue[1].tag === 2) {
            pattern_matching_result = 7;
            lmodel_2 = matchValue[1].fields[0];
            lmsg = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 5) {
        if (matchValue[0].fields[0].tag === 1) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 9;
                errorMsg = matchValue[0].fields[0].fields[0];
            }
            else {
                pattern_matching_result = 11;
            }
        }
        else if (matchValue[1].tag === 0) {
            pattern_matching_result = 8;
            context_1 = matchValue[0].fields[0].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 6) {
        if (matchValue[1].tag === 0) {
            pattern_matching_result = 10;
            ex = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[1].tag === 2) {
        pattern_matching_result = 0;
        lmodel = matchValue[1].fields[0];
        locale_1 = matchValue[0].fields[0];
    }
    else {
        pattern_matching_result = 1;
        locale_2 = matchValue[0].fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            let settings;
            const inputRecord = model.Settings;
            settings = (new Settings(locale_1, inputRecord.ListMode, inputRecord.TableFields));
            SettingsModule_save(settings);
            return [new Model(model.Route, settings, model.AppInsights, getLocalization(lmodel.Context, locale_1), model.LoadStatus), Cmd_none()];
        }
        case 1: {
            let settings_1;
            const inputRecord_1 = model.Settings;
            settings_1 = (new Settings(locale_2, inputRecord_1.ListMode, inputRecord_1.TableFields));
            SettingsModule_save(settings_1);
            return [new Model(model.Route, settings_1, model.AppInsights, model.Localization, model.LoadStatus), Cmd_none()];
        }
        case 2: {
            let settings_2;
            const inputRecord_2 = model.Settings;
            settings_2 = (new Settings(inputRecord_2.Locale, listMode, inputRecord_2.TableFields));
            SettingsModule_save(settings_2);
            return [new Model(model.Route, settings_2, model.AppInsights, model.Localization, model.LoadStatus), Cmd_none()];
        }
        case 3: {
            const cmd = equals(settings_3.Locale, model.Settings.Locale) ? Cmd_none() : Cmd_OfFunc_result(new Msg(0, settings_3.Locale));
            SettingsModule_save(settings_3);
            return [new Model(model.Route, settings_3, model.AppInsights, model.Localization, model.LoadStatus), cmd];
        }
        case 4: {
            return [model, RouteModule_navigate(route)];
        }
        case 5: {
            const route_1 = RouteModule_ofSegments(segments);
            const patternInput = loadedUpdate(model.AppInsights, model.Localization, new LoadedMsg(0, route_1), lmodel_1);
            return [new Model(route_1, model.Settings, model.AppInsights, model.Localization, new LoadStatus(2, patternInput[0])), Cmd_map((arg0) => (new Msg(4, arg0)), patternInput[1])];
        }
        case 6: {
            return [new Model(RouteModule_ofSegments(segments_1), model.Settings, model.AppInsights, model.Localization, model.LoadStatus), Cmd_none()];
        }
        case 7: {
            const patternInput_1 = loadedUpdate(model.AppInsights, model.Localization, lmsg, lmodel_2);
            return [withLoadStatus(new LoadStatus(2, patternInput_1[0])), Cmd_map((arg0_1) => (new Msg(4, arg0_1)), patternInput_1[1])];
        }
        case 8: {
            const documentsCache = empty();
            const patternInput_2 = routeToPage(model.AppInsights, context_1, documentsCache, model.Route);
            const nextModel_2 = withLoadStatus(new LoadStatus(2, new Loaded(context_1, patternInput_2[0], documentsCache)));
            return [new Model(nextModel_2.Route, nextModel_2.Settings, nextModel_2.AppInsights, getLocalization(context_1, model.Settings.Locale), nextModel_2.LoadStatus), Cmd_map((arg0_3) => (new Msg(4, arg0_3)), patternInput_2[1])];
        }
        case 9: {
            return [withLoadStatus(new LoadStatus(1, errorMsg)), Cmd_none()];
        }
        case 10: {
            return [withLoadStatus(new LoadStatus(1, toString(ex))), Cmd_none()];
        }
        case 11: {
            return [model, Cmd_none()];
        }
    }
}

