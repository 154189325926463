import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { empty, isEmpty } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Seq.js";
import { createObj } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Util.js";

export function AI_load(instrumentationKey) {
    let ai;
    let configM;
    let instrumentationKeyM = instrumentationKey;
    configM = (new (class {
        get instrumentationKey() {
            return instrumentationKeyM;
        }
        set instrumentationKey(v) {
            instrumentationKeyM = v;
        }
    }
    )());
    ai = (new ApplicationInsights(new (class {
        get config() {
            return configM;
        }
        set config(v_1) {
            configM = v_1;
        }
    }
    )()));
    const hostname = document.location.hostname;
    ai.loadAppInsights();
    ai.addTelemetryInitializer((telItem) => {
        telItem.tags["ai.cloud.role"]=hostname;
    });
    return ai;
}

function AI_createTrackPageViewParams(name, properties) {
    let nameM = name;
    let propertiesM = isEmpty(properties) ? null : createObj(properties);
    return new (class {
        get name() {
            return nameM;
        }
        set name(v) {
            nameM = v;
        }
        get properties() {
            return propertiesM;
        }
        set properties(v_1) {
            propertiesM = v_1;
        }
    }
    )();
}

export function AI_trackPageView(ai, name) {
    const arg00 = AI_createTrackPageViewParams(name, empty());
    ai.trackPageView(arg00);
}

export function AI_trackPageViewWithProps(ai, name, properties) {
    const arg00 = AI_createTrackPageViewParams(name, properties);
    ai.trackPageView(arg00);
}

