import { RouteModule_toLink } from "../Routing.fs.js";
import { Prop, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { list as list_1, Next_a, Previous_a, pagination as pagination_2, ellipsis, Link_Option, Link_a } from "../fable_modules/Fulma.2.16.0/Components/Pagination.fs.js";
import { numberHash, int32ToString } from "../fable_modules/fable-library.3.7.12/Util.js";
import { map, tryLast, tryHead, empty, contains, initialize, filter as filter_1, singleton, ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Common_GenericOption } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { view as view_1 } from "./Filters.fs.js";
import { Page as Page_2, SearchFilter, SearchFilterModule_empty } from "../Context.fs.js";
import { view as view_2 } from "./Table.fs.js";
import { view as view_3 } from "./Tiles.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Resources, Pagination } from "../../../Fame.Fts.Shared/Shared.UI/Components/Pagination.fs.js";
import { view as view_4 } from "./ListMode.fs.js";

function pagination(translation, pageToRoute, currentPage, maxPage) {
    const href = (arg_1) => (new HTMLAttr(94, RouteModule_toLink(pageToRoute(arg_1))));
    const link = (page) => Link_a(ofArray([new Link_Option(2, ofArray([href(page), new Prop(0, int32ToString(page))])), new Link_Option(0, page === currentPage)]), singleton(toText(printf("%d"))(page)));
    const surroundingCount = 2;
    const surrounding = filter_1((i_1) => {
        if (i_1 >= 1) {
            return i_1 <= maxPage;
        }
        else {
            return false;
        }
    }, initialize(surroundingCount + 1, (i) => ((i - (~(~(surroundingCount / 2)))) + currentPage)));
    let first;
    if (contains(1, surrounding, {
        Equals: (x, y) => (x === y),
        GetHashCode: numberHash,
    })) {
        first = empty();
    }
    else {
        const firstSurrounding = defaultArg(tryHead(surrounding), 1) | 0;
        first = toList(delay(() => append(singleton_1(link(1)), delay(() => ((firstSurrounding > 2) ? singleton_1(ellipsis(empty())) : empty_1())))));
    }
    let last;
    if (contains(maxPage, surrounding, {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: numberHash,
    })) {
        last = empty();
    }
    else {
        const lastSurrounding = defaultArg(tryLast(surrounding), maxPage) | 0;
        last = toList(delay(() => append((lastSurrounding < (maxPage - 1)) ? singleton_1(ellipsis(empty())) : empty_1(), delay(() => singleton_1(link(maxPage))))));
    }
    return pagination_2(empty(), ofArray([Previous_a(singleton(new Common_GenericOption(1, toList(delay(() => ((currentPage === 1) ? singleton_1(new HTMLAttr(79, true)) : singleton_1(href(currentPage - 1))))))), singleton(translation.Previous)), Next_a(singleton(new Common_GenericOption(1, toList(delay(() => ((currentPage === maxPage) ? singleton_1(new HTMLAttr(79, true)) : singleton_1(href(currentPage + 1))))))), singleton(translation.Next)), list_1(empty(), toList(delay(() => append(first, delay(() => append(map(link, surrounding), delay(() => last)))))))]));
}

export function view(loc, isInternal, settings, searchFilter, searchResult, filterToRoute, dispatchSearch, dispatchResetFilter, dispatchChangeListMode, dispatchSortBy) {
    const filter = view_1(loc, isInternal, dispatchResetFilter, (changeFilter) => {
        dispatchSearch(changeFilter(new SearchFilter(searchFilter.Query, searchFilter.Lookup, SearchFilterModule_empty.Page, searchFilter.Order, searchFilter.PageSize, searchFilter.SparePartFilter, searchFilter.ProductFilter, searchFilter.ProjectFilter)));
    }, searchFilter, searchResult);
    let content;
    const _arg1 = (settings.ListMode.tag === 0) ? view_2(loc, isInternal, settings.TableFields, dispatchSortBy, searchFilter.Order, searchResult) : view_3(loc, isInternal)(searchResult);
    if (_arg1 != null) {
        const listContent = _arg1;
        let patternInput;
        const getMaxPage = (count) => {
            let arg00;
            return ~(~((arg00 = (count / searchFilter.PageSize), Math.ceil(arg00))));
        };
        switch (searchResult.tag) {
            case 1: {
                patternInput = [searchFilter.Page.SalesListPage, getMaxPage(searchResult.fields[0].FilteredCount), (p_1) => {
                    const inputRecord_1 = searchFilter.Page;
                    return new Page_2(inputRecord_1.SparePartPage, p_1, inputRecord_1.ProductPage, inputRecord_1.ProjectPage);
                }];
                break;
            }
            case 2: {
                patternInput = [searchFilter.Page.ProductPage, getMaxPage(searchResult.fields[0].FilteredCount), (p_2) => {
                    const inputRecord_2 = searchFilter.Page;
                    return new Page_2(inputRecord_2.SparePartPage, inputRecord_2.SalesListPage, p_2, inputRecord_2.ProjectPage);
                }];
                break;
            }
            case 3: {
                patternInput = [searchFilter.Page.ProjectPage, getMaxPage(searchResult.fields[0].FilteredCount), (p_3) => {
                    const inputRecord_3 = searchFilter.Page;
                    return new Page_2(inputRecord_3.SparePartPage, inputRecord_3.SalesListPage, inputRecord_3.ProductPage, p_3);
                }];
                break;
            }
            default: {
                patternInput = [searchFilter.Page.SparePartPage, getMaxPage(searchResult.fields[0].FilteredCount), (p) => {
                    const inputRecord = searchFilter.Page;
                    return new Page_2(p, inputRecord.SalesListPage, inputRecord.ProductPage, inputRecord.ProjectPage);
                }];
            }
        }
        content = ofArray([listContent, createElement(Pagination, {
            res: new Resources(loc.Translation.Previous, loc.Translation.Next),
            pageToLink: (p_4) => RouteModule_toLink(filterToRoute(new SearchFilter(searchFilter.Query, searchFilter.Lookup, patternInput[2](p_4), searchFilter.Order, searchFilter.PageSize, searchFilter.SparePartFilter, searchFilter.ProductFilter, searchFilter.ProjectFilter))),
            onClickPage: (_page) => ((_ev) => {
            }),
            currentPage: patternInput[0],
            maxPage: patternInput[1],
        })]);
    }
    else {
        content = singleton(loc.Translation.NothingFound);
    }
    const children_2 = toList(delay(() => {
        let children;
        return append(singleton_1((children = [filter, view_4(settings.ListMode, dispatchChangeListMode)], react.createElement("div", {
            className: "list-head",
        }, ...children))), delay(() => content));
    }));
    return react.createElement("div", {
        className: "list",
    }, ...children_2);
}

