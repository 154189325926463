import { filter, toList, head, length } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { FieldDefinition$2$reflection, Value, ValueModule_view } from "../../../Fame.Fts.Shared/Shared.UI/Fields.fs.js";
import { Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { RouteModule_ofProjectId, RouteModule_ofProductId, RouteModule_ofSalesListId, RouteModule_ofSparePartId, RouteModule_toLink, Route$reflection } from "../Routing.fs.js";
import { record_type, class_type, option_type, tuple_type, lambda_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { OrderField, OrderDirection, OrderDirection$reflection, Order$reflection, OrderField$reflection } from "../Context.fs.js";
import { icon as icon_1 } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { isEmpty, ofArray, cons, tail, head as head_1, map, singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import * as react from "react";
import { equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { TableOption, table as table_1 } from "../fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { Project_definition, Product_definition, SalesList_definition, SparePart_definition } from "../Fields.fs.js";

function Value_view(loc, tr, _arg1) {
    let arg10;
    switch (_arg1.tag) {
        case 1: {
            const vs = _arg1.fields[0];
            const matchValue = length(vs) | 0;
            switch (matchValue) {
                case 0: {
                    return "-";
                }
                case 1: {
                    return head(vs);
                }
                default: {
                    return (arg10 = head(vs), toText(printf("%s ..."))(arg10));
                }
            }
        }
        case 4: {
            return ValueModule_view(loc, tr)(new Value(0, _arg1.fields[0]));
        }
        default: {
            return ValueModule_view(loc, tr)(_arg1);
        }
    }
}

class Table$2 extends Record {
    constructor(GetDetailRoute, FieldDefinition, ToOrderField, GetCurrentOrder, Cols) {
        super();
        this.GetDetailRoute = GetDetailRoute;
        this.FieldDefinition = FieldDefinition;
        this.ToOrderField = ToOrderField;
        this.GetCurrentOrder = GetCurrentOrder;
        this.Cols = Cols;
    }
}

function Table$2$reflection(gen0, gen1) {
    return record_type("Fame.Fts.Toolbox.SparePartInfo.UI.Components.Table.Table`2", [gen0, gen1], Table$2, () => [["GetDetailRoute", lambda_type(gen0, Route$reflection())], ["FieldDefinition", FieldDefinition$2$reflection(gen0, gen1)], ["ToOrderField", lambda_type(gen1, OrderField$reflection())], ["GetCurrentOrder", lambda_type(Order$reflection(), option_type(tuple_type(gen1, OrderDirection$reflection())))], ["Cols", class_type("System.Collections.Generic.IEnumerable`1", [gen1])]]);
}

function Table_th(dispatchSortBy, currentOrder, table, col) {
    let direction;
    let patternInput;
    let pattern_matching_result, direction_1, field_1;
    if (currentOrder != null) {
        if ((direction = currentOrder[1], equals(currentOrder[0], col))) {
            pattern_matching_result = 0;
            direction_1 = currentOrder[1];
            field_1 = currentOrder[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const createIcon = (i) => {
                const children = [" ", icon_1(empty(), singleton(Fa_i(singleton(i), [])))];
                return react.createElement("span", {}, ...children);
            };
            patternInput = ((direction_1.tag === 1) ? ["is-sorted is-desc", createIcon(new Fa_IconOption(11, "fas fa-arrow-down")), new OrderDirection(0)] : ["is-sorted is-asc", createIcon(new Fa_IconOption(11, "fas fa-arrow-up")), new OrderDirection(1)]);
            break;
        }
        case 1: {
            patternInput = ["", "", new OrderDirection(0)];
            break;
        }
    }
    const children_2 = [table.FieldDefinition.GetName(col), patternInput[1]];
    return react.createElement("th", {
        className: patternInput[0],
        onClick: (_arg1) => {
            dispatchSortBy([table.ToOrderField(col), patternInput[2]]);
        },
    }, ...children_2);
}

function Table_view(loc, isInternal, dispatchSortBy, order, table, items) {
    let children_14, children_10;
    const cols = toList(isInternal ? table.Cols : filter(table.FieldDefinition.IsPublic, table.Cols));
    const matchValue = [items, cols];
    let pattern_matching_result;
    if (isEmpty(matchValue[0])) {
        pattern_matching_result = 0;
    }
    else if (!isEmpty(matchValue[1])) {
        pattern_matching_result = 1;
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return void 0;
        }
        case 1: {
            const currentOrder = table.GetCurrentOrder(order);
            const ths = map((col) => Table_th(dispatchSortBy, currentOrder, table, col), cols);
            const rows = map((item) => {
                let firstTd;
                let el;
                const props_2 = [new HTMLAttr(94, RouteModule_toLink(table.GetDetailRoute(item)))];
                const children_2 = [Value_view(loc.BaseLocalization, loc.FieldResources, table.FieldDefinition.GetValue(item, head_1(matchValue[1])))];
                el = react.createElement("a", keyValueList(props_2, 1), ...children_2);
                firstTd = react.createElement("td", {}, el);
                const restTds = map((col_1) => {
                    const children = [Value_view(loc.BaseLocalization, loc.FieldResources, table.FieldDefinition.GetValue(item, col_1))];
                    return react.createElement("td", {}, ...children);
                }, tail(matchValue[1]));
                return react.createElement("tr", {}, ...cons(firstTd, restTds));
            }, matchValue[0]);
            return (children_14 = [table_1(ofArray([new TableOption(4), new TableOption(5, "is-sortable")]), ofArray([(children_10 = [react.createElement("tr", {}, ...ths)], react.createElement("thead", {}, ...children_10)), react.createElement("tbody", {}, ...rows)]))], react.createElement("div", {
                className: "table-container",
            }, ...children_14));
        }
    }
}

function getSparePartTable(loc, cols) {
    return new Table$2((s) => RouteModule_ofSparePartId(s.MaterialNo), SparePart_definition(loc), (arg0) => (new OrderField(0, arg0)), (o) => o.SparePartOrder, cols);
}

function getSalesListTable(loc, cols) {
    return new Table$2((s) => RouteModule_ofSalesListId(s.MaterialNo), SalesList_definition(loc), (arg0) => (new OrderField(1, arg0)), (o) => o.SalesListOrder, cols);
}

function getProductTable(loc, cols) {
    return new Table$2((s) => RouteModule_ofProductId(s.MaterialNo), Product_definition(loc), (arg0) => (new OrderField(2, arg0)), (o) => o.ProductOrder, cols);
}

function getProjectTable(loc, cols) {
    return new Table$2((s) => RouteModule_ofProjectId(s.MaterialNo), Project_definition(loc), (arg0) => (new OrderField(3, arg0)), (o) => o.ProjectOrder, cols);
}

export function view(loc, isInternal, tableFields, dispatchSortBy, order, _arg1) {
    switch (_arg1.tag) {
        case 1: {
            return Table_view(loc, isInternal, dispatchSortBy, order, getSalesListTable(loc, tableFields.SalesListFields), _arg1.fields[0].SalesLists);
        }
        case 2: {
            return Table_view(loc, isInternal, dispatchSortBy, order, getProductTable(loc, tableFields.ProductFields), _arg1.fields[0].Products);
        }
        case 3: {
            return Table_view(loc, isInternal, dispatchSortBy, order, getProjectTable(loc, tableFields.ProjectFields), _arg1.fields[0].Projects);
        }
        default: {
            return Table_view(loc, isInternal, dispatchSortBy, order, getSparePartTable(loc, tableFields.SparePartFields), _arg1.fields[0].SpareParts);
        }
    }
}

