import { view as view_1 } from "./Pages/ListPage.fs.js";
import { view as view_2 } from "./Pages/DetailPage.fs.js";
import { section } from "./fable_modules/Fulma.2.16.0/Layouts/Section.fs.js";
import { ofArray, singleton, empty } from "./fable_modules/fable-library.3.7.12/List.js";
import { Option as Option_1, container } from "./fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import * as react from "react";
import { view as view_3 } from "./Pages/StartPage.fs.js";
import { footer as footer_1 } from "./fable_modules/Fulma.2.16.0/Layouts/Footer.fs.js";
import { Text_p, Common_GenericOption, Color_IColor, Modifier_IModifier, TextAlignment_Option, Screen } from "./fable_modules/Fulma.2.16.0/Common.fs.js";
import { toLocalTime } from "./fable_modules/fable-library.3.7.12/DateOffset.js";
import { view as view_4 } from "./Components/Navbar.fs.js";
import { body, Option, hero } from "./fable_modules/Fulma.2.16.0/Layouts/Hero.fs.js";
import { Option as Option_2, p, h1 } from "./fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { RouterModule_router } from "./fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { Msg } from "./State.fs.js";

export function loadedView(settings, loc, route, model, dispatch) {
    const isInternal = model.Context.IsInternal;
    let page;
    const matchValue = model.Page;
    switch (matchValue.tag) {
        case 1: {
            page = view_1(loc, isInternal, settings, matchValue.fields[0], dispatch);
            break;
        }
        case 2: {
            page = view_2(loc, isInternal, settings, matchValue.fields[0], dispatch);
            break;
        }
        case 3: {
            page = section(empty(), singleton(container(empty(), singleton(react.createElement("p", {}, loc.Translation.PageNotFound)))));
            break;
        }
        default: {
            page = view_3(loc, isInternal, settings, matchValue.fields[0], dispatch);
        }
    }
    const footer = footer_1(singleton(new Common_GenericOption(2, ofArray([new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(0)), new Modifier_IModifier(1, new Color_IColor(14))]))), ofArray([Text_p(empty(), singleton(loc.Translation.DatabaseDate(toLocalTime(model.Context.PublishDate)))), Text_p(empty(), singleton(loc.Translation.AllRightsReserved))]));
    const children_4 = [view_4(loc.Translation, isInternal, settings, route, settings.Locale, dispatch), react.createElement("main", {}, page), footer];
    return react.createElement("div", {
        className: "main-container",
    }, ...children_4);
}

export function fullScreenHero(color, text, subText) {
    return hero(ofArray([new Option(5), new Option(6, color)]), singleton(body(empty(), singleton(container(ofArray([new Option_1(0), new Option_1(5, singleton(new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(0))))]), ofArray([h1(empty())(singleton(text)), p(singleton(new Option_2(6)), singleton(subText))]))))));
}

export function loadingView(loc, statusText) {
    return fullScreenHero(new Color_IColor(2), loc.Translation.Loading, statusText);
}

export function loadErrorView(loc, errorMsg) {
    return fullScreenHero(new Color_IColor(8), loc.Translation.LoadError, errorMsg);
}

export function mainView(model, dispatch) {
    const matchValue = model.LoadStatus;
    switch (matchValue.tag) {
        case 1: {
            return loadErrorView(model.Localization, matchValue.fields[0]);
        }
        case 2: {
            return loadedView(model.Settings, model.Localization, model.Route, matchValue.fields[0], dispatch);
        }
        default: {
            return loadingView(model.Localization, matchValue.fields[0]);
        }
    }
}

export function view(model, dispatch) {
    return RouterModule_router({
        application: mainView(model, dispatch),
        onUrlChanged: (arg) => {
            dispatch(new Msg(3, arg));
        },
    });
}

