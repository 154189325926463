import { Record } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Types.js";
import { record_type, option_type, lambda_type, unit_type, class_type, bool_type, string_type } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Reflection.js";
import { Tab_Option, tab, Option, tabs as tabs_1 } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Components/Tabs.fs.js";
import { Modifier_IModifier, Screen } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Common.fs.js";
import { empty as empty_1, tryFind, map, ofArray, singleton } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/List.js";
import { DOMAttr, HTMLAttr } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Seq.js";
import { value } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Option.js";
import * as react from "react";
import { keyValueList } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/MapUtil.js";
import { content, menu, Option as Option_1, dropdown as dropdown_1, Item_Option, Item_a } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Components/Dropdown.fs.js";
import { triggerButton } from "./Dropdown.fs.js";

export class Tab extends Record {
    constructor(Title, IsActive, OnClick, Href) {
        super();
        this.Title = Title;
        this.IsActive = IsActive;
        this.OnClick = OnClick;
        this.Href = Href;
    }
}

export function Tab$reflection() {
    return record_type("Fame.Fts.Shared.UI.Components.Tabs.Tab", [], Tab, () => [["Title", string_type], ["IsActive", bool_type], ["OnClick", lambda_type(class_type("Browser.Types.MouseEvent", void 0, MouseEvent), unit_type)], ["Href", option_type(string_type)]]);
}

export function tabs(ts) {
    return tabs_1(ofArray([new Option(9, singleton(new Modifier_IModifier(24, new Screen(3), true))), new Option(8, singleton(new HTMLAttr(135, "tabpanel")))]), map((t) => {
        let props;
        return tab(ofArray([new Tab_Option(0, t.IsActive), new Tab_Option(2, ofArray([new HTMLAttr(135, "tab"), new HTMLAttr(46, t.IsActive)]))]), singleton((props = toList(delay(() => append(singleton_1(new DOMAttr(40, t.OnClick)), delay(() => ((t.Href != null) ? singleton_1(new HTMLAttr(94, value(t.Href))) : empty()))))), react.createElement("a", keyValueList(props, 1), t.Title))));
    }, ts));
}

export function dropdown(ts) {
    let activeTitle;
    const _arg1 = tryFind((t) => t.IsActive, ts);
    activeTitle = ((_arg1 == null) ? "" : _arg1.Title);
    const items = map((t_2) => Item_a(ofArray([new Item_Option(0, t_2.IsActive), new Item_Option(1, toList(delay(() => append(singleton_1(new DOMAttr(40, t_2.OnClick)), delay(() => ((t_2.Href != null) ? singleton_1(new HTMLAttr(94, value(t_2.Href))) : empty()))))))]), singleton(t_2.Title)), ts);
    return dropdown_1(ofArray([new Option_1(1), new Option_1(6, singleton(new Modifier_IModifier(24, new Screen(2), true)))]), ofArray([triggerButton(activeTitle), menu(empty_1(), singleton(content(empty_1(), items)))]));
}

export function Tabs(tabsInputProps) {
    const ts = tabsInputProps.ts;
    const children = [dropdown(ts), tabs(ts)];
    return react.createElement("div", {
        className: "tab-select",
    }, ...children);
}

