import { Record } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Types.js";
import { class_type, record_type, lambda_type, option_type, obj_type, string_type } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Reflection.js";
import { singleton } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Array.js";
import { fold, chunkBySize, map } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Seq.js";
import { some } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Option.js";
import * as xlsx from "xlsx";

export class Col$1 extends Record {
    constructor(Title, GetValue) {
        super();
        this.Title = Title;
        this.GetValue = GetValue;
    }
}

export function Col$1$reflection(gen0) {
    return record_type("XLSX.Col`1", [gen0], Col$1, () => [["Title", string_type], ["GetValue", lambda_type(gen0, option_type(obj_type))]]);
}

export class SheetData$1 extends Record {
    constructor(Items, Cols) {
        super();
        this.Items = Items;
        this.Cols = Cols;
    }
}

export function SheetData$1$reflection(gen0) {
    return record_type("XLSX.SheetData`1", [gen0], SheetData$1, () => [["Items", class_type("System.Collections.Generic.IEnumerable`1", [gen0])], ["Cols", class_type("System.Collections.Generic.IEnumerable`1", [Col$1$reflection(gen0)])]]);
}

export function Worksheet_ofData(data) {
    let sheet;
    const headers = singleton(Array.from(map((col) => some(col.Title), data.Cols)));
    sheet = xlsx.utils.aoa_to_sheet(headers, null);
    const rowChunks = chunkBySize(1000, map((item) => Array.from(map((col_1) => col_1.GetValue(item), data.Cols)), data.Items));
    const appendOptions = {
        origin: -1,
    };
    return fold((sheet_1, chunk) => xlsx.utils.sheet_add_aoa(sheet_1, chunk, appendOptions), sheet, rowChunks);
}

