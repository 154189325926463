import { FunctionComponent_Of_Z5A158BBF } from "../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import * as react from "react";
import { Color_IColor, Size_ISize } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { input as input_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/./Input.fs.js";
import { Option, IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../fable_modules/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { empty, singleton, ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { Option as Option_1, button as button_1 } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Option as Option_2, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option as Option_3, p } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { StartMsg, Msg, LoadedMsg } from "../State.fs.js";
import { bind, map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { h2 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { Order, OrderModule_empty } from "../Context.fs.js";
import { view as view_1 } from "../Components/Table.fs.js";
import { view as view_2 } from "../Components/Tiles.fs.js";
import { Option as Option_4, section as section_1 } from "../fable_modules/Fulma.2.16.0/Layouts/Section.fs.js";
import { container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";

const searchFieldComponent = FunctionComponent_Of_Z5A158BBF((tupledArg) => {
    const dispatchSearch = tupledArg[1];
    const state = react.useState("");
    const size = new Size_ISize(2);
    const input = input_1(ofArray([new Option(1, new IInputType(0)), new Option(12, "..."), new Option(0, size), new Option(15, ofArray([new DOMAttr(15, (ev_1) => {
        if (ev_1.key !== "Enter") {
        }
        else {
            dispatchSearch(Browser_Types_Event__Event_get_Value(ev_1));
        }
    }), new DOMAttr(9, (ev_2) => {
        const arg00 = Browser_Types_Event__Event_get_Value(ev_2);
        state[1](arg00);
    })]))]));
    const button = button_1(ofArray([new Option_1(0, new Color_IColor(4)), new Option_1(1, size), new Option_1(18, (_arg1) => {
        dispatchSearch(state[0]);
    })]), singleton(tupledArg[0].Search));
    return div(ofArray([new Option_2(0), new Option_2(10, "searchfield")]), ofArray([p(singleton(new Option_3(3)), singleton(input)), p(empty(), singleton(button))]));
}, void 0, uncurry(2, void 0), void 0, "searchFieldComponent", "/home/vsts/work/1/s/src/Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/Pages/StartPage.fs", 16);

function searchField(translation, dispatchSearch) {
    return searchFieldComponent([translation, dispatchSearch]);
}

export function view(loc, isInternal, settings, model, dispatch) {
    const searchField_1 = searchField(loc.Translation, (arg_1) => {
        dispatch(new Msg(4, new LoadedMsg(1, arg_1)));
    });
    const history = defaultArg(map((listEl) => ofArray([h2(empty())(singleton(loc.Translation.LastViewedSpareParts)), listEl]), bind((searchResult) => {
        const order = new Order(model.SparePartOrder, OrderModule_empty.SalesListOrder, OrderModule_empty.ProductOrder, OrderModule_empty.ProjectOrder);
        if (settings.ListMode.tag === 0) {
            return view_1(loc, isInternal, settings.TableFields, (tupledArg) => {
                let tupledArg_1;
                const orderField = tupledArg[0];
                if (orderField.tag === 0) {
                    dispatch(new Msg(4, new LoadedMsg(3, (tupledArg_1 = [orderField.fields[0], tupledArg[1]], new StartMsg(0, tupledArg_1[0], tupledArg_1[1])))));
                }
            }, order, searchResult);
        }
        else {
            return view_2(loc, isInternal)(searchResult);
        }
    }, model.SparePartHistory)), empty());
    const section = (children) => section_1(singleton(new Option_4(1, "startpage")), singleton(container(empty(), children)));
    const children_1 = [section(singleton(searchField_1)), section(history)];
    return react.createElement("div", {}, ...children_1);
}

