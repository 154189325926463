import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, bool_type, union_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { append, tryHead, contains, filter as filter_1, mapIndexed as mapIndexed_1, map, singleton, empty, ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { TableFields, Settings, Settings$reflection } from "../Settings.fs.js";
import { columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option, ISize, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Common_GenericOption, Text_p, Color_IColor, Size_ISize, Screen } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { LocaleModule_toString } from "../../../Fame/Localization/Locale.fs.js";
import { menu, Option as Option_1, dropdown, Item_Option, Item_a, content as content_1 } from "../fable_modules/Fulma.2.16.0/Components/Dropdown.fs.js";
import { HTMLAttr, Prop, DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { supportedLocales } from "../Localization.fs.js";
import { triggerButton } from "../../../Fame.Fts.Shared/Shared.UI/Components/Dropdown.fs.js";
import { view as view_1 } from "./ListMode.fs.js";
import { createElement } from "react";
import * as react from "react";
import { singleton as singleton_1, append as append_1, delay, mapIndexed, map as map_1, sortBy, toList, filter } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { uncurry, structuralHash, partialApply, equals, comparePrimitives } from "../fable_modules/fable-library.3.7.12/Util.js";
import { Option as Option_2, button as button_1 } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { icon as icon_1 } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { Option as Option_3, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { FunctionComponent_Of_Z5A158BBF } from "../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { select } from "../fable_modules/Fulma.2.16.0/Elements/Form/Select.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../fable_modules/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { value as value_3, some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { content as content_2 } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { Tabs, Tab } from "../../../Fame.Fts.Shared/Shared.UI/Components/Tabs.fs.js";
import { Project_all, Project_translate, Project_isPublic, Product_all, Product_translate, Product_isPublic, SalesList_all, SalesList_translate, SalesList_isPublic, SparePart_all, SparePart_translate, SparePart_isPublic } from "../Fields.fs.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option as Option_4, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { Option as Option_5, delete$ } from "../fable_modules/Fulma.2.16.0/Elements/Delete.fs.js";

class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["General", "SparePartFields", "SalesListFields", "ProductFields", "ProjectFields"];
    }
}

function Page$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.Components.Settings.Page", [], Page, () => [[], [], [], [], []]);
}

const PageModule_all = ofArray([new Page(0), new Page(1), new Page(2), new Page(3), new Page(4)]);

function PageModule_translate(tr, _arg1) {
    switch (_arg1.tag) {
        case 1: {
            return tr.Settings.SparePartFields;
        }
        case 2: {
            return tr.Settings.SalesListFields;
        }
        case 3: {
            return tr.Settings.ProductFields;
        }
        case 4: {
            return tr.Settings.ProjectFields;
        }
        default: {
            return tr.Settings.General;
        }
    }
}

class SettingsState extends Record {
    constructor(IsActive, Page, Settings) {
        super();
        this.IsActive = IsActive;
        this.Page = Page;
        this.Settings = Settings;
    }
}

function SettingsState$reflection() {
    return record_type("Fame.Fts.Toolbox.SparePartInfo.UI.Components.Settings.SettingsState", [], SettingsState, () => [["IsActive", bool_type], ["Page", Page$reflection()], ["Settings", Settings$reflection()]]);
}

function colLine(title, content) {
    return columns(empty(), ofArray([column(singleton(new Option(0, new Screen(0), new ISize(1))), singleton(title)), column(empty(), singleton(content))]));
}

function generalSettings(tr, settings, updateSettings) {
    let localeToTitle, content;
    const children_1 = ofArray([colLine(tr.Language, (localeToTitle = ((arg) => {
        const input = LocaleModule_toString(arg);
        return input.toUpperCase();
    }), (content = content_1(empty(), map((locale_1) => Item_a(singleton(new Item_Option(1, singleton(new DOMAttr(40, (_arg1_1) => {
        updateSettings(new Settings(locale_1, settings.ListMode, settings.TableFields));
    })))), singleton(localeToTitle(locale_1))), supportedLocales)), dropdown(singleton(new Option_1(1)), ofArray([triggerButton(localeToTitle(settings.Locale)), menu(empty(), singleton(content))]))))), colLine(tr.ListMode, view_1(settings.ListMode, (l) => {
        updateSettings(new Settings(settings.Locale, l, settings.TableFields));
    }))]);
    return react.createElement("div", {}, ...children_1);
}

function fieldSettings(tr, isInternal, isPublic, translate, allFields, activeFields, update) {
    const filterInternal = (fields) => {
        if (isInternal) {
            return fields;
        }
        else {
            return filter(isPublic, fields);
        }
    };
    const allFields_1 = toList(sortBy((value) => value, filterInternal(allFields), {
        Compare: comparePrimitives,
    }));
    const activeFields_1 = toList(filterInternal(activeFields));
    const onClickDirection = (offset, field, _arg1) => {
        update(toList(map_1((tupledArg_1) => tupledArg_1[1], sortBy((tupledArg) => {
            const i_1 = tupledArg[0];
            if (!equals(tupledArg[1], field)) {
                return i_1;
            }
            else {
                return i_1 + offset;
            }
        }, mapIndexed((i, f) => [i, f], activeFields_1), {
            Compare: comparePrimitives,
        }))));
    };
    const onClickUp = partialApply(2, onClickDirection, [-1.5]);
    const onClickDown = partialApply(2, onClickDirection, [1.5]);
    const button = (color, onClick, icon) => button_1(ofArray([new Option_2(1, new Size_ISize(0)), new Option_2(0, color), new Option_2(18, onClick)]), singleton(icon_1(empty(), singleton(Fa_i(singleton(icon), [])))));
    const activeFieldLis = mapIndexed_1((i_2, field_3) => {
        let children, arg10_1, children_2;
        const props_4 = [new Prop(0, toText(printf("%A"))(field_3))];
        const children_4 = [(children = [(arg10_1 = ((i_2 + 1) | 0), toText(printf("%d"))(arg10_1))], react.createElement("span", {
            className: "position",
        }, ...children)), (children_2 = [translate(field_3)], react.createElement("span", {
            className: "name",
        }, ...children_2)), div(singleton(new Option_3(0)), ofArray([button(new Color_IColor(20), onClickUp(field_3), new Fa_IconOption(11, "fas fa-arrow-up")), button(new Color_IColor(20), onClickDown(field_3), new Fa_IconOption(11, "fas fa-arrow-down"))])), div(empty(), singleton(button(new Color_IColor(8), (_arg2) => {
            update(filter_1((y_2) => (!equals(field_3, y_2)), activeFields_1));
        }, new Fa_IconOption(11, "fas fa-times"))))];
        return react.createElement("li", keyValueList(props_4, 1), ...children_4);
    }, activeFields_1);
    const addableFields = filter_1((field_4) => (!contains(field_4, activeFields_1, {
        Equals: equals,
        GetHashCode: structuralHash,
    })), allFields_1);
    const addField = FunctionComponent_Of_Z5A158BBF((tupledArg_2) => {
        let children_12, props_10, children_14;
        const addableFields_1 = tupledArg_2[0];
        const selectState = react.useState(tryHead(addableFields_1));
        const options = map((field_5) => {
            const children_6 = [translate(field_5)];
            return react.createElement("option", {
                value: field_5,
            }, ...children_6);
        }, addableFields_1);
        const children_16 = [react.createElement("span", {
            className: "position",
        }), (children_12 = [select(empty(), singleton((props_10 = [new HTMLAttr(1, selectState[0]), new DOMAttr(9, (ev) => {
            const arg00 = some(Browser_Types_Event__Event_get_Value(ev));
            selectState[1](arg00);
        })], react.createElement("select", keyValueList(props_10, 1), ...options))))], react.createElement("span", {
            className: "name",
        }, ...children_12)), (children_14 = [button(new Color_IColor(6), (_arg3) => {
            const matchValue = selectState[0];
            if (matchValue != null) {
                tupledArg_2[1](value_3(matchValue));
            }
        }, new Fa_IconOption(11, "fas fa-plus"))], react.createElement("div", {}, ...children_14))];
        return react.createElement("li", {
            key: "new",
        }, ...children_16);
    }, void 0, uncurry(2, void 0), void 0, "fieldSettings", "/home/vsts/work/1/s/src/Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/Components/Settings.fs", 116)([addableFields, (field_2) => {
        update(append(activeFields_1, singleton(field_2)));
    }]);
    let fields_1;
    const children_18 = toList(delay(() => append_1(activeFieldLis, delay(() => singleton_1(addField)))));
    fields_1 = react.createElement("ul", {
        className: "fields",
    }, ...children_18);
    const children_20 = [content_2(empty(), singleton(Text_p(empty(), singleton(tr.Settings.SelectAndSort)))), fields_1];
    return react.createElement("div", {}, ...children_20);
}

const viewInternal = FunctionComponent_Of_Z5A158BBF((tupledArg) => {
    const tr = tupledArg[0];
    const isInternal = tupledArg[1];
    const initialState = new SettingsState(false, new Page(0), tupledArg[2]);
    const state = react.useState(initialState);
    const close = (_arg2) => {
        state[1](initialState);
    };
    const updateSettings = (settings_1) => {
        let inputRecord_2;
        state[1]((inputRecord_2 = (state[0]), new SettingsState(inputRecord_2.IsActive, inputRecord_2.Page, settings_1)));
    };
    let tabs;
    const ts = map((page_1) => (new Tab(PageModule_translate(tr, page_1), equals((state[0]).Page, page_1), (_arg3_1) => {
        let inputRecord_1;
        state[1]((inputRecord_1 = (state[0]), new SettingsState(inputRecord_1.IsActive, page_1, inputRecord_1.Settings)));
    }, void 0)), PageModule_all);
    tabs = createElement(Tabs, {
        ts: ts,
    });
    let content;
    const tableFields = (state[0]).Settings.TableFields;
    const updateTableFields = (tableFields_1) => {
        let inputRecord_3;
        updateSettings((inputRecord_3 = (state[0]).Settings, new Settings(inputRecord_3.Locale, inputRecord_3.ListMode, tableFields_1)));
    };
    const matchValue = (state[0]).Page;
    switch (matchValue.tag) {
        case 1: {
            content = fieldSettings(tr, isInternal, SparePart_isPublic, (_arg1_1) => SparePart_translate(tr, _arg1_1), SparePart_all, (state[0]).Settings.TableFields.SparePartFields, (activeFields) => {
                updateTableFields(new TableFields(activeFields, tableFields.SalesListFields, tableFields.ProductFields, tableFields.ProjectFields));
            });
            break;
        }
        case 2: {
            content = fieldSettings(tr, isInternal, SalesList_isPublic, (_arg1_2) => SalesList_translate(tr, _arg1_2), SalesList_all, (state[0]).Settings.TableFields.SalesListFields, (activeFields_1) => {
                updateTableFields(new TableFields(tableFields.SparePartFields, activeFields_1, tableFields.ProductFields, tableFields.ProjectFields));
            });
            break;
        }
        case 3: {
            content = fieldSettings(tr, isInternal, Product_isPublic, (_arg1_3) => Product_translate(tr, _arg1_3), Product_all, (state[0]).Settings.TableFields.ProductFields, (activeFields_2) => {
                updateTableFields(new TableFields(tableFields.SparePartFields, tableFields.SalesListFields, activeFields_2, tableFields.ProjectFields));
            });
            break;
        }
        case 4: {
            content = fieldSettings(tr, isInternal, Project_isPublic, (_arg1_4) => Project_translate(tr, _arg1_4), Project_all, (state[0]).Settings.TableFields.ProjectFields, (activeFields_3) => {
                updateTableFields(new TableFields(tableFields.SparePartFields, tableFields.SalesListFields, tableFields.ProductFields, activeFields_3));
            });
            break;
        }
        default: {
            content = generalSettings(tr, (state[0]).Settings, updateSettings);
        }
    }
    const children = [button_1(ofArray([new Option_2(18, (_arg1) => {
        let inputRecord;
        state[1]((inputRecord = (state[0]), new SettingsState(true, inputRecord.Page, inputRecord.Settings)));
    }), new Option_2(0, new Color_IColor(4))]), singleton(icon_1(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-cog")), []))))), modal(ofArray([new Option_4(1, (state[0]).IsActive), new Option_4(2, "settings")]), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, close)))), empty()), Card_card(empty(), ofArray([Card_head(empty(), ofArray([Card_title(empty(), singleton(tr.Settings.Settings)), delete$(singleton(new Option_5(3, close)), empty())])), Card_body(empty(), ofArray([tabs, content])), Card_foot(empty(), ofArray([button_1(ofArray([new Option_2(18, (_arg3) => {
        tupledArg[3]((state[0]).Settings);
    }), new Option_2(0, new Color_IColor(6))]), singleton(tr.Save)), button_1(singleton(new Option_2(18, close)), singleton(tr.Cancel))]))]))]))];
    return react.createElement("div", {}, ...children);
}, void 0, uncurry(2, void 0), (tupledArg_1) => toText(printf("%b;%A"))(tupledArg_1[1])(tupledArg_1[2]), "viewInternal", "/home/vsts/work/1/s/src/Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/Components/Settings.fs", 151);

export function view(tr, isInternal, settings, dispatchSaveSettings) {
    return viewInternal([tr, isInternal, settings, dispatchSaveSettings]);
}

