import { Union } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Types.js";
import { union_type } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Reflection.js";

export class Locale extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EnglishUS", "EnglishUK", "German", "French", "Russian", "Hungarian"];
    }
}

export function Locale$reflection() {
    return union_type("Fame.Localization.Locale", [], Locale, () => [[], [], [], [], [], []]);
}

export const LocaleModule_defaultEnglish = new Locale(1);

export function LocaleModule_tryParse(_arg1) {
    if (_arg1 === null) {
        return void 0;
    }
    else if (_arg1 === "en") {
        return LocaleModule_defaultEnglish;
    }
    else if (_arg1 === "en-US") {
        return new Locale(0);
    }
    else if (_arg1 === "en-GB") {
        return new Locale(1);
    }
    else if (_arg1.indexOf("de") === 0) {
        return new Locale(2);
    }
    else if (_arg1.indexOf("fr") === 0) {
        return new Locale(3);
    }
    else if (_arg1.indexOf("ru") === 0) {
        return new Locale(4);
    }
    else if (_arg1.indexOf("hu") === 0) {
        return new Locale(5);
    }
    else {
        return void 0;
    }
}

export function LocaleModule_toString(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return "en-GB";
        }
        case 2: {
            return "de";
        }
        case 3: {
            return "fr";
        }
        case 4: {
            return "ru";
        }
        case 5: {
            return "hu";
        }
        default: {
            return "en-US";
        }
    }
}

