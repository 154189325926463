import { Record } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Types.js";
import { record_type, string_type } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Reflection.js";
import { Prop, DOMAttr, HTMLAttr } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { list as list_1, Next_a, Previous_a, pagination, ellipsis, Link_Option, Link_a } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Components/Pagination.fs.js";
import { numberHash, int32ToString } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Util.js";
import { map, tryLast, tryHead, empty, contains, initialize, filter, singleton, ofArray } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/List.js";
import { printf, toText } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/String.js";
import { defaultArg } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Option.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Seq.js";
import { Common_GenericOption } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Common.fs.js";

export class Resources extends Record {
    constructor(Previous, Next) {
        super();
        this.Previous = Previous;
        this.Next = Next;
    }
}

export function Resources$reflection() {
    return record_type("Fame.Fts.Shared.UI.Components.Pagination.Resources", [], Resources, () => [["Previous", string_type], ["Next", string_type]]);
}

export function Pagination(paginationInputProps) {
    const maxPage = paginationInputProps.maxPage;
    const currentPage = paginationInputProps.currentPage;
    const onClickPage = paginationInputProps.onClickPage;
    const pageToLink = paginationInputProps.pageToLink;
    const res = paginationInputProps.res;
    const href = (arg) => (new HTMLAttr(94, pageToLink(arg)));
    const onClick = (arg_1) => (new DOMAttr(40, onClickPage(arg_1)));
    const link = (page) => Link_a(ofArray([new Link_Option(2, ofArray([href(page), onClick(page), new Prop(0, int32ToString(page))])), new Link_Option(0, page === currentPage)]), singleton(toText(printf("%d"))(page)));
    const surroundingCount = 2;
    const surrounding = filter((i_1) => {
        if (i_1 >= 1) {
            return i_1 <= maxPage;
        }
        else {
            return false;
        }
    }, initialize(surroundingCount + 1, (i) => ((i - (~(~(surroundingCount / 2)))) + currentPage)));
    let first;
    if (contains(1, surrounding, {
        Equals: (x, y) => (x === y),
        GetHashCode: numberHash,
    })) {
        first = empty();
    }
    else {
        const firstSurrounding = defaultArg(tryHead(surrounding), 1) | 0;
        first = toList(delay(() => append(singleton_1(link(1)), delay(() => ((firstSurrounding > 2) ? singleton_1(ellipsis(empty())) : empty_1())))));
    }
    let last;
    if (contains(maxPage, surrounding, {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: numberHash,
    })) {
        last = empty();
    }
    else {
        const lastSurrounding = defaultArg(tryLast(surrounding), maxPage) | 0;
        last = toList(delay(() => append((lastSurrounding < (maxPage - 1)) ? singleton_1(ellipsis(empty())) : empty_1(), delay(() => singleton_1(link(maxPage))))));
    }
    return pagination(empty(), ofArray([Previous_a(singleton(new Common_GenericOption(1, toList(delay(() => {
        if (currentPage === 1) {
            return singleton_1(new HTMLAttr(79, true));
        }
        else {
            const prevPage = (currentPage - 1) | 0;
            return append(singleton_1(href(prevPage)), delay(() => singleton_1(onClick(prevPage))));
        }
    })))), singleton(res.Previous)), Next_a(singleton(new Common_GenericOption(1, toList(delay(() => {
        if (currentPage === maxPage) {
            return singleton_1(new HTMLAttr(79, true));
        }
        else {
            const nextPage = (currentPage + 1) | 0;
            return append(singleton_1(href(nextPage)), delay(() => singleton_1(onClick(nextPage))));
        }
    })))), singleton(res.Next)), list_1(empty(), toList(delay(() => append(first, delay(() => append(map(link, surrounding), delay(() => last)))))))]));
}

