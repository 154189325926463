import { tryParse as tryParse_1 } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Int32.js";
import { FSharpRef } from "../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Types.js";

export function tryParse(input) {
    let _arg1;
    let outArg = 0;
    _arg1 = [tryParse_1(input, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (_arg1[0]) {
        return _arg1[1];
    }
    else {
        return void 0;
    }
}

