import { Item_a, Dropdown_div, Link_div, Item_Option, Item_div } from "../fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { ofArray, empty, singleton } from "../fable_modules/fable-library.3.7.12/List.js";
import { div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { input } from "../fable_modules/Fulma.2.16.0/Elements/Form/./Input.fs.js";
import { Option, IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { Route, RouteModule_toLink, RouteModule_tryGetQuery } from "../Routing.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../fable_modules/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { Msg, LoadedMsg } from "../State.fs.js";
import { HTMLAttr, DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { createElement } from "react";
import { LocaleSelect, Navbar } from "../../../Fame.Fts.Shared/Shared.UI/Components/Navbar.fs.js";
import fts_brand_logo from "../Assets/fts-brand-logo.png";
import { view as view_1 } from "./Settings.fs.js";
import { supportedLocales } from "../Localization.fs.js";
import { equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { Locale } from "../../../Fame/Localization/Locale.fs.js";
import { externalLinks } from "../Constants.fs.js";

export function view(translation, isInternal, settings, route, locale, dispatch) {
    let LogoImageSrc;
    const search = (route.tag === 1) ? null : Item_div(singleton(new Item_Option(6, "search")), singleton(div(empty(), singleton(input(ofArray([new Option(1, new IInputType(0)), new Option(10, defaultArg(RouteModule_tryGetQuery(route), "")), new Option(12, translation.Search), new Option(15, singleton(new DOMAttr(15, (ev_1) => {
        if (ev_1.key !== "Enter") {
        }
        else {
            dispatch(new Msg(4, new LoadedMsg(1, Browser_Types_Event__Event_get_Value(ev_1))));
        }
    })))]))))));
    return createElement(Navbar, (LogoImageSrc = fts_brand_logo, {
        EndElements: ofArray([Item_div(empty(), singleton(view_1(translation, isInternal, settings, (arg) => {
            dispatch(new Msg(7, arg));
        }))), Item_div(empty(), singleton(createElement(LocaleSelect, {
            All: supportedLocales,
            GetTitle: (l) => {
                if (equals(l, new Locale(2))) {
                    return "DE";
                }
                else {
                    return "EN";
                }
            },
            OnSelect: (arg_1) => {
                dispatch(new Msg(0, arg_1));
            },
            Selected: locale,
            Size: void 0,
        }))), Item_div(ofArray([new Item_Option(3), new Item_Option(2)]), ofArray([Link_div(empty(), singleton(translation.ExternalLinks.Title)), Dropdown_div(empty(), ofArray([Item_a(singleton(new Item_Option(5, ofArray([new HTMLAttr(94, externalLinks.PartnerPortal), new HTMLAttr(133, "noopener"), new HTMLAttr(157, "_blank")]))), singleton(translation.ExternalLinks.PartnerPortal)), Item_a(singleton(new Item_Option(5, ofArray([new HTMLAttr(94, externalLinks.MediaPortal), new HTMLAttr(133, "noopener"), new HTMLAttr(157, "_blank")]))), singleton(translation.ExternalLinks.MediaPortal)), Item_a(singleton(new Item_Option(5, ofArray([new HTMLAttr(94, externalLinks.IllustratedSpares), new HTMLAttr(133, "noopener"), new HTMLAttr(157, "_blank")]))), singleton(translation.ExternalLinks.IllustratedSpares))]))]))]),
        ExtraBrandElements: singleton(search),
        LogoImageSrc: LogoImageSrc,
        SiteTitle: "Fujitsu SPI",
        StartElements: empty(),
        StartHref: RouteModule_toLink(new Route(1)),
        StartOnClick: (value_2) => {
        },
    }));
}

