import { Color_IColor } from "./fable_modules/Fulma.2.16.0/Common.fs.js";
import { year, now, compare } from "./fable_modules/fable-library.3.7.12/Date.js";
import { EndOfLifeYearForEver } from "./Constants.fs.js";
import { value } from "./fable_modules/fable-library.3.7.12/Option.js";
import { uncurry } from "./fable_modules/fable-library.3.7.12/Util.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.12/String.js";

export const IsDarkGreen = new Color_IColor(19, "dark-green");

export function Eol_getColor(eol) {
    if (compare(eol, now()) < 0) {
        return new Color_IColor(8);
    }
    else {
        return IsDarkGreen;
    }
}

export function Eol_format(loc, eol) {
    if (year(eol) === EndOfLifeYearForEver) {
        return loc.Translation.Indefinite;
    }
    else {
        return loc.FormatDate(eol);
    }
}

export function CentralLocal_translate(tr, _arg1) {
    if (_arg1.tag === 1) {
        return tr.Local;
    }
    else {
        return tr.Central;
    }
}

export function CentralLocal_getColor(_arg1) {
    if (_arg1.tag === 1) {
        return new Color_IColor(8);
    }
    else {
        return IsDarkGreen;
    }
}

export function tryGetTitle(getter, format, name) {
    const matchValue = getter(name);
    if (matchValue == null) {
        return name;
    }
    else {
        return format(name, value(matchValue));
    }
}

export function getDivisionTitle(loc, name) {
    let clo1;
    return tryGetTitle(loc.TryGetProductDivision, uncurry(2, (clo1 = toText(printf("%s %s")), (arg10) => {
        const clo2 = clo1(arg10);
        return clo2;
    })), name);
}

export function getProductGroupPlantTitle(loc, name) {
    let clo1;
    return tryGetTitle(loc.TryGetProductGroupPlant, uncurry(2, (clo1 = toText(printf("%s - %s")), (arg10) => {
        const clo2 = clo1(arg10);
        return clo2;
    })), name);
}

export function getHierarchyTitle(loc, name) {
    let clo1;
    return tryGetTitle(loc.TryGetProductHierarchy, uncurry(2, (clo1 = toText(printf("%s %s")), (arg10) => {
        const clo2 = clo1(arg10);
        return clo2;
    })), name);
}

export function getSerialNoHint(loc, prefix) {
    let clo1;
    return tryGetTitle(loc.TryGetSerialNoHint, uncurry(2, (clo1 = toText(printf("%s: %s")), (arg10) => {
        const clo2 = clo1(arg10);
        return clo2;
    })), prefix);
}

