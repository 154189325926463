import { Union, Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, list_type, record_type, bool_type, lambda_type, option_type, int32_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { ProjectFilter as ProjectFilter_2, ProductFilter as ProductFilter_2, CentralLocal, SparePartFilter as SparePartFilter_2, SearchFilter, SearchFilter$reflection } from "../Context.fs.js";
import { sumBy, length, append as append_1, map as map_1, singleton as singleton_1, ofArray, choose, head, filter as filter_2, sortBy, empty, cons, contains, fold, isEmpty } from "../fable_modules/fable-library.3.7.12/List.js";
import { uncurry, equals, comparePrimitives, stringHash, partialApply } from "../fable_modules/fable-library.3.7.12/Util.js";
import { isNotNullOrEmpty } from "../../../Fame/Core/String.fs.js";
import { value as value_2, some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { getProductGroupPlantTitle, getHierarchyTitle, getDivisionTitle } from "../ViewHelper.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { content, menu, Option, dropdown, divider, Item_Option, Item_a } from "../fable_modules/Fulma.2.16.0/Components/Dropdown.fs.js";
import { HTMLAttr, Prop, DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { triggerButton } from "../../../Fame.Fts.Shared/Shared.UI/Components/Dropdown.fs.js";
import { heading, Option as Option_2, panel, Block_Option, Block_div } from "../fable_modules/Fulma.2.16.0/Components/Panel.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import * as react from "react";
import { Option as Option_1, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Color_IColor, Text_span, Common_GenericOption, Size_ISize } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option as Option_5, icon } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { Input_Option, input, radio } from "../fable_modules/Fulma.2.16.0/Elements/Form/Radio.fs.js";
import { input as input_1, checkbox } from "../fable_modules/Fulma.2.16.0/Elements/Form/Checkbox.fs.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option as Option_3, modal as modal_1 } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { Option as Option_4, delete$ } from "../fable_modules/Fulma.2.16.0/Elements/Delete.fs.js";

class FilterEntry extends Record {
    constructor(Name, Title, Count, Enable, Disable, IsDefault) {
        super();
        this.Name = Name;
        this.Title = Title;
        this.Count = Count;
        this.Enable = Enable;
        this.Disable = Disable;
        this.IsDefault = IsDefault;
    }
}

function FilterEntry$reflection() {
    return record_type("Fame.Fts.Toolbox.SparePartInfo.UI.Components.Filters.FilterEntry", [], FilterEntry, () => [["Name", string_type], ["Title", string_type], ["Count", option_type(int32_type)], ["Enable", lambda_type(SearchFilter$reflection(), SearchFilter$reflection())], ["Disable", lambda_type(SearchFilter$reflection(), SearchFilter$reflection())], ["IsDefault", bool_type]]);
}

class SingleSelectFilter extends Record {
    constructor(ActiveFilter, Rest) {
        super();
        this.ActiveFilter = ActiveFilter;
        this.Rest = Rest;
    }
}

function SingleSelectFilter$reflection() {
    return record_type("Fame.Fts.Toolbox.SparePartInfo.UI.Components.Filters.SingleSelectFilter", [], SingleSelectFilter, () => [["ActiveFilter", option_type(FilterEntry$reflection())], ["Rest", list_type(FilterEntry$reflection())]]);
}

class MultiSelectFilter extends Record {
    constructor(ActiveFilters, Rest) {
        super();
        this.ActiveFilters = ActiveFilters;
        this.Rest = Rest;
    }
}

function MultiSelectFilter$reflection() {
    return record_type("Fame.Fts.Toolbox.SparePartInfo.UI.Components.Filters.MultiSelectFilter", [], MultiSelectFilter, () => [["ActiveFilters", list_type(FilterEntry$reflection())], ["Rest", list_type(FilterEntry$reflection())]]);
}

class Filter extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SingleSelect", "MultiSelect"];
    }
}

function Filter$reflection() {
    return union_type("Fame.Fts.Toolbox.SparePartInfo.UI.Components.Filters.Filter", [], Filter, () => [[["Item", SingleSelectFilter$reflection()]], [["Item", MultiSelectFilter$reflection()]]]);
}

function FilterModule_isEmpty(_arg1) {
    if (_arg1.tag === 1) {
        const filter_1 = _arg1.fields[0];
        if (isEmpty(filter_1.ActiveFilters)) {
            return isEmpty(filter_1.Rest);
        }
        else {
            return false;
        }
    }
    else {
        const filter = _arg1.fields[0];
        if (filter.ActiveFilter == null) {
            return isEmpty(filter.Rest);
        }
        else {
            return false;
        }
    }
}

function FilterData_toMultiSelectFilter(active, items, getTitle, enable, disable) {
    let msFilter_1, sortAndFilter;
    return new Filter(1, (msFilter_1 = fold((msFilter, tupledArg) => {
        const name = tupledArg[0];
        const entry = new FilterEntry(name, getTitle(name), tupledArg[1], partialApply(1, enable, [name]), partialApply(1, disable, [name]), false);
        if (contains(name, active, {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        })) {
            return new MultiSelectFilter(cons(entry, msFilter.ActiveFilters), msFilter.Rest);
        }
        else {
            return new MultiSelectFilter(msFilter.ActiveFilters, cons(entry, msFilter.Rest));
        }
    }, new MultiSelectFilter(empty(), empty()), items), (sortAndFilter = ((arg) => sortBy((e_1) => e_1.Title, filter_2((e) => isNotNullOrEmpty(e.Title), arg), {
        Compare: comparePrimitives,
    })), new MultiSelectFilter(sortAndFilter(msFilter_1.ActiveFilters), sortAndFilter(msFilter_1.Rest)))));
}

function FilterData_toSingleSelectFilter(active, items, enable, disable) {
    const activeValue = (!isEmpty(active)) ? some(head(active)) : (void 0);
    const disableAll = fold((fn, value) => {
        const f2 = partialApply(1, disable, [value]);
        return (arg) => f2(fn(arg));
    }, (x) => x, choose((tuple) => tuple[1], items));
    return new Filter(0, fold((singleSelectFilter, tupledArg) => {
        let f2_1;
        const name = tupledArg[0];
        const value_1 = tupledArg[1];
        const patternInput = (value_1 == null) ? [true, disableAll, (x_1) => x_1] : [false, (f2_1 = partialApply(1, enable, [value_2(value_1)]), (arg_1) => f2_1(disableAll(arg_1))), disableAll];
        const entry = new FilterEntry(name, name, void 0, patternInput[1], patternInput[2], patternInput[0]);
        if (equals(value_1, activeValue)) {
            return new SingleSelectFilter(entry, singleSelectFilter.Rest);
        }
        else {
            return new SingleSelectFilter(singleSelectFilter.ActiveFilter, cons(entry, singleSelectFilter.Rest));
        }
    }, new SingleSelectFilter(void 0, empty()), items));
}

function FilterData_toBooleanSingleSelectFilter(translation, active, enable, disable) {
    return FilterData_toSingleSelectFilter(active, ofArray([[translation.All, void 0], [translation.Yes, true], [translation.No, false]]), enable, disable);
}

function FilterData_sparePartFilter(loc, isInternal, searchFilter, result) {
    const translation = loc.Translation;
    const enable = (toFilter, value, searchFilter_1) => (new SearchFilter(searchFilter_1.Query, searchFilter_1.Lookup, searchFilter_1.Page, searchFilter_1.Order, searchFilter_1.PageSize, cons(toFilter(value), searchFilter_1.SparePartFilter), searchFilter_1.ProductFilter, searchFilter_1.ProjectFilter));
    const disable = (toFilter_1, value_1, searchFilter_2) => {
        const filter_1 = toFilter_1(value_1);
        return new SearchFilter(searchFilter_2.Query, searchFilter_2.Lookup, searchFilter_2.Page, searchFilter_2.Order, searchFilter_2.PageSize, filter_2((y) => (!equals(filter_1, y)), searchFilter_2.SparePartFilter), searchFilter_2.ProductFilter, searchFilter_2.ProjectFilter);
    };
    const divisionFilter = [translation.Field.Division, FilterData_toMultiSelectFilter(choose((_arg1) => {
        if (_arg1.tag === 1) {
            return _arg1.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.SparePartFilter), result.Divisions, (name) => getDivisionTitle(loc, name), uncurry(2, partialApply(2, enable, [(arg0) => (new SparePartFilter_2(1, arg0))])), uncurry(2, partialApply(2, disable, [(arg0_1) => (new SparePartFilter_2(1, arg0_1))])))];
    const hierarchyFilter = [translation.Field.Hierarchy, FilterData_toMultiSelectFilter(choose((_arg2) => {
        if (_arg2.tag === 2) {
            return _arg2.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.SparePartFilter), result.Hierarchies, (name_1) => getHierarchyTitle(loc, name_1), uncurry(2, partialApply(2, enable, [(arg0_2) => (new SparePartFilter_2(2, arg0_2))])), uncurry(2, partialApply(2, disable, [(arg0_3) => (new SparePartFilter_2(2, arg0_3))])))];
    const engineerFilter = [translation.Field.Engineer, FilterData_toMultiSelectFilter(choose((_arg3) => {
        if (_arg3.tag === 3) {
            return _arg3.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.SparePartFilter), result.Engineer, (x) => x, uncurry(2, partialApply(2, enable, [(arg0_4) => (new SparePartFilter_2(3, arg0_4))])), uncurry(2, partialApply(2, disable, [(arg0_5) => (new SparePartFilter_2(3, arg0_5))])))];
    const plannerFilter = [translation.Field.Planner, FilterData_toMultiSelectFilter(choose((_arg4) => {
        if (_arg4.tag === 4) {
            return _arg4.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.SparePartFilter), result.Planner, (x_1) => x_1, uncurry(2, partialApply(2, enable, [(arg0_6) => (new SparePartFilter_2(4, arg0_6))])), uncurry(2, partialApply(2, disable, [(arg0_7) => (new SparePartFilter_2(4, arg0_7))])))];
    const criticalPartFilter = [translation.Field.CriticalPart, FilterData_toSingleSelectFilter(choose((_arg5) => {
        if (_arg5.tag === 5) {
            return some(_arg5.fields[0]);
        }
        else {
            return void 0;
        }
    }, searchFilter.SparePartFilter), ofArray([[translation.All, void 0], [translation.Na, some(void 0)], [translation.Yes, some(true)], [translation.No, some(false)]]), uncurry(2, partialApply(2, enable, [(arg0_8) => (new SparePartFilter_2(5, arg0_8))])), uncurry(2, partialApply(2, disable, [(arg0_9) => (new SparePartFilter_2(5, arg0_9))])))];
    const endOfLifeFilter = [translation.Field.EndOfService, FilterData_toBooleanSingleSelectFilter(translation, choose((_arg6) => {
        if (_arg6.tag === 6) {
            return _arg6.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.SparePartFilter), uncurry(2, partialApply(2, enable, [(arg0_10) => (new SparePartFilter_2(6, arg0_10))])), uncurry(2, partialApply(2, disable, [(arg0_11) => (new SparePartFilter_2(6, arg0_11))])))];
    const centralLocalFilter = [translation.Field.CentralLocal, FilterData_toSingleSelectFilter(choose((_arg7) => {
        if (_arg7.tag === 7) {
            return _arg7.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.SparePartFilter), ofArray([[translation.All, void 0], [translation.Central, new CentralLocal(0)], [translation.Local, new CentralLocal(1)]]), uncurry(2, partialApply(2, enable, [(arg0_12) => (new SparePartFilter_2(7, arg0_12))])), uncurry(2, partialApply(2, disable, [(arg0_13) => (new SparePartFilter_2(7, arg0_13))])))];
    return toList(delay(() => append(singleton(divisionFilter), delay(() => append(singleton(hierarchyFilter), delay(() => append(singleton(engineerFilter), delay(() => append(singleton(plannerFilter), delay(() => append(singleton(criticalPartFilter), delay(() => append(singleton(endOfLifeFilter), delay(() => (isInternal ? singleton(centralLocalFilter) : empty_1())))))))))))))));
}

function FilterData_productFilter(loc, isInternal, searchFilter, result) {
    const translation = loc.Translation;
    const enable = (toFilter, value, searchFilter_1) => (new SearchFilter(searchFilter_1.Query, searchFilter_1.Lookup, searchFilter_1.Page, searchFilter_1.Order, searchFilter_1.PageSize, searchFilter_1.SparePartFilter, cons(toFilter(value), searchFilter_1.ProductFilter), searchFilter_1.ProjectFilter));
    const disable = (toFilter_1, value_1, searchFilter_2) => {
        const filter_1 = toFilter_1(value_1);
        return new SearchFilter(searchFilter_2.Query, searchFilter_2.Lookup, searchFilter_2.Page, searchFilter_2.Order, searchFilter_2.PageSize, searchFilter_2.SparePartFilter, filter_2((f) => (!equals(f, filter_1)), searchFilter_2.ProductFilter), searchFilter_2.ProjectFilter);
    };
    const hierarchyFilter = [translation.Field.Hierarchy, FilterData_toMultiSelectFilter(choose((_arg1) => {
        if (_arg1.tag === 0) {
            return _arg1.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.ProductFilter), result.Hierarchies, (name) => getHierarchyTitle(loc, name), uncurry(2, partialApply(2, enable, [(arg0) => (new ProductFilter_2(0, arg0))])), uncurry(2, partialApply(2, disable, [(arg0_1) => (new ProductFilter_2(0, arg0_1))])))];
    const productGroupPlantFilter = [translation.Field.ProductGroupPlant, FilterData_toMultiSelectFilter(choose((_arg2) => {
        if (_arg2.tag === 1) {
            return _arg2.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.ProductFilter), result.ProductGroupPlants, (name_1) => getProductGroupPlantTitle(loc, name_1), uncurry(2, partialApply(2, enable, [(arg0_2) => (new ProductFilter_2(1, arg0_2))])), uncurry(2, partialApply(2, disable, [(arg0_3) => (new ProductFilter_2(1, arg0_3))])))];
    const engineerFilter = [translation.Field.Engineer, FilterData_toMultiSelectFilter(choose((_arg3) => {
        if (_arg3.tag === 2) {
            return _arg3.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.ProductFilter), result.Engineer, (x) => x, uncurry(2, partialApply(2, enable, [(arg0_4) => (new ProductFilter_2(2, arg0_4))])), uncurry(2, partialApply(2, disable, [(arg0_5) => (new ProductFilter_2(2, arg0_5))])))];
    const endOfLifeFilter = [translation.Field.EndOfService, FilterData_toBooleanSingleSelectFilter(translation, choose((_arg4) => {
        if (_arg4.tag === 3) {
            return _arg4.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.ProductFilter), uncurry(2, partialApply(2, enable, [(arg0_6) => (new ProductFilter_2(3, arg0_6))])), uncurry(2, partialApply(2, disable, [(arg0_7) => (new ProductFilter_2(3, arg0_7))])))];
    const centralLocalFilter = [translation.Field.CentralLocal, FilterData_toSingleSelectFilter(choose((_arg5) => {
        if (_arg5.tag === 4) {
            return _arg5.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.ProductFilter), ofArray([[translation.All, void 0], [translation.Central, new CentralLocal(0)], [translation.Local, new CentralLocal(1)]]), uncurry(2, partialApply(2, enable, [(arg0_8) => (new ProductFilter_2(4, arg0_8))])), uncurry(2, partialApply(2, disable, [(arg0_9) => (new ProductFilter_2(4, arg0_9))])))];
    return toList(delay(() => append(singleton(hierarchyFilter), delay(() => append(singleton(productGroupPlantFilter), delay(() => append(singleton(engineerFilter), delay(() => append(singleton(endOfLifeFilter), delay(() => (isInternal ? singleton(centralLocalFilter) : empty_1())))))))))));
}

function FilterData_projectFilter(loc, searchFilter, result) {
    const translation = loc.Translation;
    const enable = (toFilter, value, searchFilter_1) => (new SearchFilter(searchFilter_1.Query, searchFilter_1.Lookup, searchFilter_1.Page, searchFilter_1.Order, searchFilter_1.PageSize, searchFilter_1.SparePartFilter, searchFilter_1.ProductFilter, cons(toFilter(value), searchFilter_1.ProjectFilter)));
    const disable = (toFilter_1, value_1, searchFilter_2) => {
        const filter_1 = toFilter_1(value_1);
        return new SearchFilter(searchFilter_2.Query, searchFilter_2.Lookup, searchFilter_2.Page, searchFilter_2.Order, searchFilter_2.PageSize, searchFilter_2.SparePartFilter, searchFilter_2.ProductFilter, filter_2((f) => (!equals(f, filter_1)), searchFilter_2.ProjectFilter));
    };
    return ofArray([[translation.Field.Hierarchy, FilterData_toMultiSelectFilter(choose((_arg1) => {
        if (_arg1.tag === 0) {
            return _arg1.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.ProjectFilter), result.Hierarchies, (name) => getHierarchyTitle(loc, name), uncurry(2, partialApply(2, enable, [(arg0) => (new ProjectFilter_2(0, arg0))])), uncurry(2, partialApply(2, disable, [(arg0_1) => (new ProjectFilter_2(0, arg0_1))])))], [translation.Field.ProductGroupPlant, FilterData_toMultiSelectFilter(choose((_arg2) => {
        if (_arg2.tag === 1) {
            return _arg2.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.ProjectFilter), result.ProductGroupPlants, (name_1) => getProductGroupPlantTitle(loc, name_1), uncurry(2, partialApply(2, enable, [(arg0_2) => (new ProjectFilter_2(1, arg0_2))])), uncurry(2, partialApply(2, disable, [(arg0_3) => (new ProjectFilter_2(1, arg0_3))])))], [translation.Field.Engineer, FilterData_toMultiSelectFilter(choose((_arg3) => {
        if (_arg3.tag === 2) {
            return _arg3.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.ProjectFilter), result.Engineer, (x) => x, uncurry(2, partialApply(2, enable, [(arg0_4) => (new ProjectFilter_2(2, arg0_4))])), uncurry(2, partialApply(2, disable, [(arg0_5) => (new ProjectFilter_2(2, arg0_5))])))], [translation.Field.EndOfService, FilterData_toBooleanSingleSelectFilter(translation, choose((_arg4) => {
        if (_arg4.tag === 3) {
            return _arg4.fields[0];
        }
        else {
            return void 0;
        }
    }, searchFilter.ProjectFilter), uncurry(2, partialApply(2, enable, [(arg0_6) => (new ProjectFilter_2(3, arg0_6))])), uncurry(2, partialApply(2, disable, [(arg0_7) => (new ProjectFilter_2(3, arg0_7))])))]]);
}

function FilterData_ofSearchResult(loc, isInternal, searchFilter) {
    return (arg) => {
        let _arg1;
        return filter_2((tupledArg) => (!FilterModule_isEmpty(tupledArg[1])), (_arg1 = arg, (_arg1.tag === 1) ? empty() : ((_arg1.tag === 2) ? FilterData_productFilter(loc, isInternal, searchFilter, _arg1.fields[0]) : ((_arg1.tag === 3) ? FilterData_projectFilter(loc, searchFilter, _arg1.fields[0]) : FilterData_sparePartFilter(loc, isInternal, searchFilter, _arg1.fields[0])))));
    };
}

function View_createDropdownItem(getEntryContent, getOnClick, isActive, entry) {
    return Item_a(singleton_1(new Item_Option(1, ofArray([new DOMAttr(40, partialApply(1, getOnClick, [isActive, entry])), new Prop(0, entry.Name)]))), getEntryContent(isActive, entry));
}

function View_createDropdown(createItem, title, active, rest) {
    let items;
    const active_1 = map_1(partialApply(1, createItem, [true]), active);
    const rest_1 = map_1(partialApply(1, createItem, [false]), rest);
    items = (isEmpty(active_1) ? rest_1 : append_1(active_1, append_1(singleton_1(divider(empty())), rest_1)));
    return dropdown(singleton_1(new Option(1)), ofArray([triggerButton(title), menu(empty(), singleton_1(content(empty(), items)))]));
}

function View_createPanelItem(getEntryContent, getOnClick, isActive, entry) {
    return Block_div(singleton_1(new Block_Option(1, ofArray([new DOMAttr(40, partialApply(1, getOnClick, [isActive, entry])), new Prop(0, entry.Name)]))), getEntryContent(isActive, entry));
}

function View_createPanel(createItem, title, active, rest) {
    return FunctionComponent_Of_Z5A158BBF((tupledArg) => {
        const active_1 = tupledArg[1];
        const rest_1 = tupledArg[2];
        const openState = react.useState(false);
        const hasToggle = (length(active_1) + length(rest_1)) > 3;
        const isOpen = (!hasToggle) ? true : (openState[0]);
        const toggleButton = (!hasToggle) ? "" : button(ofArray([new Option_1(18, (_arg1) => {
            const arg00 = !(openState[0]);
            openState[1](arg00);
        }), new Option_1(1, new Size_ISize(0))]), singleton_1(icon(empty(), singleton_1(Fa_i(singleton_1(isOpen ? (new Fa_IconOption(11, "fas fa-chevron-up")) : (new Fa_IconOption(11, "fas fa-chevron-down"))), [])))));
        const items = hasToggle ? append_1(map_1(partialApply(1, createItem, [true]), active_1), map_1(partialApply(1, createItem, [false]), rest_1)) : map_1((tupledArg_2) => createItem(tupledArg_2[0], tupledArg_2[1]), sortBy((tupledArg_1) => tupledArg_1[1].Name, append_1(map_1((e) => [true, e], active_1), map_1((e_1) => [false, e_1], rest_1)), {
            Compare: comparePrimitives,
        }));
        return panel(toList(delay(() => ((!isOpen) ? singleton(new Option_2(2, "collapsed")) : empty_1()))), toList(delay(() => append(singleton(heading(empty(), ofArray([react.createElement("div", {
            className: "panel-title",
        }, tupledArg[0]), toggleButton]))), delay(() => items)))));
    }, void 0, uncurry(2, void 0), void 0, "createPanel", "/home/vsts/work/1/s/src/Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/Components/Filters.fs", 362)([title, active, rest]);
}

function View_filterView(applyFilterChange, createContainer, createItem, title, activeTitle, activeItems, rest, getEntryContent) {
    let title_1;
    if (activeTitle == null) {
        title_1 = title;
    }
    else {
        const t = activeTitle;
        title_1 = toText(printf("%s: %s"))(title)(t);
    }
    return createContainer(createItem(getEntryContent, (isActive) => ((item) => (isActive ? ((_arg1) => applyFilterChange(item.Disable)) : ((_arg2) => applyFilterChange(item.Enable))))), title_1, activeItems, rest);
}

function View_singleSelectView(applyFilterChange, createContainer, createItem, title, filter) {
    let patternInput;
    const matchValue = filter.ActiveFilter;
    if (matchValue == null) {
        patternInput = [void 0, empty()];
    }
    else {
        const entry = matchValue;
        patternInput = [entry.Name, singleton_1(entry)];
    }
    return View_filterView(applyFilterChange, createContainer, createItem, title, patternInput[0], patternInput[1], filter.Rest, (isActive) => ((entry_1) => singleton_1(radio(empty(), ofArray([input(singleton_1(new Input_Option(1, ofArray([new HTMLAttr(62, isActive), new HTMLAttr(132, true)])))), " " + entry_1.Name])))));
}

function View_multiSelectView(applyFilterChange, createContainer, createItem, title, filter) {
    let arg20;
    let patternInput;
    const matchValue = filter.ActiveFilters;
    if (isEmpty(matchValue)) {
        patternInput = [void 0, empty()];
    }
    else {
        const entries = matchValue;
        const first = head(entries);
        const total = length(entries) | 0;
        patternInput = [(total === 1) ? first.Name : ((arg20 = ((total - 1) | 0), toText(printf("%s +%d"))(first.Name)(arg20))), entries];
    }
    return View_filterView(applyFilterChange, createContainer, createItem, title, patternInput[0], patternInput[1], filter.Rest, (isActive) => ((entry) => singleton_1(checkbox(empty(), toList(delay(() => append(singleton(input_1(singleton_1(new Common_GenericOption(1, ofArray([new HTMLAttr(62, isActive), new HTMLAttr(132, true)]))))), delay(() => append(singleton(" " + entry.Title), delay(() => {
        const matchValue_1 = entry.Count;
        if (matchValue_1 != null) {
            const c = matchValue_1 | 0;
            return singleton(Text_span(singleton_1(new Common_GenericOption(0, "count")), singleton_1(toText(printf("%d"))(c))));
        }
        else {
            return empty_1();
        }
    }))))))))));
}

function View_dropdownsFilter(applyFilterChange, filters) {
    const children = map_1((tupledArg) => {
        const title = tupledArg[0];
        const filter = tupledArg[1];
        if (filter.tag === 1) {
            return View_multiSelectView(applyFilterChange, (createItem_1, title_2, active_1, rest_1) => View_createDropdown(uncurry(2, createItem_1), title_2, active_1, rest_1), (getEntryContent_1, getOnClick_1) => ((isActive_1) => ((entry_1) => View_createDropdownItem(uncurry(2, getEntryContent_1), uncurry(3, getOnClick_1), isActive_1, entry_1))), title, filter.fields[0]);
        }
        else {
            return View_singleSelectView(applyFilterChange, (createItem, title_1, active, rest) => View_createDropdown(uncurry(2, createItem), title_1, active, rest), (getEntryContent, getOnClick) => ((isActive) => ((entry) => View_createDropdownItem(uncurry(2, getEntryContent), uncurry(3, getOnClick), isActive, entry))), title, filter.fields[0]);
        }
    }, filters);
    return react.createElement("div", {
        className: "filter-dropdowns is-hidden-touch",
    }, ...children);
}

class View_ModalState extends Record {
    constructor(IsOpen, SearchFilter) {
        super();
        this.IsOpen = IsOpen;
        this.SearchFilter = SearchFilter;
    }
}

function View_ModalState$reflection() {
    return record_type("Fame.Fts.Toolbox.SparePartInfo.UI.Components.Filters.View.ModalState", [], View_ModalState, () => [["IsOpen", bool_type], ["SearchFilter", SearchFilter$reflection()]]);
}

function View_modalFilter(loc, isInternal, applyFilterChange, searchFilter, searchResult) {
    const tr = loc.Translation;
    return FunctionComponent_Of_Z5A158BBF((searchFilter_1) => {
        let _arg4;
        const modalState = react.useState(new View_ModalState(false, searchFilter_1));
        const closeAndReset = (_arg2) => {
            modalState[1](new View_ModalState(false, searchFilter_1));
        };
        const applyFilterInModal = (map) => {
            const searchFilter_2 = map((modalState[0]).SearchFilter);
            modalState[1](new View_ModalState((modalState[0]).IsOpen, searchFilter_2));
        };
        const filters = FilterData_ofSearchResult(loc, isInternal, (modalState[0]).SearchFilter)(searchResult);
        const panels = map_1((tupledArg) => {
            const title = tupledArg[0];
            const filter = tupledArg[1];
            if (filter.tag === 1) {
                return View_multiSelectView(applyFilterInModal, (createItem_1, title_2, active_1, rest_1) => View_createPanel(uncurry(2, createItem_1), title_2, active_1, rest_1), (getEntryContent_1, getOnClick_1) => ((isActive_1) => ((entry_1) => View_createPanelItem(uncurry(2, getEntryContent_1), uncurry(3, getOnClick_1), isActive_1, entry_1))), title, filter.fields[0]);
            }
            else {
                return View_singleSelectView(applyFilterInModal, (createItem, title_1, active, rest) => View_createPanel(uncurry(2, createItem), title_1, active, rest), (getEntryContent, getOnClick) => ((isActive) => ((entry) => View_createPanelItem(uncurry(2, getEntryContent), uncurry(3, getOnClick), isActive, entry))), title, filter.fields[0]);
            }
        }, filters);
        const modal = modal_1(singleton_1(new Option_3(1, (modalState[0]).IsOpen)), ofArray([background(singleton_1(new Common_GenericOption(1, singleton_1(new DOMAttr(40, closeAndReset)))), empty()), Card_card(empty(), ofArray([Card_head(empty(), ofArray([Card_title(empty(), singleton_1(tr.Filters)), delete$(singleton_1(new Option_4(3, closeAndReset)), empty())])), Card_body(empty(), panels), Card_foot(empty(), ofArray([button(ofArray([new Option_1(0, new Color_IColor(6)), new Option_1(18, (_arg3) => {
            applyFilterChange((_arg1_1) => (modalState[0]).SearchFilter);
            modalState[1](new View_ModalState(false, (modalState[0]).SearchFilter));
        })]), singleton_1(tr.Ok)), button(singleton_1(new Option_1(18, closeAndReset)), singleton_1(tr.Cancel))]))]))]));
        const openButton = button(singleton_1(new Option_1(18, (_arg1) => {
            modalState[1](new View_ModalState(true, (modalState[0]).SearchFilter));
        })), singleton_1((_arg4 = (sumBy((tupledArg_1) => {
            const f_2 = tupledArg_1[1];
            if (f_2.tag === 1) {
                return length(f_2.fields[0].ActiveFilters) | 0;
            }
            else {
                const matchValue = f_2.fields[0].ActiveFilter;
                if (matchValue != null) {
                    if (matchValue.IsDefault) {
                        const f_5 = matchValue;
                        return 0;
                    }
                    else if (matchValue != null) {
                        return 1;
                    }
                    else {
                        throw (new Error("Match failure"));
                    }
                }
                else {
                    return 0;
                }
            }
        }, filters, {
            GetZero: () => 0,
            Add: (x, y) => (x + y),
        }) | 0), (_arg4 === 0) ? tr.ShowFilters : tr.ActiveFilters(_arg4))));
        return react.createElement("div", {
            className: "filter-modal is-hidden-desktop",
        }, modal, openButton);
    }, void 0, uncurry(2, void 0), (searchFilter_3) => toText(printf("%A"))(searchFilter_3), "modalFilter", "/home/vsts/work/1/s/src/Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/Components/Filters.fs", 458)(searchFilter);
}

export function view(loc, isInternal, dispatchResetFilter, applyFilterChange, searchFilter, searchResult) {
    let children;
    const _arg1 = FilterData_ofSearchResult(loc, isInternal, searchFilter)(searchResult);
    if (isEmpty(_arg1)) {
        children = empty();
    }
    else {
        const resetButton = button(singleton_1(new Option_1(18, (_ev) => {
            dispatchResetFilter();
        })), singleton_1(icon(singleton_1(new Option_5(0, new Size_ISize(0))), singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-times")), [])))));
        children = ofArray([View_dropdownsFilter(applyFilterChange, _arg1), View_modalFilter(loc, isInternal, applyFilterChange, searchFilter, searchResult), resetButton]);
    }
    return react.createElement("div", {
        className: "filters",
    }, ...children);
}

