import { Fa_i, Fa_IconOption } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { ofArray, singleton as singleton_1, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Option, button as button_1 } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { icon as icon_1 } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Option as Option_1, div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { ListMode } from "../Settings.fs.js";

export function view(currentListMode, onChange) {
    const button = (targetListMode) => {
        const icon = (targetListMode.tag === 0) ? (new Fa_IconOption(11, "fas fa-list")) : (new Fa_IconOption(11, "fas fa-th"));
        return div(empty(), singleton_1(button_1(toList(delay(() => append(singleton(new Option(18, (_arg1) => {
            onChange(targetListMode);
        })), delay(() => append(singleton(new Option(12, equals(currentListMode, targetListMode))), delay(() => (equals(currentListMode, targetListMode) ? singleton(new Option(19, "is-selected")) : empty_1()))))))), singleton_1(icon_1(empty(), singleton_1(Fa_i(singleton_1(icon), [])))))));
    };
    return div_1(singleton_1(new Option_1(0)), ofArray([button(new ListMode(1)), button(new ListMode(0))]));
}

