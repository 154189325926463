import { TextSize_Option, TextWeight_Option, Text_span, Modifier_IModifier, TextAlignment_Option, Screen, Text_div, Common_GenericOption, Text_p } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { isEmpty, map, ofArray, empty, singleton } from "../fable_modules/fable-library.3.7.12/List.js";
import { getDivisionTitle, Eol_format, Eol_getColor } from "../ViewHelper.fs.js";
import { box$0027 } from "../fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import { RouteModule_ofProjectId, RouteModule_ofProductId, RouteModule_ofSalesListId, RouteModule_ofSparePartId, RouteModule_toLink } from "../Routing.fs.js";
import { DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { FunctionComponent_Of_Z5A158BBF } from "../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { ExternalUrl } from "../Constants.fs.js";
import { Option, image as image_1 } from "../fable_modules/Fulma.2.16.0/Elements/Image.fs.js";
import { append, empty as empty_1, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Prop, lazyLoad } from "../../../Import/Fable/Fable.ReactLazyLoad.fs.js";
import { ProjectIdModule_toString, ProductIdModule_toString, SalesListIdModule_toString, SparePartIdModule_toString } from "../../SparePartInfo/CommonTypes.fs.js";
import { uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Option as Option_1, columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";

function description(s) {
    return Text_p(singleton(new Common_GenericOption(0, "description")), singleton(s));
}

function endOfLife(loc, eol) {
    let date;
    return Text_div(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(3))))), singleton(Text_div(empty(), ofArray([loc.Translation.Field.EndOfService, (eol != null) ? ((date = eol, Text_div(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(1, Eol_getColor(date))))), singleton(Eol_format(loc, date))))) : Text_div(empty(), singleton("n/a"))]))));
}

function materialNo(translation, no) {
    return Text_div(empty(), ofArray([translation.Field.MaterialNo + ": ", Text_span(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(2, new TextWeight_Option(2))))), singleton(no))]));
}

function row(title, value) {
    return Text_div(empty(), singleton((title + ": ") + value));
}

function box(route, children) {
    let props;
    return box$0027(empty(), singleton((props = [new HTMLAttr(94, RouteModule_toLink(route))], react.createElement("a", keyValueList(props, 1), ...children))));
}

const createSparePartTileComponent = FunctionComponent_Of_Z5A158BBF((tupledArg) => {
    const loc = tupledArg[0];
    const isInternal = tupledArg[1];
    const sparePart = tupledArg[2];
    const pictureState = react.useState(true);
    const src = ExternalUrl.MaterialImage(sparePart.MaterialNo);
    const image = image_1(singleton(new Option(9)), toList(delay(() => {
        let props;
        return (pictureState[0]) ? singleton_1((props = [new HTMLAttr(149, src), new HTMLAttr(8, "Picture of " + sparePart.Description), new DOMAttr(14, (_arg3) => {
            pictureState[1](false);
        })], react.createElement("img", keyValueList(props, 1)))) : empty_1();
    })));
    const price = Text_div(singleton(new Common_GenericOption(2, ofArray([new Modifier_IModifier(3, new Screen(0), new TextSize_Option(3)), new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(3))]))), singleton(Text_div(empty(), singleton(loc.FormatCurrencyWithEmpty(sparePart.Currency, sparePart.ListPrice)))));
    return lazyLoad([new Prop(3), new Prop(2, true), new Prop(4, 100)], [box(RouteModule_ofSparePartId(sparePart.MaterialNo), [image, description(sparePart.Description), materialNo(loc.Translation, SparePartIdModule_toString(sparePart.MaterialNo)), Text_div(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(3, new Screen(0), new TextSize_Option(6))))), toList(delay(() => append(singleton_1(row(loc.Translation.Field.Division, getDivisionTitle(loc, sparePart.Division))), delay(() => append(isInternal ? singleton_1(row(loc.Translation.Field.CentralLocal, sparePart.CentralLocal)) : empty_1(), delay(() => append(singleton_1(row(loc.Translation.Field.PartNumber, sparePart.PartNumber)), delay(() => (isInternal ? singleton_1(endOfLife(loc, sparePart.EndOfService)) : empty_1())))))))))), price])]);
}, void 0, uncurry(2, void 0), (tupledArg_1) => SparePartIdModule_toString(tupledArg_1[2].MaterialNo), "createSparePartTileComponent", "/home/vsts/work/1/s/src/Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/Components/Tiles.fs", 51);

function createSparePartTile(loc, isInternal, sparePart) {
    return createSparePartTileComponent([loc, isInternal, sparePart]);
}

function createSalesListTile(loc, _isInternal, salesList) {
    const idStr = SalesListIdModule_toString(salesList.MaterialNo);
    return box(RouteModule_ofSalesListId(salesList.MaterialNo), [description(salesList.Description), row(loc.Translation.Field.PartNumber, salesList.PartNumber), materialNo(loc.Translation, idStr)]);
}

function createProductTile(loc, isInternal, product) {
    const idStr = ProductIdModule_toString(product.MaterialNo);
    return box(RouteModule_ofProductId(product.MaterialNo), toList(delay(() => append(singleton_1(description(product.Description)), delay(() => append(singleton_1(row(loc.Translation.Field.PartNumber, product.PartNumber)), delay(() => append(singleton_1(materialNo(loc.Translation, idStr)), delay(() => append(singleton_1(row(loc.Translation.Field.ProductNo, product.ProductNo)), delay(() => append(isInternal ? singleton_1(endOfLife(loc, product.EndOfService)) : empty_1(), delay(() => singleton_1(row(loc.Translation.Field.ProductGroupPlant, product.ProductGroupPlant)))))))))))))));
}

function createProjectTile(loc, isInternal, project) {
    const idStr = ProjectIdModule_toString(project.MaterialNo);
    return box(RouteModule_ofProjectId(project.MaterialNo), toList(delay(() => append(singleton_1(description(project.Description)), delay(() => append(singleton_1(row(loc.Translation.Field.PartNumber, project.PartNumber)), delay(() => append(singleton_1(materialNo(loc.Translation, idStr)), delay(() => append(singleton_1(row(loc.Translation.Field.ProductNo, project.ProductNo)), delay(() => append(isInternal ? singleton_1(endOfLife(loc, project.EndOfService)) : empty_1(), delay(() => singleton_1(row(loc.Translation.Field.ProductGroupPlant, project.ProductGroupPlant)))))))))))))));
}

function createTiles(createTile, items) {
    return map((item) => column(empty(), singleton(createTile(item))), items);
}

export function view(loc, isInternal) {
    return (arg) => {
        let _arg2;
        const _arg1 = arg;
        switch (_arg1.tag) {
            case 1: {
                _arg2 = createTiles((salesList) => createSalesListTile(loc, isInternal, salesList), _arg1.fields[0].SalesLists);
                break;
            }
            case 2: {
                _arg2 = createTiles((product) => createProductTile(loc, isInternal, product), _arg1.fields[0].Products);
                break;
            }
            case 3: {
                _arg2 = createTiles((project) => createProjectTile(loc, isInternal, project), _arg1.fields[0].Projects);
                break;
            }
            default: {
                _arg2 = createTiles((sparePart) => createSparePartTile(loc, isInternal, sparePart), _arg1.fields[0].SpareParts);
            }
        }
        return isEmpty(_arg2) ? (void 0) : columns(singleton(new Option_1(8, "tiles")), _arg2);
    };
}

