import { Option, div } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { ofArray, empty, map, singleton } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/List.js";
import { p } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Option as Option_1, button } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Seq.js";
import { Color_IColor } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Common.fs.js";
import { equals } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Util.js";
import { value } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/fable-library.3.7.12/Option.js";
import { useFeliz_React__React_useState_Static_1505 } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Feliz.1.64.0/React.fs.js";
import { End_div, Start_div, Menu_Option, menu, Burger_Option, burger, Item_Option, Item_a, Brand_div, Option as Option_2, navbar } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { container } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { DOMAttr, HTMLAttr } from "../../../Fame.Fts.Toolbox.SparePartInfo/SparePartInfo.UI/fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import * as react from "react";

export function LocaleSelect(options) {
    return div(singleton(new Option(0)), map((locale) => {
        const title = options.GetTitle(locale);
        return p(empty(), singleton(button(toList(delay(() => append(singleton_1(new Option_1(0, new Color_IColor(4))), delay(() => append(singleton_1(new Option_1(5)), delay(() => append(singleton_1(new Option_1(4)), delay(() => append(equals(locale, options.Selected) ? singleton_1(new Option_1(19, "is-selected")) : singleton_1(new Option_1(18, (_arg1) => {
            options.OnSelect(locale);
        })), delay(() => ((options.Size != null) ? singleton_1(new Option_1(1, value(options.Size))) : empty_1()))))))))))), singleton(title))));
    }, options.All));
}

export function Navbar(options) {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const updateOpen = patternInput[1];
    const isOpen = patternInput[0];
    return navbar(singleton(new Option_2(0, new Color_IColor(4))), singleton(container(empty(), ofArray([Brand_div(empty(), toList(delay(() => append(singleton_1(Item_a(ofArray([new Item_Option(6, "logo"), new Item_Option(5, ofArray([new HTMLAttr(94, options.StartHref), new DOMAttr(40, (ev) => {
        options.StartOnClick(ev);
        updateOpen(false);
    })]))]), singleton(react.createElement("img", {
        src: options.LogoImageSrc,
        alt: options.SiteTitle,
    })))), delay(() => append(options.ExtraBrandElements, delay(() => singleton_1(burger(ofArray([new Burger_Option(0, isOpen), new Burger_Option(1, (_arg2) => {
        updateOpen(!isOpen);
    })]), ofArray([react.createElement("span", {}), react.createElement("span", {}), react.createElement("span", {})])))))))))), menu(singleton(new Menu_Option(0, isOpen)), ofArray([Start_div(empty(), options.StartElements), End_div(empty(), options.EndElements)]))]))));
}

